import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './App.css'
import './assets/fonts/avenir-black.css'
import './assets/fonts/avenir-book.css'
import './assets/fonts/bembo.css'
import { Header, WIPComponent } from './components'
import { Account } from './components/account/account.component'
import AddressForm from './components/address/address-form'
import AddressList from './components/address/address-list'
import Cart from './components/cart/cart'
import { Category } from './components/category/category'
import ContactUs from './components/contact-us/contact-us.component'
import { Footer } from './components/footer/footer'
import CookiePolicy from './components/footer/pages/cookies-policy'
import FAQSPage from './components/footer/pages/faqs'
import { FeStoryPage } from './components/footer/pages/fe-story'
import { OrderingAndShippingPage } from './components/footer/pages/order-and-shipping'
import { OrganizationsPage } from './components/footer/pages/organizations'
import PrivacyPolicy from './components/footer/pages/privacy-policy'
import { PurchaseConditions } from './components/footer/pages/purchase-conditions'
import { ReturnsPage } from './components/footer/pages/returns'
import { StoreLocatorPage } from './components/footer/pages/store-locator'
import { TermsAndConditionsPage } from './components/footer/pages/terms-and-conditions'
import { Home } from './components/home/home'
import { Login } from './components/login/login'
import { NoProductsFound } from './components/not-found/no-products-found'
import { NotFound } from './components/not-found/not-found'
import PartnersPage from './components/partners/partners.page'
import { PasswordRecovery } from './components/password-recovery/password-recovery'
import { RedsysProcessingPayment } from './components/payment/processing-payment'
import { CardPaymentPage } from './components/payment/redsys-v2'
import { Product } from './components/product'
import { RedirectHandler } from './components/redirect-handler/redirect-handler'
import SignUp from './components/signup/signup'
import ScrollToTop from './components/utils/scroll-to-top'
import { LanguageISOCode } from './enums/language-iso-code.enum'
import { ICart } from './interfaces/cart.interface'
import customTheme from './theme'
import { AccountRoutes } from './translations/translations.enum'
import { emptyCart, fetchUpdatedCart, retrieveCart } from './utils/cart.util'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import i18n from './translations'
import HubspotTest from './components/footer/pages/hubspot-test'

function App() {
	const theme = useTheme()
	const isMobile = useMediaQuery(
		theme.breakpoints.down(
			parseInt(process.env.REACT_APP_MAX_MOBILE_WIDTH_PX || 'md')
		)
	)
	const [language, setLanguage] = useState<LanguageISOCode>(LanguageISOCode.es)
	const [isLogged, setIsLogged] = useState(!!Cookies.get('accessToken'))

	const [cart, setCart] = useState<ICart>(emptyCart())

	const deleteCartProduct = async (
		modelId: number,
		variantId: number | 'default' = 'default'
	) => {
		const cartToUpdate = { ...cart }
		delete cartToUpdate.products[modelId][variantId]

		await fetchUpdatedCart(cartToUpdate, setCart, isLogged)
	}

	const updateCart = async (
		modelId: number,
		quantity: number,
		variantId: number | 'default' = 'default'
	) => {
		const cartToUpdate = { ...cart }

		if (!cartToUpdate.products[modelId]) cartToUpdate.products[modelId] = {}
		cartToUpdate.products[modelId][variantId] = quantity

		await fetchUpdatedCart(cartToUpdate, setCart, isLogged)
	}

	const getLanguageFromUrl = (): LanguageISOCode => {
		const splittedUrl = window.location.href.split('/')
		return (splittedUrl[3] || 'es') as LanguageISOCode;
	}
	
	useEffect(() => {
		const languageUrl = getLanguageFromUrl()
		i18n.changeLanguage(languageUrl)
		setLanguage(languageUrl)
		retrieveCart(setCart, isLogged)
	}, [])

	const appBarHeight = isMobile
		? `${process.env.REACT_APP_HEADER_HEIGHT_MOBILE_PX}px`
		: `${process.env.REACT_APP_HEADER_HEIGHT_DESKTOP_PX}px`

	return (
		<HelmetProvider>
			<Helmet>
				<title>Fina Ejerique</title>
				<meta
					name='keywords'
					content='finaejerique, fina ejerique, ropa infantil, moda infantil, ropa de bebe, moda de bebe, ropa de niño, moda de niño, ropa de niña, moda de niña, ropa de recien nacido, moda de recien nacido, ropa de bebe online, moda de bebe online, ropa de niño online, moda de niño online, ropa de niña online, moda de niña online, ropa de recien nacido online, moda de recien nacido online'
				/>
			</Helmet>
			<ThemeProvider theme={customTheme}>
				<Router>
					<ScrollToTop />
					<Header
						isMobile={isMobile}
						appBarHeight={appBarHeight}
						language={language}
						setLanguage={setLanguage}
						isLogged={isLogged}
						setIsLogged={setIsLogged}
						setCart={setCart}
					/>
					<Routes>
						<Route
							path='/:language/hubspot-test'
							element={<HubspotTest isMobile={isMobile} />}
						/>
						<Route
							path='/:language/account'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ACCOUNT_OVERVIEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ACCOUNT_OVERVIEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/addresses'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ADDRESS_BOOK}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/direcciones'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ADDRESS_BOOK}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/addresses/new'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ADDRESS_NEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/direcciones/nueva'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ADDRESS_NEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/addresses/edit/:addressName'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ADDRESS_EDIT}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/direcciones/editar/:addressName'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ADDRESS_EDIT}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/orders'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ORDER_HISTORY}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/pedidos'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ORDER_HISTORY}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/returns'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.RETURNS}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/devoluciones'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.RETURNS}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/my-info'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.MY_INFO}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/mis-datos'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.MY_INFO}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/contact-us'
							element={<ContactUs isMobile={isMobile} />}
						/>
						<Route
							path='/:language/contactanos'
							element={<ContactUs isMobile={isMobile} />}
						/>
						<Route
							path='/:language/account/change-password'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.CHANGE_PASSWORD}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/cambiar-contrasena'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.CHANGE_PASSWORD}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/orders/:orderId'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ORDER_VIEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/pedidos/:orderId'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ORDER_VIEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/returns/:orderId'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.RETURN_VIEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/devoluciones/:orderId'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.RETURN_VIEW}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/orders/:orderId/returns'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ORDER_RETURN}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/pedidos/:orderId/devoluciones'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.ORDER_RETURN}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/account/wishlist'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.WISHLIST}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/cuenta/lista-de-deseos'
							element={
								<Account
									isMobile={isMobile}
									route={AccountRoutes.WISHLIST}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/new-address'
							element={<AddressForm isMobile={isMobile} />}
						/>
						<Route
							path='/:language/nueva-direccion'
							element={<AddressForm isMobile={isMobile} />}
						/>
						<Route
							path='/:language/addresses'
							element={<AddressList isMobile={isMobile} />}
						/>
						<Route
							path='/:language/direcciones'
							element={<AddressList isMobile={isMobile} />}
						/>
						<Route
							path='/:language/address/edit/:addressName'
							element={<AddressForm isMobile={isMobile} />}
						/>
						<Route
							path='/:language/direcciones/editar/:addressName'
							element={<AddressForm isMobile={isMobile} />}
						/>
						<Route
							path='/:language?'
							element={<Home isMobile={isMobile} />}
						/>
						<Route
							path='/:language/category/:id'
							element={<Category isMobile={isMobile} />}
						/>
						<Route
							path='/:language/categoria/:id'
							element={<Category isMobile={isMobile} />}
						/>
						<Route
							path='/:language/c/:categoryName/:id'
							element={<Category isMobile={isMobile} />}
						/>
						<Route
							path='/:language/product/:id'
							element={<Product isMobile={isMobile} updateCart={updateCart} />}
						/>
						<Route
							path='/:language/producto/:id'
							element={<Product isMobile={isMobile} updateCart={updateCart} />}
						/>
						<Route
							path='/:language/p/:productName/:id'
							element={<Product isMobile={isMobile} updateCart={updateCart} />}
						/>
						<Route
							path='/:language/search'
							element={<NoProductsFound isMobile={isMobile} />}
						/>
						<Route
							path='/:language/buscar'
							element={<NoProductsFound isMobile={isMobile} />}
						/>
						<Route
							path='/:language/search/:query'
							element={
								// DONT remove div
								<div>
									<Category isMobile={isMobile} />
								</div>
							}
						/>
						<Route
							path='/:language/buscar/:query'
							element={
								// DONT remove div
								<div>
									<Category isMobile={isMobile} />
								</div>
							}
						/>
						<Route
							path='/:language/shopping-bag'
							element={
								<Cart
									isMobile={isMobile}
									cart={cart}
									deleteCartProduct={deleteCartProduct}
									setCart={setCart}
									setIsLogged={setIsLogged}
									updateCart={updateCart}
								/>
							}
						/>
						<Route
							path='/:language/carrito'
							element={
								<Cart
									isMobile={isMobile}
									cart={cart}
									deleteCartProduct={deleteCartProduct}
									setCart={setCart}
									setIsLogged={setIsLogged}
									updateCart={updateCart}
								/>
							}
						/>
						<Route
							path='/:language/shopping-bag/insite/:redsysId'
							element={<CardPaymentPage />}
						/>
						<Route
							path='/:language/carrito/insite/:redsysId'
							element={<CardPaymentPage />}
						/>
						<Route
							path='/:language/processing-payment'
							element={<RedsysProcessingPayment />}
						/>
						<Route
							path='/:language/procesando-pago'
							element={<RedsysProcessingPayment />}
						/>
						<Route
							path='/:language/login'
							element={
								<Login
									isMobile={isMobile}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/password-recovery'
							element={<PasswordRecovery isMobile={isMobile} />}
						/>
						<Route
							path='/:language/recuperar-contrasena'
							element={<PasswordRecovery isMobile={isMobile} />}
						/>
						<Route
							path='/:language/organizations'
							element={<OrganizationsPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/organizacion'
							element={<OrganizationsPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/purchase-conditions'
							element={<PurchaseConditions isMobile={isMobile} />}
						/>
						<Route
							path='/:language/condicion-de-venta'
							element={<PurchaseConditions isMobile={isMobile} />}
						/>
						<Route
							path='/:language/privacy-policy'
							element={<PrivacyPolicy isMobile={isMobile} />}
						/>
						<Route
							path='/:language/politica-de-privacidad'
							element={<PrivacyPolicy isMobile={isMobile} />}
						/>
						<Route
							path='/:language/cookie-policy'
							element={<CookiePolicy isMobile={isMobile} />}
						/>
						<Route
							path='/:language/politica-cookies'
							element={<CookiePolicy isMobile={isMobile} />}
						/>
						<Route
							path='/:language/faqs'
							element={<FAQSPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/faqs'
							element={<WIPComponent />}
						/>
						<Route
							path='/:language/returns'
							element={<ReturnsPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/devoluciones'
							element={<ReturnsPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/ordering-and-shipping'
							element={<OrderingAndShippingPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/pedidos-y-envios'
							element={<OrderingAndShippingPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/store-locator'
							element={<StoreLocatorPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/localizador-de-tiendas'
							element={<StoreLocatorPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/terms-and-conditions'
							element={<TermsAndConditionsPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/terminos-y-condiciones'
							element={<TermsAndConditionsPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/fina-ejerique-story'
							element={<FeStoryPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/fina-ejerique-historia'
							element={<FeStoryPage isMobile={isMobile} />}
						/>
						<Route
							path='/:language/signup'
							element={
								<SignUp
									isMobile={isMobile}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/registrate'
							element={
								<SignUp
									isMobile={isMobile}
									setIsLogged={setIsLogged}
									setCart={setCart}
								/>
							}
						/>
						<Route
							path='/:language/partners'
							element={<PartnersPage isMobile={isMobile} />}
						/>

						<Route
							path='/:language/:entityName'
							element={
								<RedirectHandler
									isMobile={isMobile}
									updateCart={updateCart}
								/>
							}
						/>

						<Route
							path='/:language/*'
							element={<NotFound isMobile={isMobile} />}
						/>
						<Route
							path='*'
							element={<NotFound isMobile={isMobile} />}
						/>
					</Routes>

					<Footer isMobile={isMobile} language={language}/>
				</Router>
			</ThemeProvider>
		</HelmetProvider>
	)
}

export default App
