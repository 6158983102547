import { Box, Typography } from '@mui/material'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { IRoute } from '../../interfaces/route.interface'
import { LazyImage } from '../lazy-image/lazy-image'
import { BlackButton } from '../product/black-button'
import { LazyImageUnknownHeight } from '../lazy-image/lazy-image-unknown-height'
import { getPriceComponent } from '../../utils/price-component'
import { t } from 'i18next'
import { Translations } from '../../translations/translations.enum'

export interface IOrderPreviewProps extends IRoute {
	order: IOrderRequest
}

export function OrderPreview(props: IOrderPreviewProps) {
	const navigate = useNavigate()
	const image = props.order.orderLines[0].image

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: props.isMobile ? '1rem 1rem 1rem 0' : '2 2 2 0',
				borderTop: '1px solid #cfcfcf',
				borderBottom: '1px solid #cfcfcf',
				margin: '0 0 1.5rem',
				width: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
				}}
			>
				<LazyImageUnknownHeight
					imageSrc={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_CATALOG_IMAGE_PATH}/${image}`}
					loadPlaceholderSize={'40px'}
				/>
				<Box
					sx={{
						width: props.isMobile ? '50%' : '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						padding: '1rem',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'flex-start',
								width: '100%',
								flexDirection: props.isMobile ? 'column' : 'row',
							}}
						>
							{getPriceComponent(
								'1.2rem',
								props.order.total,
								props.order.totalWithDiscount
							)}
							{props.order?.payedAt && (
								<Typography
									fontSize={props.isMobile ? '1rem' : '1rem'}
									sx={{
										lineHeight: '2rem',
										color: '#898484',
										width: props.isMobile ? '100%' : '50%',
									}}
								>
									{new Date(props.order?.payedAt).toLocaleDateString('es-es')}
								</Typography>
							)}
						</Box>
						<Typography
							sx={{
								lineHeight: '2rem',
								fontSize: props.isMobile ? '0.8rem' : '1rem',
							}}
						>
							{t(Translations.ORDER_NUMBER)}: {props.order.redsysId}
						</Typography>
						<Typography
							sx={{
								lineHeight: '2rem',
								fontSize: props.isMobile ? '0.8rem' : '1rem',
							}}
						>
							{props.order.orderLines.length} {t(Translations.ITEMS)}
						</Typography>
					</Box>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							flexDirection: props.isMobile ? 'column' : 'row',
						}}
					>
						<Typography
							fontWeight={'bold'}
							sx={{
								lineHeight: '2rem',
								textAlign: 'left',
								width: '100%',
								marginTop: props.isMobile ? '2rem' : 0,
								fontSize: props.isMobile ? '0.9rem' : '1.15rem',
							}}
						>
							{t(props.order.status).toUpperCase()}
						</Typography>
						<BlackButton
							text={t(Translations.VIEW_DETAILS)}
							inverted={true}
							onClick={() => {
								navigate(`${props.order.redsysId}`)
							}}
							width={props.isMobile ? '100%' : '50%'}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
