import React from 'react'
import { useNavigate } from 'react-router-dom' // Importa useNavigate
import {
	Close,
	Search as SearchIcon,
} from '@mui/icons-material'
import { Translations } from '../../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import {
	Box,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material'
import '../../../assets/scss/dropdown.scss'

import '../../../assets/scss/header.scss'
import { LanguageISOCode } from '../../../enums/language-iso-code.enum'

const Search = (props: {
	language: LanguageISOCode
	isSticky: boolean
	isSearching: boolean
	setIsSearching: (isSearching: boolean) => void
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate() // Hook para navegar

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const query = event.target.value || ''
		navigate(`${props.language}/search/${query}`) // Cambia la URL sin recargar
	}

	const handleOpenSearch = () =>
		props.setIsSearching(true)
	const handleCloseSearch = () =>
		props.setIsSearching(false)

	return (
		<Box
			sx={{
				display: 'inline-flex',
				borderColor: 'grey.500',
				cursor: 'pointer',
				padding: 0,
				textDecoration: 'none',
				color: 'primary.main',
			}}
			className='dropdown'
		>
			{props.isSearching ? (
				<TextField
					variant='outlined'
					fullWidth
					autoFocus
					placeholder={t(Translations.SEARCH)}
					onChange={handleChange}
					InputProps={{
						startAdornment: (
							<InputAdornment
								position='start'
								style={{
									color: '#000',
									padding: '0',
								}}
							>
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<IconButton
								onClick={handleCloseSearch}
								style={{
									color: '#000',
									padding: '0',
								}}
							>
								<Close />
							</IconButton>
						),
						style: {
							color: 'black',
							backgroundColor: 'white',
							border: 'none',
							fontSize: '1.2rem',
							fontWeight: 'bold',
							padding: 0,
						}, // Estilo personalizado sin borde
						disableUnderline: true, // Desactiva la línea inferior
					}}
					sx={{
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								border: 'none', // Remueve el borde
							},
							'&:hover fieldset': {
								border: 'none', // Remueve el borde al pasar el mouse
							},
							'&.Mui-focused fieldset': {
								border: 'none', // Remueve el borde al enfocar
							},
						},
						'& .MuiOutlinedInput-input': {
							// Estilos directos para el elemento input
							padding: '0', // Ajusta el padding aquí
						},
						padding: 0,
					}}
				/>
			) : (
				<Box
					onClick={handleOpenSearch}
					sx={{
						color: 'primary.main',
						padding: '0',
						display: 'flex',
						direction: 'row',
						alignItems: 'flex-start',
					}}
				>
					<SearchIcon />
					<div className='header-search-button'>
						{t(Translations.SEARCH)}
					</div>
				</Box>
			)}
		</Box>
	)
}

export default Search
