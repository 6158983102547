import { IRoute } from '../../interfaces/route.interface'
import * as React from 'react'
import {
	Unstable_NumberInput as BaseNumberInput,
	NumberInputProps,
} from '@mui/base/Unstable_NumberInput'
import { styled } from '@mui/system'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

export interface IQuantityChangerProps extends IRoute {
	quantity: number
	productId: number
	variantId: number
	updateCart: (modelId: number, variantId: number, quantity: number) => void
}

export function QuantityChanger(props: IQuantityChangerProps) {
	const NumberInput = React.forwardRef(function CustomNumberInput(
		props: NumberInputProps,
		ref: React.ForwardedRef<HTMLDivElement>
	) {
		return (
			<BaseNumberInput
				slots={{
					root: StyledInputRoot,
					input: StyledInput,
					incrementButton: StyledButton,
					decrementButton: StyledButton,
				}}
				slotProps={{
					incrementButton: {
						children: <AddIcon fontSize='small' />,
						className: 'increment',
					},
					decrementButton: {
						children: <RemoveIcon fontSize='small' />,
					},
				}}
				{...props}
				ref={ref}
			/>
		)
	})
	const black = {
		100: '#e6e6e6', // Gris muy claro
		200: '#cccccc', // Gris claro
		300: '#b3b3b3', // Gris medio claro
		400: '#999999', // Gris medio
		500: '#808080', // Gris neutro
		600: '#666666', // Gris medio oscuro
		700: '#4d4d4d', // Gris oscuro
		800: '#333333', // Muy oscuro
	}

	const grey = {
		50: '#F3F6F9',
		100: '#E5EAF2',
		200: '#DAE2ED',
		300: '#C7D0DD',
		400: '#B0B8C4',
		500: '#9DA8B7',
		600: '#6B7A90',
		700: '#434D5B',
		800: '#303740',
		900: '#1C2025',
	}

	const StyledInputRoot = styled('div')(
		({ theme }) => `
  font-family: 'Antic', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
  margin-top: 1.5rem;
`
	)

	const StyledInput = styled('input')(
		({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: black; // Texto siempre negro
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 4px;
  margin: 0;
  padding: 0;
  outline: 0;
  width: 32px;
  height: 32px;
  text-align: center;

  &:hover {
    border-color: ${black[400]};
  }

  &:focus {
    border-color: ${black[400]};
    box-shadow: 0 0 0 3px ${
			theme.palette.mode === 'dark' ? black[700] : black[200]
		};
  }

  &:focus-visible {
    outline: 0;
  }
`
	)

	const StyledButton = styled('button')(
		({ theme }) => `
  font-family: 'Antic', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  border-radius: 4px;
  background: black; // Fondo negro
  color: white; // Ícono blanco
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? black[700] : black[500]};
    border-color: ${theme.palette.mode === 'dark' ? black[500] : black[400]};
    color: white; // Ícono blanco en hover también
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
	)

	return (
		<NumberInput
			aria-label='Quantity Input'
			min={1}
			max={99}
			defaultValue={props.quantity}
			onChange={(event, newValue) => {
				if (newValue) {
					props.updateCart(props.productId, newValue, props.variantId)
				}
			}}
		/>
	)
}
