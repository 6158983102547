import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	Modal,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { IRoute } from '../../../interfaces/route.interface'
import ContactUs from '../../contact-us/contact-us.component'
import { BlackButton } from '../black-button'
import { useTranslation } from 'react-i18next'
import { Product } from '../../../translations/translations.enum'

export interface IAccordionContacUsProps
	extends IRoute {}

export function AccordionContacUs(
	props: IAccordionContacUsProps
) {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)

	const handleClose = () => setOpen(false)

	return (
		<Box m={'5% 0 7.5%'}>
			<Typography
				sx={{
					mb: '1rem',
				}}
			>
				{t(Product.PRODUCT_CONTACT_US_DESCRIPTION)}
			</Typography>
			<BlackButton
				text={t(Product.PRODUCT_CONTACT_US_BUTTON)}
				onClick={() => setOpen(true)}
			/>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						bgcolor: 'background.paper',
						boxShadow: 24,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						width: 'fit-content',
						padding: '2rem 2rem 4rem',
					}}
				>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<CloseIcon
							onClick={() => {
								handleClose()
							}}
							sx={{
								cursor: 'pointer',
								p: 1,
							}}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<ContactUs
							isMobile={props.isMobile}
						/>
					</Box>
				</Box>
			</Modal>
		</Box>
	)
}
