import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Delivery } from '../../../translations/translations.enum'

export function AccordionDelivery() {
	const { t } = useTranslation()
	return (
		<Box m={'5% 0 7.5%'}>
			<Typography fontWeight={'bold'}>
				{t(Delivery.DELIVERY_TITLE)}
			</Typography>
			<br />
			<Typography
				dangerouslySetInnerHTML={{ __html: t(Delivery.DELIVERY_DESCRIPTION_1) }}
			/>
			<br />
			<Typography
				dangerouslySetInnerHTML={{ __html: t(Delivery.DELIVERY_DESCRIPTION_2) }}
			/>
		</Box>
	)
}
