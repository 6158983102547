import Cookies from 'js-cookie'
import {
	ICartUpdate,
	fetchUpdatedCart,
	retrieveCart,
} from './cart.util'
import { NavigateFunction } from 'react-router-dom'
import { LocalStorageVariables } from '../enums/local-storage.enum'

export interface IAuthenticatedUser {
	accessToken: string
}

export async function saveAccessToken(
	data: IAuthenticatedUser,
	navigate: NavigateFunction,
	setIsLogged: (logged: boolean) => void,
	nextStep?: () => void,
	setCart?: ICartUpdate,
	isNewCart: boolean = false,
	language: string = 'es'
) {
	const token = data.accessToken

	Cookies.set('accessToken', token, {
		expires: 7,
		secure: false, // TODO: SET TRUE IN PRODUCTION
		sameSite: 'strict',
	})

	setIsLogged(true)

	if (setCart) {
		if (!isNewCart) {
			await retrieveCart(setCart, true)
		} else {
			const cart = localStorage.getItem(
				LocalStorageVariables.CART
			)

			if (cart) {
				fetchUpdatedCart(
					JSON.parse(cart),
					setCart,
					true
				)
			}
		}
	}

	if (nextStep) nextStep()
	else
		navigate(`/${language}`, {
			replace: true,
		})
}
