import { ResourceLanguage } from 'i18next'
import {
	AccountRoutes,
	AddressErrors,
	AddressTranslations,
	Cart,
	Delivery,
	DeliveryStatusTranslations,
	ErrorTranslations,
	FilterColor,
	FooterPage,
	MenuCategoryTranslations,
	OrderStatusTranslation,
	Password,
	PasswordRecover,
	Payment,
	Product,
	ReturnOrder,
	SignIn,
	SignUp,
	Translations,
	Returns,
	Faqs,
	Partners,
	Privacy,
	Cookie,
	Terms,
} from './translations.enum'

const translations: Record<
	| Translations
	| MenuCategoryTranslations
	| AccountRoutes
	| FilterColor
	| AddressTranslations
	| AddressErrors
	| Password
	| Cart
	| Payment
	| SignUp
	| SignIn
	| Product
	| FooterPage
	| Delivery
	| ReturnOrder
	| DeliveryStatusTranslations
	| PasswordRecover
	| OrderStatusTranslation
	| ErrorTranslations
	| Returns
	| Faqs
	| Partners
	| Privacy
	| Cookie
	| Terms,
	string
> = {
	SEARCH: 'Buscar',
	COOKIE_POLICY: 'Política de Cookies',
	TERMS_CONDITIONS: 'Términos y Condiciones',
	PRIVACY_POLICY: 'Política de Privacidad',
	FAQS: 'FAQs',
	SPANISH: 'Español',
	ENGLISH: 'Inglés',
	ITALIAN: 'Italiano',
	PORTUGUESE: 'Portugués',
	SHOPPING_BAG: 'Carrito',
	WISHLIST: 'Lista de deseos',
	MYFINAEJERIQUE: 'Mi cuenta',
	CUSTOMER_SERVICE: 'Atención al Cliente',
	NEED_HELP_ORDER: '¿Necesitas ayuda con tu pedido?',
	COLOR: 'Color',
	SIZE: 'Talla',
	SIZE_CHART: 'Guia de tallas',
	REGION: 'Región',
	LANGUAGE: 'Idioma',
	SHIPPING_TO: 'Envío a',
	SHIPPING_LOCATION: 'España',
	BABY: 'Bebé',
	NEW_BORN: 'Recién nacido',
	CHILDREN: 'Infantil',
	SIGN_IN: 'Iniciar sesión',
	SIGN_OUT: 'Cerrar sesión',
	SIGN_UP: 'Registrate',
	LOADING: 'Cargando',
	DESCRIPTION: 'Descripción',
	DETAILS: 'Detalles',
	DELIVERY: 'Envío',
	CONTACT_US: 'Contáctanos',
	SIMILAR_PRODUCTS: 'Productos similares',
	YOU_MAY_ALSO_LIKE: 'También te puede gustar',
	ADD_TO_CART: 'Añadir al carrito',
	CLEAR_FILTERS: 'Limpiar filtros',
	FILTER: 'Filtrar',
	APPLY: 'Aplicar',
	ACCOUNT_OVERVIEW: 'Resumen de cuenta',
	ORDER_HISTORY: 'Historial de pedidos',
	ADDRESS_BOOK: 'Libreta de direcciones',
	ADDRESS_EDIT: 'Editar dirección',
	ADDRESS_NEW: 'Nueva dirección',
	CHANGE_PASSWORD: 'Cambiar contraseña',
	CONTINUE_TO_PAYMENT: 'Continuar al pago',
	ORDER_VIEW: 'Ver pedido',
	RED_PALETTE: 'Rojos',
	PINK_PALETTE: 'Rosas',
	PURPLE_PALETTE: 'Morados',
	BLUE_PALETTE: 'Azules',
	GREEN_PALETTE: 'Verdes',
	YELLOW_PALETTE: 'Amarillos',
	ORANGE_PALETTE: 'Naranjas',
	BROWN_PALETTE: 'Marrones',
	GRAY_PALETTE: 'Grises',
	WHITE_PALETTE: 'Blancos',
	BLACK_PALETTE: 'Negros',
	MY_INFO: 'Información de mi cuenta',
	ORDER_RETURN: 'Devolución de pedido',
	RETURNS: 'Devoluciones',
	RETURN_VIEW: 'Ver devolución',
	ORDER_HISTORY_DESCRIPTION: 'Comprueba tu historial de pedidos',
	ADDRESS_BOOK_DESCRIPTION: 'Gestiona tu libreta de direcciones',
	PASSWORD_CHANGE_DESCRIPTION: 'Cambia tu contraseña',
	RETURN_DESCRIPTION: 'Comprueba el estado de tus devoluciones',
	WISHLIST_DESCRIPTION: 'Lista de deseos de productos de Fina Ejerique',
	ACCOUNT_OVERVIEW_DESCRIPTION: 'Hola',
	ADDRESS: 'Dirección',
	ADDRESS_NO_ADDRESSES: 'Crea tu primera dirección',
	ADDRESS_FIRST_NAME: 'Nombre',
	ADDRESS_LAST_NAME: 'Apellidos',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_CITY: 'Ciudad',
	ADDRESS_PROVINCE: 'Provincia',
	ADDRESS_POSTAL_CODE: 'Código postal',
	ADDRESS_STREET_AND_NUMBER: 'Calle y número',
	ADDRESS_ADDITIONAL_DATA: 'Piso/Puerta u otro (Opcional)',
	ADDRESS_PHONE_NUMBER: 'Número de teléfono',
	ADDRESSES: 'Direcciones',
	ADD_ADDRESS: 'Añadir dirección',
	EDIT_ADDRESS: 'Editar dirección',
	DELIVERY_ADDRESS: 'Dirección de entrega',
	ADDRESS_NAME: 'Nombre de la dirección (Ej. Casa, Trabajo)',
	SAVE_ADDRESS: 'Guardar dirección',
	EDIT: 'Editar',
	DELETE: 'Borrar',
	CONTINUE_SHOPPING: 'Continuar comprando',
	CHECK_OUT: 'Pagar',
	SUBTOTAL: 'Subtotal',
	TOTAL: 'Total',
	SHIPPING: 'Envío',
	SELECT_ADDRESS: 'Por favor, selecciona una dirección',
	UNITS: 'Unidades',
	ORDER_SUMMARY: 'Resumen del pedido',
	ALL_FIELDS_REQUIRED: 'Todos los campos son requeridos',
	MESSAGE_SENT_SUCCESSFULLY: 'Mensaje enviado satisfactoriamente',
	AN_ERROR_OCCURRED: 'Ocurrió un error',
	SEND_MESSAGE: 'Enviar mensaje',
	MESSAGE: 'Mensaje',
	CALL_US: 'Llámanos',
	EMAIL_US: 'Envianos un correo',
	CONTACT_US_DESCRIPTION: `Si necesitas ayuda para realizar un
					pedido o tienes alguna pregunta sobre
					nuestra tienda, nuestro equipo está
					disponible para responder a tus
					consultas de lunes a viernes, de 9 am a
					3 pm.`,
	NEWS_LETTER: 'Newsletter',
	NEWS_LETTER_LABEL: 'Introduce tu email',
	NEWS_LETTER_SIGNUP: 'Suscríbete para recibir actualizaciones',
	NEWS_LETTER_BUTTON: 'Suscribirse',
	ORDERING_AND_SHIPPING: 'Pedido y envío',
	THE_FE_STORY: 'Historia de Fina Ejerique',
	CAREERS: 'Careers',
	CUSTOMER_CARE: 'Atención al cliente',
	ABOUT_FE: 'Sobre Fina Ejerique',
	FOLLOW_FE: 'Sigue a Fina Ejerique',
	VIEW_DETAILS: 'Ver detalles',
	ITEMS: 'Artículos',
	ORDER_NUMBER: 'Número de pedido',
	REQUEST_RETURN: 'Solicitar devolución',
	ERROR_ADDRESS_NAME: 'Por favor seleccione un nombre para la dirección',
	ERROR_ADDRESS_FIRST_NAME: 'Por favor agrega tu nombre',
	ERROR_ADDRESS_LAST_NAME: 'Por favor agrega tu apellido',
	ERROR_ADDRESS_COUNTRY: 'por favor seleccione su país',
	ERROR_ADDRESS_CITY: 'Por favor selecciona tu ciudad',
	ERROR_ADDRESS_PROVINCE: 'Por favor selecciona tu provincia',
	ERROR_ADDRESS_POSTAL_CODE: 'Por favor seleccione su código postal',
	ERROR_ADDRESS_STREET_AND_NUMBER: 'Por favor seleccione su dirección',
	ERROR_ADDRESS_PHONE_NUMBER:
		'Por favor seleccione un número de teléfono válido',
	NO_ADDRESSES: 'No hay direcciones',
	BACK: 'Volver',
	PERSONAL_DATA: 'Datos personales',
	BIRTH_DATE: 'Fecha de nacimiento',
	SELECT_SIZE: 'Por favor, selecciona una talla',
	RETURN_ADDRESS: 'Direccion de devolucion',
	DRAFT: 'Borrador',
	PENDING_PAYMENT: 'Pendiente de pago',
	PAID: 'Pagado',
	PAYMENT_FAILED: 'Pago fallido',
	PENDING_REVIEW: 'Pendiente de revisión',
	REVIEWED: 'Revisado',
	SENT_TO_SIMSS: 'Enviado a SIMSS',
	ERROR_SENDING_TO_SIMSS: 'Error al enviar a SIMSS',
	CARRIER_NOTIFIED: 'Transportista notificado',
	ERROR_NOTIFYING_CARRIER: 'Error al notificar al transportista',
	SHIPPED: 'Enviado',
	DELIVERED: 'Entregado',
	COMPLETED: 'Completado',
	CANCELLED: 'Cancelado',
	PASSWORD_CHANGE: 'Cambiar la contraseña',
	PASSWORD_CURRENT: 'Contraseña actual',
	PASSWORD_NEW: 'Nueva contraseña',
	PASSWORD_CONFIRM: 'Confirmar nueva contraseña',
	PASSWORD_DETAILS:
		'La contraseña debe contener: <br />- Al menos 8 caracteres <br />- Al menos una letra mayúscula <br />- Al menos una letra minúscula <br /> - Al menos un número <br />- Al menos un carácter especial (@, #, $, %, etc.)',
	PASSWORD_ERROR_OLD: 'Por favor agrega tu antigua contraseña',
	PASSWORD_ERROR_NEW: 'Por favor agrega tu nueva contraseña',
	PASSWORD_ERROR_CONFIRM: 'Por favor confirma tu nueva contraseña',
	PASSWORD_ERROR_NOT_MATCH: 'Las contraseñas no coinciden',
	PASSWORD_ERROR_INCOMPLETE:
		'La contraseña debe contener al menos 8 caracteres, un número, una letra y un carácter especial.',
	PASSWORD_ERROR_INCORRECT:
		'La contraseña actual no es correcta. Compruébelo por favor.',
	PASSWORD_ERROR_ERROR:
		'Ocurrió un error. Por favor, inténtelo de nuevo más tarde.',
	CART_BAG: 'Carrito',
	CART_EMAIL: 'Email',
	CART_DELIVERY: 'Entrega',
	CART_PAYMENT: 'Pago',
	CHECK_EMAIL_COMMUNICATIONS:
		'Me gustaría recibir comunicaciones exclusivas por correo electrónico de Fina Ejerique.',
	CHECK_TERMS_AND_CONDITIONS:
		'Al realizar su pedido, acepta los términos de uso del sitio web y la política de privacidad de Fina Ejerique.',
	RETURNORDER_CHECK_TERMS_AND_CONDITIONS:
		'Al realizar su devolución, acepta los términos de uso del sitio web y la política de privacidad de Fina Ejerique.',
	PAYMENT_CONFIRM_AND_PAY: 'Confirma y paga',
	PAYMENT_ERROR_TERMS_CONDITIONS: 'Debe aceptar los Términos y Condiciones',
	PAYMENT_ERROR_PAYMENT_METHOD: 'Debes seleccionar un método de pago',
	PAYMENT_ERROR_CREATING_ORDER_ID: 'Error al crear el pedido',
	PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND: 'Método de pago no encontrado',
	PAYMENT_ERROR_CREATING_ORDER_RETRY:
		'Error al crear el pedido. Por favor, inténtelo de nuevo más tarde.',
	PAYMENT_CREDIT_CARD: 'Tarjeta de crédito/débito',
	PAYMENT_CREDIT_CARD_DESCRIPTION: 'Utilice su tarjeta para pagar',
	PAYMENT_BIZUM: 'Bizum',
	PAYMENT_BIZUM_DESCRIPTION: 'Paga a través de Bizum',
	SIGNUP_CREATE_ACCOUNT: 'Registrate',
	SIGNUP_EMAIL: 'Dirección de correo electrónico',
	SIGNUP_FIRST_NAME: 'Nombres',
	SIGNUP_LAST_NAME: 'Apellidos',
	SIGNUP_BIRTHDAY: 'Fecha de nacimiento (opcional)',
	SIGNUP_COUNTRY: 'Elige el país',
	SIGNUP_PASSWORD: 'Contraseña',
	SIGNUP_CONFIRM_PASSWORD: 'Confirmar Contraseña',
	SIGNUP_ALREADY_EXISTS: '¿Ya tienes una cuenta?',
	SIGNUP_SIGNIN: 'Iniciar Sesión',
	SIGNUP_ERROR_INVALID_EMAIL: 'Dirección de correo electrónico no válida',
	SIGNUP_ERROR_INVALID_PASSWORD: 'Por favor proporcione una contraseña válida',
	SIGNUP_ERROR_TERMS_CONDITIONS: 'Debe aceptar los Términos y Condiciones',
	SIGNUP_ERROR_PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
	SIGNUP_ERROR_COUNTRY: 'Por favor seleccione un país',
	SIGNUP_ERROR_FIRST_NAME: 'Por favor proporciona tu primer nombre',
	SIGNUP_ERROR_LAST_NAME: 'Por favor proporcione su apellido',
	SIGNUP_ERROR_RETRY:
		'Ocurrió un error. Por favor, inténtelo de nuevo más tarde',
	SIGNUP_ERROR_CHECK_FIELDS:
		'Existen errores en los campos. Por favor, revísalos.',
	SIGNIN_CREATE_ACCOUNT: 'Iniciar Sesión',
	SIGNIN_EMAIL: 'Dirección de correo electrónico',
	SIGNIN_PASSWORD: 'Contraseña',
	SIGNIN_PASSWORD_FORGOT: '¿Olvidaste tu contraseña?',
	SIGNIN_ACCOUNT_NOT_EXISTS: '¿No tienes una cuenta?',
	SIGNIN_SIGNUP: 'Registrate',
	SIGNIN_ERROR_INVALID_EMAIL:
		'Por favor, introduce una dirección de correo electrónico válida',
	SIGNIN_ERROR_SIGNIN:
		'Error de inicio de sesion. Por favor, verifique sus credenciales',
	SIGNIN_ERROR_RETRY:
		'Ocurrió un error. Por favor, inténtelo de nuevo más tarde',
	SIGNUP_ERROR_DUPLICATED_EMAIL: 'Éste correo electrónico ya está registrado',
	PRODUCT_CONTACT_US_DESCRIPTION:
		'Si necesitas ayuda para realizar un pedido o tienes alguna pregunta sobre nuestra tienda, nuestro equipo está disponible para responder a tus consultas de lunes a viernes, de 9 am a 3 pm.',
	PRODUCT_CONTACT_US_BUTTON: 'Contactar',
	FOOTER_PAGE_PAYMENT_TYPE_TITLE: 'Formas de pago',
	FOOTER_PAGE_DEVOLUTIONS: 'Las devoluciones son GRATUITAS.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION:
		'Puedes utilizar los siguientes medios de pago: Visa, Visa Electron, Mastercard, Maestro, JCB y UPAY.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1: '•  La tarjeta no esté caducada.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2:
		'•  Que tu tarjeta no exceda la fecha de validez.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3:
		'•  Puede que se haya alcanzado el límite de la tarjeta. Consulta con tu banco que la tarjeta no haya excedido el importe permitido para efectuar compras.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4:
		'•  Comprueba que has rellenado correctamente todos los campos necesarios.',
	FOOTER_PAGE_INVOICE_QUESTION:
		'¿Puedo obtener una factura a nombre de mi empresa?',
	FOOTER_PAGE_INVOICE_ANSWER:
		'Sí. Solamente tienes que marcar la opción de «empresa» en datos personales y cubrir los datos fiscales que te pedimos.',
	FOOTER_PAGE_SECURITY_TITLE: 'Seguridad',
	FOOTER_PAGE_SECURITY_DESCRIPTION:
		'Para Fina Ejerique, la seguridad en las compras online es fundamental. Todas las transacciones se llevan a cabo a través de sistemas de pago seguros y los datos confidenciales del pago son transmitidos directamente y de forma encriptada (SSL) a la entidad. Contamos con los certificados SSL de Sectigo RSA Domain Validation Secure Server CA y con los certificados de seguridad de transacciones online: Verified by VISA, MasterCard SecureCode y American Express Safekey',
	FOOTER_PAGE_DELIVERY: 'Envíos',
	DELIVERY_TITLE: 'ENTREGA A DOMICILIO GRATUITA A PARTIR DE 50€',
	DELIVERY_DESCRIPTION_1:
		'Los <b>plazos de entrega</b> van de 1 a 3 días laborables en la península (1 o 2 días más en las Islas Baleares). Envíos unicamente a España y Portugal. No se realizan envíos a las Islas Canarias.',
	DELIVERY_DESCRIPTION_2:
		'<b>Los plazos de entrega son para los </b> pedidos realizados de lunes a viernes antes de las 09.00h am. Después de esa hora, el plazo comenzará al siguiente día hábil. En periodos especiales como Black Friday o Rebajas, el plazo de envío puede ser superior al habitual.',
	RETURNORDER_DEVOLUTION_N: 'Nº de devolución',
	RETURNORDER_ORDER_N: 'Numero del Pedido',
	RETURNORDER_BUY_DATE: 'Fecha de Compra',
	RETURNORDER_PRODUCTS: 'Artículos',
	RETURNORDER_SUBTOTAL: 'Subtotal',
	RETURNORDER_TOTAL: 'Total',
	RETURNORDER_SHIPPING: 'Envío',
	RETURNORDER_CONTACT_SUPPORT: 'Error. Pongase en contacto con soporte tecnico',
	RETURNORDER_NO_SELECTED_PRODUCTS: 'No hay productos seleccionados',
	RETURNORDER_SELECT_TERMS_CONDITIONS:
		'Tienes que aceptar los Terminos y Condiciones para poder seguir.',
	RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE:
		'La solicitud de devolución ha sido registrada',
	NEWS_LETTER_SUCCESS: 'Gracias por suscribirte',
	NEWS_LETTER_ERROR:
		'Error al suscribirte. Por favor, inténtalo de nuevo más tarde',
	NEWS_LETTER_ALREADY_SUSCRIBED: 'Ya estás suscrito',
	PASSWORD_RECOVERY: 'Recuperar contraseña',
	PASSWORD_RECOVERY_DESCRIPTION:
		'Introduce tu email y te enviaremos un correo con la nueva contraseña.',
	PASSWORD_RECOVERY_EMAIL: 'Email',
	PASSWORD_RECOVERY_ERROR_INVALID_EMAIL: 'Email no válido',
	PASSWORD_RECOVERY_ERROR_RETRY:
		'Ocurrió un error. Por favor, inténtalo de nuevo más tarde',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND: 'El email no está registrado',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT:
		'Error al enviar el email. Por favor, inténtalo de nuevo más tarde',
	PASSWORD_RECOVERY_SUCCESS: 'Hemos enviado un email la nueva contraseña',
	PASSWORD_RECOVERY_ERROR:
		'Ocurrió un error. Por favor, inténtalo de nuevo más tarde',
	PASSWORD_RECOVERY_ERROR_EMAIL: 'Por favor, introduce un email válido',
	PASSWORD_RECOVERY_SUBMIT: 'Enviar',
	Draft: 'Borrador',
	'Pending payment': 'Pendiente de pago',
	Paid: 'Pagado',
	'Payment failed': 'Pago fallido',
	'Paid pending review': 'Pagado pendiente de revisión',
	Reviewed: 'Revisado',
	'Sent to SIMSS': 'Enviado a SIMSS',
	'Error sending to SIMSS': 'Error al enviar a SIMSS',
	'Carrier notified': 'Transportista notificado',
	'Error notifying carrier': 'Error al notificar al transportista',
	Shipped: 'Enviado',
	Delivered: 'Entregado',
	Completed: 'Completado',
	Cancelled: 'Cancelado',
	PAGE_NOT_FOUND: 'Página no encontrada',
	NO_PRODUCTS_FOUND: 'No se encontraron productos para esta busqueda',

	RETURN_TITLE: 'Devoluciones y cambios',
	RETURN_SUBTITLE_1: 'Las devoluciones son GRATUITAS',
	RETURN_TEXT_1: 'Dispones de 30 días tras la recepción de tu pedido para  solicitar su devolución. Para ello, puedes solicitar la recogida gratuita a domicilio y enviaremos un transportista a recoger tu paquete:',
	RETURN_TEXT_2: 'Los plazos de entrega son para los pedidos realizados de lunes a viernes antes de las 09.00h am. Después de esa hora, el plazo comenzará al siguiente día hábil. En periodos especiales como Black Friday o Rebajas, el plazo de envío puede ser superior al habitual.',
	RETURN_SUBTITLE_2: '1. Solicita la recogida a domicilio en finaejerique.com',
	RETURN_TEXT_3: 'Si estás registrado, accede al apartado Historial y detalles del pedido en la sección Mi cuenta. A continuación, selecciona el pedido donde se encuentra el/los producto/s a devolver y una vez dentro, selecciónalo y dale a la opción Solicitar una devolución. Una vez realizados todos estos pasos procederemos a la recogida del paquete y si el producto se encuentra en buen estado se realizará el reembolso de la cantidad total del producto.',
	RETURN_SUBTITLE_3: '2. Prepara tu paquete',
	RETURN_TEXT_4: 'Empaqueta tus artículos de forma segura, en el paquete original si es posible, y pega la etiqueta de devolución en el paquete.',
	RETURN_TEXT_5: 'Podrás obtener un albarán de devolución en Devolución de mercancías, seleccionando tu pedido e imprimiendo el "Formulario de devolución".',
	RETURN_SUBTITLE_4: '3. El transportista acudirá a tu domicilio para recoger tu paquete',
	RETURN_TEXT_6: 'Una vez realizados los pasos anteriores, el transportista acudirá a tu domicilio para proceder con la recogida.',
	RETURN_TEXT_7: 'Si se trata de una devolución, el reembolso se hará efectivo en el mismo método de pago utilizado en la compra y en un plazo de entre 3 y 12 días laborables desde que recibimos tu paquete en nuestros almacenes.',

	FAQS_TITLE_1: 'Envío y seguimiento del pedido',
	FAQS_TITLE_2: 'Devoluciones, cambios y reembolsos',
	FAQS_TITLE_3: 'Pagos, promociones y tarjetas regalo',
	FAQS_TITLE_4: 'Información de tallas y productos',
	FAQS_TITLE_5: 'Instrucciones de Cuidado',
	FAQS_TITLE_6: 'Guía de Tallas - Bebé',
	FAQS_TITLE_7: 'Guía de Tallas - Niños',
	FAQS_TITLE_8: 'Datos personales y comunicaciones',
	FAQS_Q_1: '¿Cuáles son los tipos de envío y los plazos de entrega de un pedido?',
	FAQS_Q_2: '¿Dónde está mi pedido?',
	FAQS_Q_3: '¿Puedo cambiar la dirección de entrega de un pedido?',
	FAQS_Q_4: '¿Puedo cancelar mi pedido?',
	FAQS_Q_5: '¿Qué puedo hacer si no he recibido todos los artículos de mi compra?',
	FAQS_Q_6: '¿Qué puedo hacer si he recibido algún artículo incorrecto o defectuoso?',
	FAQS_Q_7: '¿Cómo puedo cambiar o devolver una compra online?',
	FAQS_Q_8: '¿Cómo puedo saber el estado de mi devolución?',
	FAQS_Q_9: '¿Cómo y cuándo recibiré mi reembolso?',
	FAQS_Q_10: '¿Cuáles son las condiciones de devolución?',
	FAQS_Q_11: '¿Cómo puedo pagar mis compras online?',
	FAQS_Q_12: '¿Puedo obtener una factura a nombre de mi empresa?',
	FAQS_Q_13: 'Tengo un código promocional, ¿cómo lo utilizo?',
	FAQS_Q_14: '¿Cómo cuido mis prendas?',
	FAQS_Q_15: 'Lavado',
	FAQS_Q_16: 'Uso de lejía',
	FAQS_Q_17: 'Planchado',	
	FAQS_Q_18: 'Lavado en seco',
	FAQS_Q_19: 'Secado',
	FAQS_Q_20: '¿Cuál es mi talla?',	
	FAQS_Q_21: 'He olvidado mi contraseña, ¿cómo puedo recuperarla?',
	FAQS_A_1: 'ENTREGA A DOMICILIO GRATUITA A PARTIR DE 50€',
	FAQS_A_2: 'Los plazos de entrega van de 1 a 3 días laborables en la península (1 o 2 días más en las Islas Baleares). No se realizan envíos a las Islas Canarias.',
	FAQS_A_3: 'Los plazos de entrega son para los pedidos realizados de lunes a viernes antes de las 09.00h am. Después de esa hora, el plazo comenzará al siguiente día hábil. En periodos especiales como Black Friday o Rebajas, el plazo de envío puede ser superior al habitual.',
	FAQS_A_4: 'Consulta los detalles de tus compras y conoce su estado en todo momento desde Mi cuenta. Recibirás notificaciones por e-mail para que puedas hacer el seguimiento de forma fácil. No pierdas de vista nuestras comunicaciones.',
	FAQS_A_5: 'Podemos realizar el cambio de dirección de un pedido mientras se encuentre en preparación en nuestros almacenes. Una vez está preparado para el envío o en manos del transportista, ya no podemos realizar cambios sobre el pedido. Consulta el estado en Mis pedidos y, si estás a tiempo, contacta con nuestro equipo de Atención al cliente.',
	FAQS_A_6: 'Puedes solicitar a nuestro equipo de Atención al cliente la cancelación de tu pedido siempre que se encuentre en preparación en nuestro almacén. Si tu pedido ya no se puede cancelar, recuerda que tienes 30 días desde el envío para hacer cambios y devoluciones de forma fácil.',
	FAQS_A_7: 'En el caso de que alguno de los artículos de tu compra se haya agotado en el último momento y no te lo hayamos enviado, tendrás un e-mail con un aviso. Además, te reembolsaremos el importe de los artículos agotados y los gastos de envío en el mismo método de pago de la compra.',
	FAQS_A_8: 'Sentimos que alguno de los artículos no sea lo que esperabas. Haz la devolución desde Mi Cuenta indicando en el formulario de devolución que se trata de un artículo incorrecto o defectuoso. Te reembolsaremos el importe de los artículos más los gastos de envío en cuanto los recibamos en nuestros almacenes.',
	FAQS_A_9: 'Las devoluciones son GRATUITAS. Dispones de 30 días tras la recepción de tu pedido para solicitar su devolución. Para ello, puedes solicitar la recogida gratuita a domicilio y enviaremos un transportista a recoger tu paquete:',
	FAQS_A_10: '1. Solicita la recogida a domicilio en finaejerique.com',
	FAQS_A_11: 'Si estás registrado, accede al apartado Mi cuenta. A continuación, selecciona el pedido donde se encuentra el/los producto/s a devolver y una vez dentro, selecciónalo y dale a la opción Solicitar una devolución. Una vez realizados todos estos pasos procederemos a la recogida del paquete y si el producto se encuentra en buen estado se realizará el reembolso de la cantidad total del producto.',
	FAQS_A_12: '2. Prepara tu paquete',
	FAQS_A_13: 'Empaqueta tus artículos de forma segura, en el paquete original si es posible, y pega la etiqueta de devolución en el paquete.',
	FAQS_A_14: '3. El transportista acudirá a tu domicilio para recoger tu paquete',
	FAQS_A_15: 'Una vez realizados los pasos anteriores, el transportista acudirá a tu domicilio para proceder con la recogida.',
	FAQS_A_16: 'Si hiciste una devolución tu paquete puede tardar varios días en llegar a nuestros almacenes. Recibirás un e-mail cuando realicemos el reembolso de las prendas o, en caso de un cambio, cuando enviemos la nueva talla que solicitaste.',
	FAQS_A_17: 'Si se trata de una devolución, el reembolso se hará efectivo en el mismo método de pago utilizado en la compra y en un plazo de entre 3 y 12 días laborables desde que recibimos tu paquete en nuestros almacenes. Recuerda que te reembolsaremos el importe de las prendas, no de los gastos de envío (solo si la prenda estaba defectuosa o era incorrecta). Si tu compra superó los 50€, no reembolsaremos los gastos de envío dado que el envío fue gratuito.',
	FAQS_A_18: 'Los artículos deben devolverse en el mismo estado que se recibieron. Los artículos que hayas arreglado o estén personalizados tampoco se podrán cambiar ni devolver porque están hechos exclusivamente para ti y no se podrán poner de nuevo a la venta.',
	FAQS_A_19: 'Puedes utilizar los siguientes medios de pago: Visa, Visa Electron, Mastercard, Maestro, JCB, UPAY y Bizum.',
	FAQS_A_20: 'En el caso de que el banco rechace tu tarjeta comprueba que:',
	FAQS_A_21: 'La tarjeta no esté caducada.',
	FAQS_A_22: 'Que tu tarjeta no exceda la fecha de validez.',
	FAQS_A_23: 'Puede que se haya alcanzado el límite de la tarjeta. Consulta con tu banco que la tarjeta no haya excedido el importe permitido para efectuar compras.',
	FAQS_A_24: 'Comprueba que has rellenado correctamente todos los campos necesarios.',
	FAQS_A_25: 'Sí. Solamente tienes que marcar la opción de «empresa» en datos personales y cubrir los datos fiscales que te pedimos.',
	FAQS_A_26: 'Antes de finalizar la compra, encuentra el espacio código promocional donde podrás añadir tu código (solo uno por compra). Si no se aplica ningún descuento, revisa las condiciones de la promoción o su fecha de validez. Puede que el código haya caducado o que los artículos de tu compra no entren en la promoción. Verás el importe acumulado que puedes descontar antes de finalizar tu compra.',
	FAQS_A_27: 'Instrucciones específicas sobre el cuidado de las prendas se encuentran en la etiqueta de cada artículo. Sigue estas indicaciones para mantener tus prendas en buen estado.',
	FAQS_A_28: 'Lavar a máquina. El número (30º C, 40º C, 60º C, 95º C) indica la temperatura máxima de lavado.',
	FAQS_A_29: 'Lavar a máquina con programa normal y centrifugado corto. El número (30º C, 40º C, 60º C, 95º C) indica la temperatura máxima de lavado.',
	FAQS_A_30: 'Lavar a mano máximo 30º C. No frotar ni escurrir.',
	FAQS_A_31: 'No lavar.',
	FAQS_A_32: 'Se puede usar lejía.',
	FAQS_A_33: 'No se puede usar lejía.',
	FAQS_A_34: 'Planchado a temperatura alta: máximo 200º C. Algodón, lino o viscosa.',
	FAQS_A_35: 'Planchado a temperatura media: máximo 150º C. Lana y mezclas de poliéster.',
	FAQS_A_36: 'Planchado a temperatura baja: máximo 110º C. Lana y mezclas de poliéster. Seda natural, rayón, acetato o acrílico.',
	FAQS_A_37: 'No planchar.',
	FAQS_A_38: 'Las letras (A, F, P) contenidas en los círculos indican el tipo de producto adecuado para cada prenda (información útil para los especialistas en tintorería).',
	FAQS_A_39: 'No se puede lavar en seco.',
	FAQS_A_40: 'Se puede usar secadora.',
	FAQS_A_41: 'No se puede usar secadora.',
	FAQS_A_42: 'Secar en secadora a baja temperatura.',
	FAQS_A_43: 'Secar en secadora a temperatura normal.',
	FAQS_A_44: 'Utiliza la Guía de tallas: verás las medidas que corresponden a cada talla para que puedas medirte y elegir cuál se adapta mejor a tu hijo/a.',
	FAQS_A_45: 'Cuando vayas a iniciar sesión en tu cuenta, verás un enlace para cambiar tu contraseña si la has olvidado. Luego, tendrás que añadir el e-mail de tu cuenta para recibir un mensaje con los pasos a seguir. ¡Es muy sencillo!',
	FAQS_TABLE1_1: 'Talla',
	FAQS_TABLE1_2: '1m',
	FAQS_TABLE1_3: '3m',
	FAQS_TABLE1_4: '6m',
	FAQS_TABLE1_5: '9m',
	FAQS_TABLE1_6: '12m',
	FAQS_TABLE1_7: '18m',
	FAQS_TABLE1_8: '2a',
	FAQS_TABLE1_9: '3a',
	FAQS_TABLE1_10: '4a',
	FAQS_TABLE2_1: 'Estatura',
	FAQS_TABLE3_1: 'Pecho',
	FAQS_TABLE4_1: 'Cintura',
	FAQS_TABLE5_1: 'Cadera',
	FAQS_TABLE6_1: 'Largo manga',
	FAQS_TABLE7_1: 'Largo pantalón niño',
	FAQS_TABLE8_1: 'Talla',
	FAQS_TABLE8_2: '2a',
	FAQS_TABLE8_3: '3a',
	FAQS_TABLE8_4: '4a',
	FAQS_TABLE8_5: '5a',
	FAQS_TABLE8_6: '6a',
	FAQS_TABLE8_7: '8a',
	FAQS_TABLE8_8: '10a',
	FAQS_TABLE8_9: '12a',
	FAQS_TABLE8_10: '14a',
	FAQS_TABLE9_1: 'Estatura',
	FAQS_TABLE10_1: 'Pecho',
	FAQS_TABLE11_1: 'Cintura',
	FAQS_TABLE12_1: 'Cadera',
	FAQS_TABLE13_1: 'Largo manga',
	FAQS_TABLE14_1: 'Largo pantalón niño',
	PARTNERS_TITLE: 'Partners',
	PARTNERS_TEXT_1: 'GLOBOTEX SL, ha sido beneficiaria de Fondos Europeos, cuyo objetivo es el refuerzo del crecimiento sostenible y la competitividad de las PYMES, y gracias al cual ha puesto en marcha un Plan de Acción con el objetivo de mejorar su competitividad mediante la transformación digital, lapromoción online y el comercio electrónico en mercados internacionales durante el año 2024. Para ello ha contado con el apoyo del ProgramaXpande Digital de la Cámara de Comercio de Valencia. #EuropaSeSiente',
	PARTNERS_TEXT_2: 'GLOBOTEX SOCIEDAD DE RESPONSABILIDAD LIMITADA, ha sido beneficiaria de Fondos Europeos, cuyo objetivo es el refuerzo del crecimiento sostenible y la competitividad de las PYMES, y gracias al cual ha puesto en marcha un Plan de Internacionalización con el objetivo de mejorar su posicionamiento competitivo en el exterior durante el año 2024. Para ello ha contado con el apoyo del Programa Xpande Digital de la Cámara de Comercio de Valencia. #EuropaSeSiente',
	PRIVACY_TITLE: 'POLÍTICA DE PRIVACIDAD',
	PRIVACY_SUBTITLE_1: 'Tratamiento de los datos de clientes',
	PRIVACY_SUBTITLE_2: 'Información básica sobre Protección de datos',
	PRIVACY_SUBTITLE_3: 'Información completa sobre Protección de Datos',
	PRIVACY_SUBTITLE_4: 'Tratamiento de los datos de potenciales clientes y contactos',
	PRIVACY_SUBTITLE_5: 'Información básica sobre Protección de datos',
	PRIVACY_SUBTITLE_6: 'Información completa sobre Protección de Datos',
	PRIVACY_TEXT_1: 'En GLOBOTEX, S.L. nos preocupamos por la privacidad y la transparencia. A continuación, le indicamos en detalle los tratamientos de datos personales que realizamos, así como toda la información relativa a los mismos.',
	PRIVACY_TEXT_2: 'Responsable',
	PRIVACY_TEXT_3: 'GLOBOTEX, S.L.',
	PRIVACY_TEXT_4: 'Finalidad',
	PRIVACY_TEXT_5: 'Prestar los servicios solicitados y enviar comunicaciones comerciales.',
	PRIVACY_TEXT_6: 'Legitimación',
	PRIVACY_TEXT_7: 'Ejecución de un contrato. Interés legítimo del Responsable.',
	PRIVACY_TEXT_8: 'Destinatarios',
	PRIVACY_TEXT_9: 'No se cederán datos a terceros, salvo obligación legal.',
	PRIVACY_TEXT_10: 'Derechos',
	PRIVACY_TEXT_11: 'Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, indicados en la información adicional, que puede ejercer dirigiéndose a legal@finaejerique.es o C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_12: 'Procedencia',
	PRIVACY_TEXT_13: 'El propio interesado.',
	PRIVACY_TEXT_14: '1. ¿Quién es el responsable del tratamiento de sus datos?',
	PRIVACY_TEXT_15: 'GLOBOTEX, S.L.<br />B97230692<br />C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA - VALENCIA<br />963135167<br />legal@finaejerique.es',
	PRIVACY_TEXT_16: '2. ¿Con qué finalidad tratamos sus datos personales?',
	PRIVACY_TEXT_17: 'En GLOBOTEX, S.L. tratamos la información que nos facilitan las personas interesadas con el fin de realizar la gestión administrativa, contable y fiscal de los servicios solicitados, así como enviar comunicaciones comerciales sobre nuestros productos y servicios. En el caso de que no facilite sus datos personales, no podremos cumplir con las finalidades descritas.<br />No se van a tomar decisiones automatizadas en base a los datos proporcionados.',
	PRIVACY_TEXT_18: '3. ¿Por cuánto tiempo conservaremos sus datos?',
	PRIVACY_TEXT_19: 'Los datos se conservarán mientras el interesado no solicite su supresión, y en su caso, durante los años necesarios para cumplir con las obligaciones legales.',
	PRIVACY_TEXT_20: '4. ¿Cuál es la legitimación para el tratamiento de sus datos?',
	PRIVACY_TEXT_21: 'Le indicamos la base legal para el tratamiento de sus datos:',
	PRIVACY_TEXT_22: 'Ejecución de un contrato',
	PRIVACY_TEXT_23: 'Gestión fiscal, contable y administrativa de clientes. (RGPD art. 6.1.b).',
	PRIVACY_TEXT_24: 'Interés legítimo del Responsable',
	PRIVACY_TEXT_25: 'Envío de comunicaciones comerciales incluso por vía electrónica. (RGPD Considerando 47, LSSICE art. 21.2).',
	PRIVACY_TEXT_26: '5. ¿A qué destinatarios se comunicarán sus datos?',
	PRIVACY_TEXT_27: 'No se cederán datos a terceros, salvo obligación legal.',
	PRIVACY_TEXT_28: '6. Transferencias de datos a terceros países',
	PRIVACY_TEXT_29: 'No están previstas transferencias de datos a terceros países.',
	PRIVACY_TEXT_30: '7. ¿Cuáles son sus derechos cuando nos facilita sus datos?',
	PRIVACY_TEXT_31: 'Cualquier persona tiene derecho a obtener confirmación sobre si en GLOBOTEX, S.L. estamos tratando, o no, datos personales que les conciernan.',
	PRIVACY_TEXT_32: 'Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. Igualmente, tienen derecho a la portabilidad de sus datos.',
	PRIVACY_TEXT_33: 'En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.',
	PRIVACY_TEXT_34: 'En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, GLOBOTEX, S.L. dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.',
	PRIVACY_TEXT_35: 'Podrá ejercitar materialmente sus derechos de la siguiente forma: dirigiéndose a legal@finaejerique.es o C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_36: 'Cuando se realice el envío de comunicaciones comerciales utilizando como base jurídica el interés legítimo del responsable, el interesado podrá oponerse al tratamiento de sus datos con ese fin.',
	PRIVACY_TEXT_37: 'Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.',
	PRIVACY_TEXT_38: 'En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.aepd.es.',
	PRIVACY_TEXT_39: '8. ¿Cómo hemos obtenido sus datos?',
	PRIVACY_TEXT_40: 'Los datos personales que tratamos en GLOBOTEX, S.L. proceden de: El propio interesado.',
	PRIVACY_TEXT_41: 'Las categorías de datos que se tratan son:',
	PRIVACY_TEXT_42: 'Datos identificativos',
	PRIVACY_TEXT_43: 'Direcciones postales y electrónicas',
	PRIVACY_TEXT_44: 'Información comercial',
	PRIVACY_TEXT_45: 'Responsable',
	PRIVACY_TEXT_46: 'GLOBOTEX, S.L.',
	PRIVACY_TEXT_47: 'Finalidad',
	PRIVACY_TEXT_48: 'Atender su solicitud y enviarle comunicaciones comerciales.',
	PRIVACY_TEXT_49: 'Legitimación',
	PRIVACY_TEXT_50: 'Ejecución de un contrato. Consentimiento del interesado. Interés legítimo del Responsable.',
	PRIVACY_TEXT_51: 'Destinatarios',
	PRIVACY_TEXT_52: 'No se cederán datos a terceros, salvo obligación legal.',
	PRIVACY_TEXT_53: 'Derechos',
	PRIVACY_TEXT_54: 'Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, indicados en la información adicional, que puede ejercer dirigiéndose a legal@finaejerique.es o C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_55: 'Procedencia',
	PRIVACY_TEXT_56: 'El propio interesado.',
	PRIVACY_TEXT_57: '1. ¿Quién es el responsable del tratamiento de sus datos?',
	PRIVACY_TEXT_58: 'GLOBOTEX, S.L.<br />B97230692<br />C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA - VALENCIA<br />963135167<br />legal@finaejerique.es',
	PRIVACY_TEXT_59: '2. ¿Con qué finalidad tratamos sus datos personales?',
	PRIVACY_TEXT_60: 'En GLOBOTEX, S.L. tratamos la información que nos facilitan las personas interesadas con el fin de realizar la gestión de potenciales clientes que se han interesado sobre nuestros productos y/o servicios, así como otros contactos comerciales. Envío de comunicaciones comerciales inclusive por vía electrónica. En el caso de que no facilite sus datos personales, no podremos cumplir con las finalidades descritas.<br />No se van a tomar decisiones automatizadas en base a los datos proporcionados.',
	PRIVACY_TEXT_61: '3. ¿Por cuánto tiempo conservaremos sus datos?',
	PRIVACY_TEXT_62: 'Los datos se conservarán mientras el interesado no solicite su supresión.',
	PRIVACY_TEXT_63: '4. ¿Cuál es la legitimación para el tratamiento de sus datos?',
	PRIVACY_TEXT_64: 'Le indicamos la base legal para el tratamiento de sus datos:',
	PRIVACY_TEXT_65: 'Ejecución de un contrato',
	PRIVACY_TEXT_66: 'Gestión de potenciales clientes que se han interesado sobre nuestros productos y/o servicios. (RGPD, art. 6.1.b, LSSICE art.21).',
	PRIVACY_TEXT_67: 'Consentimiento del interesado',
	PRIVACY_TEXT_68: 'Enviar comunicaciones comerciales, inclusive por vía electrónica. (RGPD, art. 6.1.a).',
	PRIVACY_TEXT_69: 'Interés legítimo del Responsable',
	PRIVACY_TEXT_70: 'Gestión de los datos de contacto profesionales (LOPDGDD art.19, RGPD art. 6.1.f).',
	PRIVACY_TEXT_71: '5. ¿A qué destinatarios se comunicarán sus datos?',
	PRIVACY_TEXT_72: 'No se cederán datos a terceros, salvo obligación legal.',
	PRIVACY_TEXT_73: '6. Transferencias de datos a terceros países',
	PRIVACY_TEXT_74: 'No están previstas transferencias de datos a terceros países.',
	PRIVACY_TEXT_75: '7. ¿Cuáles son sus derechos cuando nos facilita sus datos?',
	PRIVACY_TEXT_76: 'Cualquier persona tiene derecho a obtener confirmación sobre si en GLOBOTEX, S.L. estamos tratando, o no, datos personales que les conciernan.',
	PRIVACY_TEXT_77: 'Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. Igualmente, tienen derecho a la portabilidad de sus datos.',
	PRIVACY_TEXT_78: 'En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.',
	PRIVACY_TEXT_79: 'En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, GLOBOTEX, S.L. dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.',
	PRIVACY_TEXT_80: 'Podrá ejercitar materialmente sus derechos de la siguiente forma: dirigiéndose a legal@finaejerique.es o C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_81: 'Cuando se realice el envío de comunicaciones comerciales utilizando como base jurídica el interés legítimo del responsable, el interesado podrá oponerse al tratamiento de sus datos con ese fin.',
	PRIVACY_TEXT_82: 'Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.',
	PRIVACY_TEXT_83: 'En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.aepd.es.',
	PRIVACY_TEXT_84: '8. ¿Cómo hemos obtenido sus datos?',
	PRIVACY_TEXT_85: 'Los datos personales que tratamos en GLOBOTEX, S.L. proceden de: El propio interesado.',
	PRIVACY_TEXT_86: 'Las categorías de datos que se tratan son:',
	PRIVACY_TEXT_87: 'Datos identificativos',
	PRIVACY_TEXT_88: 'Direcciones postales y electrónicas',
	PRIVACY_TEXT_89: 'Información comercial',

	COOKIE_TITLE: 'Política de Cookies',
	COOKIE_SUBTITLE_1: '¿QUÉ SON LAS COOKIES?',
	COOKIE_SUBTITLE_2: 'TIPOS DE COOKIES',
	COOKIE_SUBTITLE_3: 'DESACTIVACIÓN Y ELIMINACIÓN DE COOKIES',
	COOKIE_SUBTITLE_4: 'Aceptación de la Política de cookies',
	COOKIE_TEXT_1:'En la web GLOBOTEX, S.L. informa acerca del uso de las cookies en su página web:',
	COOKIE_TEXT_2:'Las cookies son archivos que se pueden descargar en su equipo a través de las páginas web. Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.',
	COOKIE_TEXT_3:'Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos:',
	COOKIE_TEXT_4:'Cookies propias',
	COOKIE_TEXT_5:'Aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.',
	COOKIE_TEXT_6:'Cookies de terceros',
	COOKIE_TEXT_7:'Aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.',
	COOKIE_TEXT_8:'Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:',
	COOKIE_TEXT_9:'Cookies de sesión',
	COOKIE_TEXT_10: 'Diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.',
	COOKIE_TEXT_11: 'Cookies persistentes',
	COOKIE_TEXT_12: 'Los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.',
	COOKIE_TEXT_13: 'Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos:',
	COOKIE_TEXT_14: 'Cookies técnicas',
	COOKIE_TEXT_15: 'Permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.',
	COOKIE_TEXT_16: 'Cookies de personalización',
	COOKIE_TEXT_17: 'Permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios.',
	COOKIE_TEXT_18: 'Cookies de análisis',
	COOKIE_TEXT_19: 'Permiten el seguimiento y análisis del comportamiento de los usuarios de los sitios web.',
	COOKIE_TEXT_20: 'Cookies publicitarias',
	COOKIE_TEXT_21: 'Permiten la gestión de los espacios publicitarios.',
	COOKIE_TEXT_22: 'Cookies de publicidad comportamental',
	COOKIE_TEXT_23: 'Almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación.',
	COOKIE_TEXT_24: 'Cookies de redes sociales externas',
	COOKIE_TEXT_25: 'Se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales.',
	COOKIE_TEXT_26: 'Tienes la opción de permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en su equipo. Al desactivar cookies, algunos de los servicios disponibles podrían dejar de estar operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el menú Herramientas u Opciones. También puede consultarse el menú de Ayuda del navegador, donde puedes encontrar instrucciones. El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web.',
	COOKIE_TEXT_27: 'Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:',
	COOKIE_TEXT_28: 'Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes:',
	COOKIE_TEXT_29: 'muestra información sobre su Política de cookies en la parte inferior o superior de cualquier página del portal con cada inicio de sesión con el objeto de que usted sea consciente.',
	COOKIE_TEXT_30: 'Ante esta información es posible llevar a cabo las siguientes acciones:',
	COOKIE_TEXT_31: 'Aceptar cookies',
	COOKIE_TEXT_32: 'No se volverá a visualizar este aviso al acceder a cualquier página del portal durante la presente sesión.',
	COOKIE_TEXT_33: 'Modificar su configuración',
	COOKIE_TEXT_34: 'Podrá obtener más información sobre qué son las cookies, conocer la Política de cookies de https://finaejerique.com/ y modificar la configuración de su navegador.',

	TERMS_TITLE: 'AVISO LEGAL',
	TERMS_SUBTITLE_1: '1. OBJETO',
	TERMS_SUBTITLE_2: '2. IDENTIFICACIÓN',
	TERMS_SUBTITLE_3: '3. COMUNICACIONES',
	TERMS_SUBTITLE_4: '4. CONDICIONES DE ACCESO Y UTILIZACIÓN',
	TERMS_SUBTITLE_5: '4.1. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA UTILIZACIÓN',
	TERMS_SUBTITLE_6: '4.2. PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO',
	TERMS_SUBTITLE_7: '4.3. PUBLICACIONES',
	TERMS_SUBTITLE_8: '5. LEGISLACIÓN APLICABLE',
	TERMS_TEXT_1: 'El presente aviso legal regula el uso y utilización del sitio web <a href="http://www.finaejerique.com">www.finaejerique.com</a>, del que es titular GLOBOTEX, S.L. (en adelante, EL PROPIETARIO DE LA WEB).',
	TERMS_TEXT_2: 'La navegación por el sitio web de EL PROPIETARIO DE LA WEB le atribuye la condición de USUARIO del mismo y conlleva su aceptación plena y sin reservas de todas y cada una de las condiciones publicadas en este aviso legal, advirtiendo de que dichas condiciones podrán ser modificadas sin notificación previa por parte de EL PROPIETARIO DE LA WEB, en cuyo caso se procederá a su publicación y aviso con la máxima antelación posible.',
	TERMS_TEXT_3: 'Por ello es recomendable leer atentamente su contenido en caso de desear acceder y hacer uso de la información y de los servicios ofrecidos desde este sitio web.',
	TERMS_TEXT_4: 'El usuario, además, se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal, y responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.',
	TERMS_TEXT_5: 'Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo EL PROPIETARIO DE LA WEB denegar o retirar el acceso y su uso en cualquier momento.',
	TERMS_TEXT_6: 'EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, le informa de que:',
	TERMS_TEXT_7: 'Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:',
	TERMS_TEXT_8: 'Tfno.',
	TERMS_TEXT_9: 'Todas las notificaciones y comunicaciones entre los usuarios y PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados anteriormente.',
	TERMS_TEXT_10: 'El sitio web y sus servicios son de acceso libre y gratuito. No obstante, PROPIETARIO DE LA WEB puede condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa cumplimentación del correspondiente formulario.',
	TERMS_TEXT_11: 'El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a PROPIETARIO DE LA WEB y será el único responsable de las manifestaciones falsas o inexactas que realice.',
	TERMS_TEXT_12: 'El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de PROPIETARIO DE LA WEB y a no emplearlos para, entre otros:',
	TERMS_TEXT_13: 'a) Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.',
	TERMS_TEXT_14: 'b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de PROPIETARIO DE LA WEB o de terceras personas.',
	TERMS_TEXT_15: 'c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer información.',
	TERMS_TEXT_16: 'd) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de PROPIETARIO DE LA WEB o de terceros.',
	TERMS_TEXT_17: 'e) Suplantar la identidad de cualquier otro usuario.',
	TERMS_TEXT_18: 'f) Reproducir, copiar, distribuir, poner a disposición de, o cualquier otra forma de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.',
	TERMS_TEXT_19: 'g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.',
	TERMS_TEXT_20: 'Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.',
	TERMS_TEXT_21: 'Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.',
	TERMS_TEXT_22: 'La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos.',
	TERMS_TEXT_23: 'El contenido del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.',
	TERMS_TEXT_24: 'PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:',
	TERMS_TEXT_25: 'a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.',
	TERMS_TEXT_26: 'b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.',
	TERMS_TEXT_27: 'c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web.',
	TERMS_TEXT_28: 'Asimismo, PROPIETARIO DE LA WEB declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster.',
	TERMS_TEXT_29: 'La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido.',
	TERMS_TEXT_30: 'En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a PROPIETARIO DE LA WEB identificándose debidamente y especificando las supuestas infracciones.',
	TERMS_TEXT_31: 'La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal.',
	TERMS_TEXT_32: 'Las condiciones presentes se regirán por la legislación española vigente. La lengua utilizada será el Castellano.',

	RETURNORDER_TITLE: 'Devolución de productos',
	RETURNORDER_CHOOSE_PRODUCTS: 'Escoja los productos a devolver',
	RETURNORDER_ADDRESS: 'Dirección de recogida',
	RETURNORDER_ALL_PRODUCTS: 'All the products have been returned',
}

export const es: ResourceLanguage = {
	translation: translations,
}

export default es
