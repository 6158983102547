import { Box, Typography } from '@mui/material'
import { IAddress } from './interface/address.interface'
import { Link, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { AddressPreviewInfo } from './address-preview-info'
import { useTranslation } from 'react-i18next'

export interface IAddressPreviewProps {
	address: IAddress
	retrieveAddresses: () => void
	isAccountSettings?: boolean
	selectedAddress?: string
	setSelectedAddress?: (
		addressName: string
	) => void
	setEditingAddress?: (address?: string) => void
}

const AddressPreview = (
	props: IAddressPreviewProps
) => {
	const { t } = useTranslation()
	const { language } = useParams()
	const deleteAddress = async () => {
		const accessToken = Cookies.get('accessToken')

		if (!accessToken) {
			window.location.href = '/login'
		}

		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/user/address/${props.address.id}`,
			{
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		).then((response) => {
			if (!response.ok) {
				throw new Error('Error deleting address')
			} else {
				props.retrieveAddresses()
			}
		})
	}

	return (
		<Box
			sx={{
				marginY: '1rem',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Typography
					sx={{
						fontSize: '1.5rem',
					}}
				>
					{props.address.name}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						gap: '1.5rem',
					}}
				>
					<Link
						to={
							props.isAccountSettings
								? `/${language}/account/addresses/edit/${props.address.name}`
								: ``
						}
						onClick={() => {
							props.setEditingAddress?.(
								props.address.name
							)
						}}
						style={{
							color: 'black',
							textUnderlineOffset: '0.3rem',
						}}
					>
						{t('EDIT')}
					</Link>
					{props.isAccountSettings && (
						<Typography
							style={{
								color: 'black',
								textUnderlineOffset: '0.3rem',
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
							onClick={deleteAddress}
						>
							{t('DELETE')}
						</Typography>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					border:
						props.selectedAddress ===
						props.address.name
							? '1px solid #000'
							: '1px solid #dfdfdf',
					padding: '1rem',
					cursor: props.setSelectedAddress
						? 'pointer'
						: 'default',
				}}
				onClick={() => {
					if (props.setSelectedAddress) {
						props.setSelectedAddress(
							props.address.name
						)
					}
				}}
			>
				<AddressPreviewInfo
					address={props.address}
				/>
			</Box>
		</Box>
	)
}

export default AddressPreview
