import { LocalStorageVariables } from '../enums/local-storage.enum'
import { ICart } from '../interfaces/cart.interface'
import { RequestManager } from '../components/utils'
import { deepClone } from './deep-clone'

export const emptyCart: () => ICart = () =>
	deepClone({
		products: {},
		total: 0,
		delivery: 0,
		subtotal: 0,
	})

export type ICartUpdate = (cart: ICart) => void

export function cleanCart(setCart: ICartUpdate) {
	updateCartData(emptyCart(), setCart)
}

export async function retrieveCart(setCart: ICartUpdate, isLogged: boolean) {
	if (isLogged) {
		const response = await RequestManager('GET', 'cart')
		if ([200, 201].includes(response.status)) {
			const cartData = JSON.parse(
				localStorage.getItem(LocalStorageVariables.CART) ||
					JSON.stringify(emptyCart())
			)
			const dataToUpdate = {
				...response.data,
				products: {
					...cartData?.products,
					...response.data.products,
				},
			}
			updateCartData(dataToUpdate, setCart)
		}
	} else {
		const cartData = JSON.parse(
			localStorage.getItem(LocalStorageVariables.CART) ||
				JSON.stringify(emptyCart())
		)
		updateCartData(cartData, setCart)
	}
}

export async function fetchUpdatedCart(
	cartData: ICart,
	setCart: ICartUpdate,
	isLogged: boolean
) {
	if (isLogged) {
		const response = await RequestManager('POST', 'cart', true, cartData)

		if ([200, 201].includes(response.status)) {
			updateCartData(response.data, setCart)
		}
	} else {
		const response = await RequestManager('POST', 'cart/price', false, cartData)

		if ([200, 201].includes(response.status)) {
			const cartResponse: ICart = response.data

			delete (cartResponse as any).products
			delete cartData.subTotalWithDiscount
			delete cartData.totalWithDiscount

			const updatedCart = {
				...cartData,
				...cartResponse,
			}

			updateCartData(updatedCart, setCart)
		}
	}
}

const updateCartData = (cartData: ICart, setCart: ICartUpdate) => {
	localStorage.setItem(LocalStorageVariables.CART, JSON.stringify(cartData))
	setCart(cartData)
}
