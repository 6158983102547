import { styled } from '@mui/material/styles'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector'
import { Box } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { CartReview } from './cart-review'
import { OrderSummary } from './order-summary'
import Payment from '../payment/payment'
import { ICart } from '../../interfaces/cart.interface'
import Cookies from 'js-cookie'
import { ICartUpdate } from '../../utils/cart.util'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { OrderAddress } from './order-address'
import { CartLogin } from './cart-login'
import { CardPaymentPage } from '../payment/redsys-v2'
import { Cart as CartTranslation } from '../../translations/translations.enum'

const ColorlibConnector = styled(StepConnector)(
	({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]:
			{
				top: 9,
			},
		[`& .${stepConnectorClasses.line}`]: {
			height: 1,
			border: 0,
			backgroundColor: '#000', // Líneas de color negro
			borderRadius: 1,
		},
	})
)

export interface ICartProps extends IRoute {
	cart: ICart
	deleteCartProduct: (
		modelId: number,
		variantId: number | 'default' | undefined
	) => void

	setIsLogged: (isLogged: boolean) => void
	setCart: ICartUpdate
	updateCart: (
		modelId: number,
		variantId: number,
		quantity: number
	) => void
}

export default function Cart(props: ICartProps) {
	const { t } = useTranslation()
	const steps = [
		t(CartTranslation.CART_BAG),
		t(CartTranslation.CART_EMAIL),
		t(CartTranslation.CART_DELIVERY),
		t(CartTranslation.CART_PAYMENT),
	]
		
	const [currentStep, setCurrentStep] =
		useState<number>(0)

	const [order, setOrder] = useState<
		IOrderRequest | undefined
	>(undefined)

	const nextStep = () => {
		window.scrollTo(0, 0)
		setCurrentStep(currentStep + 1)
	}

	const getStepBody = () => {
		switch (currentStep) {
			case 1:
				if (!Cookies.get('accessToken')) {
					return (
						<CartLogin
							isMobile={props.isMobile}
							nextStep={nextStep}
							setIsLogged={props.setIsLogged}
							setCart={props.setCart}
							isNewCart={true}
						/>
					)
				} else {
					nextStep()
					break
				}
			case 2:
				return (
					<OrderSummary
						isMobile={props.isMobile}
						order={order}
						setOrder={setOrder}
						body={
							<OrderAddress
								nextStep={nextStep}
								isMobile={false}
								order={order}
							/>
						}
					/>
				)
			case 3:
				return (
					<OrderSummary
						order={order}
						setOrder={setOrder}
						isMobile={props.isMobile}
						body={
							<Payment
								nextStep={nextStep}
								order={order}
								setCart={props.setCart}
							/>
						}
					/>
				)
			case 4:
				return (
					<OrderSummary
						order={order}
						setOrder={setOrder}
						isMobile={props.isMobile}
						body={
							<CardPaymentPage order={order} />
						}
					/>
				)
			default:
				return (
					<CartReview
						isMobile={props.isMobile}
						nextStep={nextStep}
						cart={props.cart}
						deleteCartProduct={
							props.deleteCartProduct
						}
						updateCart={props.updateCart}
					/>
				)
		}
	}

	return (
		<Box
			sx={{
				padding: '20px',
			}}
		>
			<Box
				sx={{
					width: '100%',
					marginTop: '20px',
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'center',
				}}
			>
				<Stepper
					alternativeLabel
					activeStep={currentStep}
					connector={<ColorlibConnector />}
					sx={{
						width: props.isMobile
							? '100%'
							: '65%',
					}}
				>
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel
								sx={{
									'.MuiStepLabel-label': {
										// Cambia el color del texto para el estado no activo
										color: 'grey',
										'&.Mui-active': {
											// Color para el paso activo
											color: 'black',
										},
										'&.Mui-completed': {
											// Color para los pasos completados
											color: 'black',
										},
									},
								}}
							>
								{label}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>

			{getStepBody()}
		</Box>
	)
}
