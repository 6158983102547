import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Container, Typography, TextField, IconButton } from '@mui/material'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { LoginButton } from './login-button'
import { ICartUpdate } from '../../utils/cart.util'
import { saveAccessToken } from '../utils'
import { SignInFooter } from '../utils/sign-in-footer'
import { useTranslation } from 'react-i18next'
import { SignIn } from '../../translations/translations.enum'

export interface ILoginProps {
	nextStep?: () => void
	isMobile: boolean
	setIsLogged: (isLogged: boolean) => void
	setCart: ICartUpdate
	isNewCart?: boolean
	changeLoginForm?: () => void
	isOrderLogin?: boolean
}

export const Login = (props: ILoginProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { language } = useParams()
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		form: '',
	})
	const [errors, setErrors] = useState<any>({})
	const [showPassword, setShowPassword] = useState(false)

	const validateEmail = (email: string) => {
		return /\S+@\S+\.\S+/.test(email)
	}

	const handleChange = (e: any) => {
		const { name, value } = e.target
		setFormData({ ...formData, [name]: value })

		// Limpiar errores al cambiar el campo
		if (errors[name]) {
			setErrors({ ...errors, [name]: '' })
		}
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		let tempErrors: any = {}

		// Validación de email
		if (!validateEmail(formData.email)) {
			tempErrors.email = t(SignIn.SIGNIN_ERROR_INVALID_EMAIL)
		}

		setErrors(tempErrors)

		if (Object.keys(tempErrors).length === 0) {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_CORE_API_URL}/auth/login`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							email: formData.email,
							password: formData.password,
						}),
					}
				)

				if (response.ok) {
					const data = await response.json()

					saveAccessToken(
						data,
						navigate,
						props.setIsLogged,
						props.nextStep,
						props.setCart,
						props.isNewCart
					)
				} else if (!response.ok && [401].includes(response.status)) {
					setErrors({
						...errors,
						email: t(SignIn.SIGNIN_ERROR_SIGNIN),
					})
				} else {
					setErrors({
						...errors,
						email: t(SignIn.SIGNIN_ERROR_RETRY),
					})
				}
			} catch (error) {
				console.error('Error submitting form:', error)
				// Manejo de error de red o del servidor
				setErrors({
					...errors,
					email: t(SignIn.SIGNIN_ERROR_RETRY),
				})
			}
		}
	}

	const handleClickShowPassword = () => setShowPassword(!showPassword)
	const handleMouseDownPassword = (event: any) => event.preventDefault()
	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{
				padding: '7.5% 2%',
				width: props.isMobile ? '100%' : '25%',
			}}
		>
			<div>
				<Typography
					component='h1'
					variant='h5'
					fontFamily={'Bembo'}
					sx={{
						textAlign: 'left',
						fontSize: '2rem',
					}}
				>
					{t(SignIn.SIGNIN_CREATE_ACCOUNT)}
				</Typography>
				<form
					noValidate
					onSubmit={handleSubmit}
				>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label={t(SignIn.SIGNIN_EMAIL)}
						name='email'
						autoComplete='email'
						autoFocus
						value={formData.email}
						onChange={handleChange}
						error={!!errors.email}
						helperText={errors.email}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label={t(SignIn.SIGNIN_PASSWORD)}
						type={showPassword ? 'text' : 'password'}
						id='password'
						autoComplete='current-password'
						value={formData.password}
						onChange={handleChange}
						error={!!errors.password}
						helperText={errors.password}
						InputProps={{
							endAdornment: (
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							),
						}}
					/>
					<Link
						to={`/${language}/password-recovery`}
						style={{
							textDecoration: 'none',
							color: 'black',
							textAlign: 'right',
							marginBottom: '1rem',
						}}
					>
						{t(SignIn.SIGNIN_PASSWORD_FORGOT)}
					</Link>
					<LoginButton />
					<SignInFooter
						urlPath={`signup`}
						firstText={t(SignIn.SIGNIN_ACCOUNT_NOT_EXISTS)}
						secondText={t(SignIn.SIGNIN_SIGNUP)}
						isOrderLogin={props.isOrderLogin}
						isMobile={props.isMobile}
						changeLoginForm={props.changeLoginForm}
					/>
				</form>
			</div>
		</Container>
	)
}
