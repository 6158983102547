import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, {
	AccordionProps,
} from '@mui/material/Accordion'
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { Translations } from '../../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import CategoryFilter from '../filter'
import { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'

const Accordion = styled(
	(props: AccordionProps) => (
		<MuiAccordion
			disableGutters
			elevation={0}
			square
			{...props}
		/>
	)
)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&::before': {
		display: 'none',
	},
}))

const AccordionSummary = styled(
	(props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			{...props}
			expandIcon={
				<ArrowForwardIosSharpIcon
					sx={{ fontSize: '0.9rem' }}
				/>
			}
		/>
	)
)(({ theme }) => ({
	backgroundColor: 'white',
	// Remueve flexDirection si estaba establecido a 'row-reverse'
	'& .MuiAccordionSummary-content': {
		justifyContent: 'space-between', // Esto distribuye el contenido y el icono en extremos opuestos
		width: '100%', // Asegura que el contenido use todo el ancho disponible
	},
	'& .MuiAccordionSummary-expandIconWrapper': {
		// Alinea el icono a la derecha
		marginRight: 20, // Ajusta según necesidad para alinear el icono correctamente
		position: 'absolute',
		right: 0,
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
		{
			transform: 'rotate(90deg)',
		},
}))

const AccordionDetails = styled(
	MuiAccordionDetails
)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export interface IFilterAccordionProps {
	setColor: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
	setSize: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
	colors: Record<string, boolean>
	sizes: Record<string, boolean>
	isMobile: boolean
}

export default function FilterAccordion(
	props: IFilterAccordionProps
) {
	const [expanded, setExpanded] = React.useState<
		string | false
	>('panel1')

	const { t } = useTranslation()

	const handleChange =
		(panel: string) =>
		(
			event: React.SyntheticEvent,
			newExpanded: boolean
		) => {
			setExpanded(newExpanded ? panel : false)
		}

	return (
		<Box mb={'4rem'}>
			<Accordion
				expanded={expanded === 'panel1'}
				onChange={handleChange('panel1')}
			>
				<AccordionSummary
					aria-controls='panel1d-content'
					id='panel1d-header'
				>
					<Typography fontWeight={'bold'}>
						{t(Translations.SIZE)}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<CategoryFilter
						setter={props.setSize}
						by={Translations.SIZE}
						values={props.sizes}
						isMobile={props.isMobile}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel2'}
				onChange={handleChange('panel2')}
			>
				<AccordionSummary
					aria-controls='panel2d-content'
					id='panel2d-header'
				>
					<Typography fontWeight={'bold'}>
						{t(Translations.COLOR)}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<CategoryFilter
						setter={props.setColor}
						by={Translations.COLOR}
						values={props.colors}
						isMobile={props.isMobile}
					/>
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}
