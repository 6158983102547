import {
	Box,
	IconButton,
	InputBase,
	Toolbar,
} from '@mui/material'
import LogoSVG from '../../../assets/logo-svg'
import { MobileHeaderMenuButton } from './buttons/menu'
// import { MobileHeaderSearchButton } from './buttons/search'
import { MobileHeaderWishListButton } from './buttons/wishlist'
import { MobileHeaderShippingCartButton } from './buttons/shopping-cart'
import { MobileHeaderToolbarBox } from './toolbar-box'
import { Link } from 'react-router-dom'
import { MobileHeaderSearchButton } from '../../../search/mobile/search.mobile'
import { LanguageISOCode } from '../../../../enums/language-iso-code.enum'

export interface IMobileHeaderToolbar {
	toggleDrawer: () => void
	setDrawerOpen: (isOpen: boolean) => void
	drawerOpen: boolean
	language: LanguageISOCode
}

export const MobileHeaderToolbar = (
	props: IMobileHeaderToolbar
) => {
	return (
		<Toolbar
			sx={{
				height: '45px',
				minHeight: '45px !important',
			}}
		>
			<Link
				to='/'
				onClick={() => props.setDrawerOpen(false)}
			>
				<LogoSVG />
			</Link>

			<MobileHeaderToolbarBox justifyContent='flex-start'>
				<MobileHeaderMenuButton
					toggleDrawer={props.toggleDrawer}
					drawerOpen={props.drawerOpen}
				/>
				<MobileHeaderSearchButton
					setDrawerOpen={props.setDrawerOpen}
					language={props.language}
				/>
			</MobileHeaderToolbarBox>
			<MobileHeaderToolbarBox justifyContent='flex-end'>
				{/* <MobileHeaderWishListButton
					setDrawerOpen={props.setDrawerOpen}
				/> */}
				<MobileHeaderShippingCartButton
					language={props.language}
					setDrawerOpen={props.setDrawerOpen}
				/>
			</MobileHeaderToolbarBox>
		</Toolbar>
	)
}
