import { Box, Divider } from '@mui/material'

export const MobileHeaderDivider = () => {
	return (
		<Box padding={'0 8px'}>
			<Divider
				orientation='horizontal'
				variant='inset'
				sx={{
					margin: '16px',
				}}
			/>
		</Box>
	)
}
