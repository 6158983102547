import { useState } from 'react'
import {
	TextField,
	Button,
	Box,
	Typography,
	Alert,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../translations/translations.enum'
import { RequestManager } from '../utils'

export const FooterForm = () => {
	const { t } = useTranslation()
	const [inputValue, setInputValue] = useState('')
	const [successMessage, setSuccessMessage] =
		useState('')
	const [errorMessage, setErrorMessage] =
		useState('')

	const handleSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault()

		try {
			const response = await RequestManager(
				'POST',
				'news-letter/subscribe',
				false,
				{
					email: inputValue,
				}
			)
			if (response.status === 201) {
				setSuccessMessage(
					t(Translations.NEWS_LETTER_SUCCESS)
				)
				setErrorMessage('')
			} else if (response.status === 409) {
				setSuccessMessage('')
				setErrorMessage(
					t(
						Translations.NEWS_LETTER_ALREADY_SUSCRIBED
					)
				)
			} else {
				setSuccessMessage('')
				setErrorMessage(
					t(Translations.NEWS_LETTER_ERROR)
				)
			}
		} catch (error) {
			setSuccessMessage('')
			setErrorMessage(
				t(Translations.NEWS_LETTER_ERROR)
			)
		}
	}

	return (
		<Box
			component='form'
			sx={{
				display: 'flex',
				alignItems: 'left',
				width: 'auto',
				flexDirection: 'column',
			}}
			onSubmit={handleSubmit}
			noValidate
			autoComplete='off'
		>
			<Box sx={{ paddingBottom: '10px' }}>
				<Typography fontWeight={'bold'}>
					{t(Translations.NEWS_LETTER_SIGNUP)}
				</Typography>
			</Box>
			<Box>
				<TextField
					label={t(
						Translations.NEWS_LETTER_LABEL
					)}
					variant='outlined'
					value={inputValue}
					onChange={(e) =>
						setInputValue(e.target.value)
					}
					sx={{
						height: 40,
					}}
					InputProps={{
						style: {
							height: 40,
							borderRadius: 0,
							borderColor: 'primary.main',
							backgroundColor: 'white',
						},
					}}
					size='small'
				/>
				<Button
					type='submit'
					variant='contained'
					sx={{
						height: 40,
						alignSelf: 'stretch',
						fontWeight: 'bold',
						letterSpacing: '1.25px',
						fontSize: '0.75rem',
						borderRadius: 0,
					}}
				>
					{t(Translations.NEWS_LETTER_BUTTON)}
				</Button>
				{successMessage && (
					<Box
						sx={{
							marginTop: '10px',
							width: '40.5%',
						}}
					>
						<Alert severity='success'>
							{successMessage}
						</Alert>
					</Box>
				)}
				{errorMessage && (
					<Box
						sx={{
							marginTop: '10px',
							width: '40.5%',
						}}
					>
						<Alert severity='error'>
							{errorMessage}
						</Alert>
					</Box>
				)}
			</Box>
		</Box>
	)
}
