export interface IFLogoSVG {
	width?: string
	height?: string
	loaded?: boolean
}

const FLogoSVG = (props: IFLogoSVG) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 1200 1200'
		style={{
			display: props.loaded ? 'none' : 'block',
		}}
		className='svg-carga'
	>
		<path
			d='M203.4 379.8c0 217 210.5 244.4 367.3 247l14.4 164.7c9.2 108.5-32.7 266.7-163.3 266.7-121.6 0-185.7-75.8-185.7-190.8 0-130.8 95.4-179.1 162.1-179.1 2.6 0 6.6 0 9.1 1.2-9.1 7.9-14.3 26.3-14.3 47.1 0 26.2 18.3 45.8 44.4 45.8 24.8 0 41.9-27.5 41.9-52.3 0-41.8-38-69.2-86.3-69.2-90.2 0-189.6 81-189.6 206.5 0 141.1 91.5 220.8 218.4 220.8 202.5 0 254.8-181.6 245.6-296.7l-13-164.7-.3-3.6 53.4-3.7c126.3-10.1 228.1-69.6 276.2-163.9 20.7-40.8 16.4-82.4-12.8-97.2-20.9-10.7-58.5-6.2-72.8 18.4-22.5 38.4 10.5 78.4 50.4 76.1-38 74.5-102.3 119.9-228.6 136.5l-38.3 2.6-30.1.4-10.2-118.5c-10.4-126.8 9.2-149 17-168.6 2.6-7.9 6.6-15.7 6.6-20.9 0-9.2-7.9-10.5-10.5-10.5-27.5 0-104.5 87.6-95.4 200l9.1 121.6c-149-1.3-332-19.6-332-215.7 0-154.3 111.2-193.5 205.2-193.5 13.1 0 24.9 1.4 38 3.9l301.9 57.6c9.1 1.3 26.1 3.9 35.3 3.9 62.7 0 124.1-62.8 158.1-99.4 5.3-5.2 18.3-20.9 18.3-30q0-7.8-7.8-7.9c-4 0-10.5 4-17 10.5-61.4 53.6-81 58.9-125.5 58.9-15.7 0-47-4-54.9-5.3L508 118.4c-10.4-1.3-39.2-6.6-49.7-6.6-109.8 0-254.9 64.1-254.9 268z'
			style={{
				fill: '#CCCCCC',
			}}
		/>
	</svg>
)
export default FLogoSVG
