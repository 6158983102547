import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import { IFooterLink } from '../../interfaces/footer-links.interface'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface IFooterNavColumnProps {
	links: IFooterLink
}

export const FooterNavColumn = (prop: IFooterNavColumnProps) => {
	const { t } = useTranslation()
	const getList = (links: IFooterLink) => {
		const header = links.title
		const url = links.url
		const sublinks = links.sublinks
		const hasSublinks = sublinks && sublinks.length > 0

		const headListItem = (
			<ListItem
				key={header}
				sx={{
					padding: '0.5rem 0',
					margin: '0',
				}}
			>
				<Typography
					fontWeight={'bold'}
					key={header}
				>
					{t(header)}
				</Typography>
			</ListItem>
		)

		const subListItems = hasSublinks
			? sublinks.map((sublink, index) => {
					const { title, url } = sublink
					return (
						<ListItem
							key={title}
							sx={{
								padding: '0.5rem 0',
								margin: '0',
							}}
						>
							<Link
								to={url}
								style={{
									textDecoration: 'none',
									color: 'inherit',
									cursor: 'pointer',
								}}
								onClick={() => {
									window.scrollTo(0, 0)
								}}
							>
								<Typography>{t(title)}</Typography>
							</Link>
						</ListItem>
					)
			  })
			: null

		return (
			<Grid
				item
				xs={12}
				sm={6}
				md={4}
			>
				<List>
					{headListItem}
					{subListItems}
				</List>
			</Grid>
		)
	}

	return getList(prop.links)
}
