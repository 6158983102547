import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../../translations/translations.enum'
import { Dispatch, SetStateAction } from 'react'

export interface IFilterClearButtonProps {
	setColor: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
	setSize: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
}

export const FilterClearButton = (
	props: IFilterClearButtonProps
) => {
	const isMobile = true
	const { t } = useTranslation()
	const border = isMobile ? '2px solid' : 'none'

	const clearFilters = () => {
		props.setColor({})
		props.setSize({})
	}
	return (
		<Button
			sx={{
				borderRadius: '0px',
				backgroundColor: 'secondary.main',
				color: 'primary.main',
				boxShadow: 'none',
				width: '100%',
				border: border,
				padding: '.85rem 0',
			}}
			onClick={clearFilters}
		>
			<Typography
				sx={{
					fontSize: '0.785rem',
					fontWeight: 'bold',
					letterSpacing: '.14rem',
				}}
			>
				{t(Translations.CLEAR_FILTERS)}
			</Typography>
		</Button>
	)
}
