import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface IBlackButton {
	text: string
	inverted?: boolean
	onClick?: () => void
	width?: string
	disabled?: boolean
}

export const BlackButton = (
	props: IBlackButton
) => {
	const isMobile = true
	const { t } = useTranslation()
	const border = isMobile ? '2px solid' : 'none'

	return (
		<Button
			onClick={props.onClick}
			disabled={props.disabled}
			sx={{
				borderRadius: '0px',
				backgroundColor: props.inverted
					? 'secondary.main'
					: 'primary.main',
				color: props.inverted
					? 'primary.main'
					: 'secondary.main',
				boxShadow: 'none',
				width: props.width || '100%',
				border: border,
				padding: '.85rem 1rem',
				transition:
					'background-color 300ms ease-out, border-color 300ms ease-out',
				'&:hover': {
					backgroundColor: props.inverted
						? 'primary.main'
						: 'secondary.main',
					color: props.inverted
						? 'secondary.main'
						: 'primary.main',
					boxShadow: 'none',
					border: '2px solid',
				},
			}}
			variant='contained'
			type='submit'
		>
			<Typography
				sx={{
					fontSize: '0.785rem',
					fontWeight: 'bold',
					letterSpacing: '.14rem',
				}}
			>
				{t(props.text)}
			</Typography>
		</Button>
	)
}
