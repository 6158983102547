import { Tabs, Tab, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MobileHeaderTabPanel from './tab-table'
import { IDropdownLink } from '../../../../interfaces/dropdown-link.interface'
import { LanguageISOCode } from '../../../../enums/language-iso-code.enum'
import { useTranslation } from 'react-i18next'

export interface MobileHeaderTabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
	toggleDrawer: () => void
}

export interface MobileHeaderTabProps {
	toggleDrawer: () => void
	language: LanguageISOCode
}

export const MobileHeaderTab = (
	props: MobileHeaderTabProps
) => {
	const { t } = useTranslation()
	const [value, setValue] = useState(0)
	const [parentCategories, setParentCategories] =
		useState<IDropdownLink[]>([])

	const TabPanel: React.FC<
		MobileHeaderTabPanelProps
	> = ({ children, value, index }) => (
		<div hidden={value !== index}>
			{value === index && (
				<Box sx={{ padding: '0px 16px' }}>
					{children}
				</Box>
			)}
		</div>
	)

	const handleChange = (
		event: React.SyntheticEvent,
		newValue: number
	) => {
		setValue(newValue)
	}

	const getCategories = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/menu?language=${props.language}`
		)
		const result = await response.json()
		setParentCategories(result)
	}

	useEffect(() => {
		getCategories()
	}, [props.language])

	return (
		<>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor='primary'
				textColor='primary'
				variant='fullWidth'
				sx={{
					boxShadow: '0px 0px 5px 0px',
					minHeight: 'fit-content',
					overflow: 'unset',
				}}
			>
				{parentCategories.map(
					(parentCategory, index) => {
						return (
							<Tab
								label={t(parentCategory.name)}
								key={index}
								sx={{
									fontWeight: 'bold',
									color: 'black',
									textTransform: 'none',
									fontSize: '1.1428rem',
									padding: '10px 16px',
									minHeight: 'auto', // Ajusta dinámicamente la altura según el contenido
									height: 'auto', // Ajusta la altura dinámicamente
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center', // Alinear el contenido horizontalmente al centro
									justifyContent: 'flex-start', // Alinear el contenido verticalmente en la parte superior
									whiteSpace: 'normal', // Permite que el texto se envuelva
									// wordBreak: 'break-word', // Permite que el texto se rompa para ajustarse al contenedor
									lineHeight: '1.2', // Asegura que la altura de línea no corte el texto
								}}
							/>
						)
					}
				)}
			</Tabs>
			{parentCategories.map(
				(parentCategory, index) => {
					return (
						<TabPanel
							value={value}
							index={index}
							key={index}
							toggleDrawer={props.toggleDrawer}
						>
							<MobileHeaderTabPanel
								toggleDrawer={props.toggleDrawer}
								language={props.language}
								categories={
									parentCategory.subMenus
								}
							/>
						</TabPanel>
					)
				}
			)}
		</>
	)
}
