import {
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react'
import { Box, Typography } from '@mui/material'
import { CartDivider } from './cart-divider'
import { CartSummary } from './cart-summary'
import { CartHelp } from './cart-help'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { OrderSummaryProduct } from './order-summary-product'
import { RequestManager } from '../utils'
import { useNavigate } from 'react-router-dom'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export interface IOrderRequestSummary {
	body: ReactElement<any, any>
	isMobile: boolean
	order?: IOrderRequest
	setOrder: (order: IOrderRequest) => void
}

export const OrderSummary = (
	props: IOrderRequestSummary
) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isSticky, setIsSticky] = useState(false)
	const [stickyHeight, setStickyHeight] = useState(0)

	const elementRef = useRef<HTMLDivElement>(null)

	const handleScroll = () => {
		const currentScrollHeight = window.scrollY
		const trigger = elementRef.current?.getBoundingClientRect().height || 0
		const scrollHeightTrigger =
			currentScrollHeight + window.innerHeight

		if (scrollHeightTrigger <= trigger) {
			setIsSticky(false)
			setStickyHeight(0)
		} else {
			setIsSticky(true)
			setStickyHeight(
				-trigger + window.innerHeight
			)
		}
	}

	const onLoad = () => window.scrollTo(0, 0)
	window.addEventListener('load', onLoad)

	window.addEventListener('scroll', handleScroll)

	useEffect(() => {
		window.removeEventListener('load', onLoad)
		window.removeEventListener(
			'scroll',
			handleScroll
		)

		RequestManager('POST', 'order', true).then(
			(response) => {
				if (
					[200, 201].includes(response.status)
				) {
					localStorage.setItem(
						'order',
						JSON.stringify(response.data)
					)
					props.setOrder(response.data)
				} else {
					navigate('/')
				}
			}
		)
	}, [])

	return (
		<Box
			sx={{
				display: 'flex',
				margin: props.isMobile ? '0' : '0 7.5rem',
				flexDirection: props.isMobile
					? 'column'
					: 'row',
			}}
			gap={'5%'}
		>
			<Box
				sx={{
					mb: '5%',
					minWidth: '50%',
				}}
			>
				{props.body}
			</Box>

			<Box sx={{}}>
				<Box
					ref={elementRef}
					className='product'
					margin={'10px 18px 0'}
					sx={{
						'@media only screen and (min-width: 769px)':
							{
								margin: '0 auto',
								maxWidth: '462px',
								position: isSticky
									? 'sticky'
									: 'relative',
								top: stickyHeight,
								padding: '0 20px',
								paddingTop: '43px',
								paddingBottom: '75px',
							},
					}}
				>
					<Box
						sx={{
							width: '100%',
						}}
					>
						<Typography
							fontFamily={'Bembo'}
							fontSize={'2rem'}
							mb={'10px'}
						>
							{t(Translations.ORDER_SUMMARY)}
						</Typography>
						<CartDivider />

						{props.order &&
							props.order.orderLines?.map(
								(line) => (
									<OrderSummaryProduct
										line={line}
									/>
								)
							)}

						<CartDivider />
						<CartSummary
							subTotal={{
								value: props.order?.subtotal || 0,
								valueWithDiscount:
									props.order
										?.subTotalWithDiscount,
								text: t(Translations.SUBTOTAL),
							}}
							total={{
								value: props.order?.total || 0,
								valueWithDiscount:
									props.order?.totalWithDiscount,
								text: t(Translations.TOTAL),
							}}
							delivery={{
								value: props.order?.shipping || 0,
								text: t(Translations.SHIPPING),
							}}
						/>

						<CartHelp />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
