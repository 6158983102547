import {
	useState,
	useEffect,
	useRef,
} from 'react'
import { Box } from '@mui/material'
import './lazy-image.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import FLogoSVG from '../assets/f-logo-svg'

export interface ILazyImage {
	imageSrc: string
	width?: string
	height?: string
	loadPlaceholderSize: string
	loadPlaceholderMargin?: string
	onClick?: () => void
}

export const LazyImageUnknownHeight = (
	props: ILazyImage
) => {
	const [loaded, setLoaded] =
		useState<boolean>(false)

	return (
		<Box
			sx={{
				width: props.width || '100%',
				// height: props.height || '100%',
				objectFit: 'cover',
				// border: loaded ? '' : '1px solid #CCCCCC',
				position: 'relative',
				boxSizing: 'border-box',
			}}
			onClick={props.onClick}
		>
			<LazyLoadImage
				key={props.imageSrc}
				src={props.imageSrc}
				style={{
					width: loaded ? '100%' : '0px',
					height: loaded ? '100%' : '0px',
				}}
				onLoad={() => {
					setLoaded(true)
				}}
			/>
			<Box
				style={{
					margin: `${
						props.loadPlaceholderMargin || '40%'
					} auto`,
					width: props.loadPlaceholderSize,
					display: loaded ? 'none' : 'block',
					border: loaded
						? ''
						: '1px solid #CCCCCC',
					borderRadius: '50%',
					padding: '1%',
				}}
			>
				<FLogoSVG loaded={loaded} />
			</Box>
		</Box>
	)
}
