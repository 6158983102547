import {
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../../interfaces/route.interface'
import { Cookie } from '../../../translations/translations.enum'

export default function CookiePolicy(props: IRoute) {
	const { t } = useTranslation()
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					sx={{
						fontSize: '1.75rem',
					}}
					gutterBottom
				>
					{t(Cookie.COOKIE_TITLE)}
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_1)}
					<a href='https://finaejerique.com/'>https://finaejerique.com/</a>.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					{t(Cookie.COOKIE_SUBTITLE_1)}
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_2)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					{t(Cookie.COOKIE_SUBTITLE_2)}
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_3)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_4)}
							secondary={t(Cookie.COOKIE_TEXT_5)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_6)}
							secondary={t(Cookie.COOKIE_TEXT_7)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_8)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_9)}
							secondary={t(Cookie.COOKIE_TEXT_10)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_11)}
							secondary={t(Cookie.COOKIE_TEXT_12)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_13)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_14)}
							secondary={t(Cookie.COOKIE_TEXT_15)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_16)}
							secondary={t(Cookie.COOKIE_TEXT_17)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_18)}
							secondary={t(Cookie.COOKIE_TEXT_19)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_20)}
							secondary={t(Cookie.COOKIE_TEXT_21)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_22)}
							secondary={t(Cookie.COOKIE_TEXT_23)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_24)}
							secondary={t(Cookie.COOKIE_TEXT_25)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					{t(Cookie.COOKIE_SUBTITLE_3)}
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_26)}
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_27)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Microsoft Internet Explorer o Microsoft Edge'
							secondary={
								<a href='http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies'>
									http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Mozilla Firefox'
							secondary={
								<a href='http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia'>
									http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Chrome'
							secondary={
								<a href='https://support.google.com/accounts/answer/61416?hl=es'>
									https://support.google.com/accounts/answer/61416?hl=es
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Safari'
							secondary={
								<a href='http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/'>
									http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Opera'
							secondary={
								<a href='http://help.opera.com/Linux/10.60/es-ES/cookies.html'>
									http://help.opera.com/Linux/10.60/es-ES/cookies.html
								</a>
							}
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_28)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Ghostery'
							secondary={
								<a href='http://www.ghostery.com/'>http://www.ghostery.com/</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Your online choices'
							secondary={
								<a href='http://www.youronlinechoices.com/es/'>
									http://www.youronlinechoices.com/es/
								</a>
							}
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					{t(Cookie.COOKIE_SUBTITLE_4)}
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					<a href='https://finaejerique.com/'>https://finaejerique.com/</a>{' '}
					{t(Cookie.COOKIE_TEXT_29)}
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					{t(Cookie.COOKIE_TEXT_30)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_31)}
							secondary={t(Cookie.COOKIE_TEXT_32)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Cookie.COOKIE_TEXT_33)}
							secondary={t(Cookie.COOKIE_TEXT_34)}
						/>
					</ListItem>
				</List>
			</Paper>
		</Container>
	)
}
