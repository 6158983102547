import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { footerPageSubTitle, footerPageText, footerPageTitle } from '.'
import { IRoute } from '../../../interfaces/route.interface'
import { Returns } from '../../../translations/translations.enum'

export const ReturnsPage = (props: IRoute) => {
	const { t } = useTranslation()

	return (
		<Box
			mx={'5%'}
			my={'5%'}
			mb={'15%'}
		>
			{footerPageTitle(
				t(Returns.RETURN_TITLE),
				props.isMobile
			)}
			{footerPageSubTitle(
				t(Returns.RETURN_SUBTITLE_1),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_1),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_2),
				props.isMobile
			)}
			{footerPageSubTitle(
				t(Returns.RETURN_SUBTITLE_2),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_3),
				props.isMobile
			)}
			{footerPageSubTitle(
				t(Returns.RETURN_SUBTITLE_3),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_4),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_5),
				props.isMobile
			)}
			{footerPageSubTitle(
				t(Returns.RETURN_SUBTITLE_4),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_6),
				props.isMobile
			)}
			{footerPageText(
				t(Returns.RETURN_TEXT_7),
				props.isMobile
			)}
		</Box>
	)
}
