import {
	Typography,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Faqs } from '../../../../translations/translations.enum'

export const SizeChartTable = () => {
	const { t } = useTranslation()
	
	return (
		<>
			<Typography
				variant='h5'
				sx={{
					mt: '2rem',
				}}
				gutterBottom
			>
				{t(Faqs.FAQS_TITLE_6)}
			</Typography>

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE1_1)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_2)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_3)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_4)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_5)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_6)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_7)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_8)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_9)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE1_10)}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE2_1)}</TableCell>
							<TableCell>55</TableCell>
							<TableCell>62</TableCell>
							<TableCell>68</TableCell>
							<TableCell>71</TableCell>
							<TableCell>74</TableCell>
							<TableCell>83</TableCell>
							<TableCell>92</TableCell>
							<TableCell>98</TableCell>
							<TableCell>104</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE3_1)}</TableCell>
							<TableCell>21</TableCell>
							<TableCell>22</TableCell>
							<TableCell>23</TableCell>
							<TableCell>23.5</TableCell>
							<TableCell>24</TableCell>
							<TableCell>25</TableCell>
							<TableCell>26</TableCell>
							<TableCell>27</TableCell>
							<TableCell>28</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE4_1)}</TableCell>
							<TableCell></TableCell>
							<TableCell>22</TableCell>
							<TableCell>22.6</TableCell>
							<TableCell>23.2</TableCell>
							<TableCell>23.8</TableCell>
							<TableCell>24.4</TableCell>
							<TableCell>25</TableCell>
							<TableCell>25.6</TableCell>
							<TableCell>26.2</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE5_1)}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>28</TableCell>
							<TableCell>29.25</TableCell>
							<TableCell>30.5</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE6_1)}</TableCell>
							<TableCell></TableCell>
							<TableCell>20.5</TableCell>
							<TableCell>22</TableCell>
							<TableCell>23.5</TableCell>
							<TableCell>24.5</TableCell>
							<TableCell>27.7</TableCell>
							<TableCell>31</TableCell>
							<TableCell>33</TableCell>
							<TableCell>35</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE7_1)}</TableCell>
							<TableCell></TableCell>
							<TableCell>32</TableCell>
							<TableCell>34</TableCell>
							<TableCell>36.4</TableCell>
							<TableCell>38.6</TableCell>
							<TableCell>45</TableCell>
							<TableCell>51</TableCell>
							<TableCell>55</TableCell>
							<TableCell>59</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Typography
				variant='h5'
				sx={{
					mt: '2rem',
				}}
				gutterBottom
			>
				{t(Faqs.FAQS_TITLE_7)}
			</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE8_1)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_2)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_3)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_4)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_5)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_6)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_7)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_8)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_9)}</TableCell>
							<TableCell>{t(Faqs.FAQS_TABLE8_10)}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE9_1)}</TableCell>
							<TableCell>92</TableCell>
							<TableCell>98</TableCell>
							<TableCell>104</TableCell>
							<TableCell>110</TableCell>
							<TableCell>116</TableCell>
							<TableCell>128</TableCell>
							<TableCell>140</TableCell>
							<TableCell>152</TableCell>
							<TableCell>158</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE10_1)}</TableCell>
							<TableCell>26</TableCell>
							<TableCell>27</TableCell>
							<TableCell>28</TableCell>
							<TableCell>29</TableCell>
							<TableCell>30</TableCell>
							<TableCell>32</TableCell>
							<TableCell>34.5</TableCell>
							<TableCell>36.5</TableCell>
							<TableCell>38.5</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE11_1)}</TableCell>
							<TableCell>25</TableCell>
							<TableCell>25.6</TableCell>
							<TableCell>26.2</TableCell>
							<TableCell>26.8</TableCell>
							<TableCell>27.4</TableCell>
							<TableCell>28.75</TableCell>
							<TableCell>30.25</TableCell>
							<TableCell>31</TableCell>
							<TableCell>31.75</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE12_1)}</TableCell>
							<TableCell>28</TableCell>
							<TableCell>29.25</TableCell>
							<TableCell>30.5</TableCell>
							<TableCell>31.75</TableCell>
							<TableCell>33</TableCell>
							<TableCell>35.5</TableCell>
							<TableCell>38</TableCell>
							<TableCell>40.75</TableCell>
							<TableCell>43.5</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE13_1)}</TableCell>
							<TableCell>31</TableCell>
							<TableCell>33</TableCell>
							<TableCell>35</TableCell>
							<TableCell>37.5</TableCell>
							<TableCell>39</TableCell>
							<TableCell>43</TableCell>
							<TableCell>48</TableCell>
							<TableCell>49</TableCell>
							<TableCell>89</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t(Faqs.FAQS_TABLE14_1)}</TableCell>
							<TableCell>1</TableCell>
							<TableCell>2</TableCell>
							<TableCell>3</TableCell>
							<TableCell>4</TableCell>
							<TableCell>5</TableCell>
							<TableCell>6</TableCell>
							<TableCell>7</TableCell>
							<TableCell>8</TableCell>
							<TableCell>9</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
