const routeMapping: Record<string, Record<string, string>> = {
	account: {
		en: 'account',
		es: 'cuenta'
	},
	accountAddresses: {
		en: 'account/addresses',
		es: 'cuenta/direcciones'
	},
	addressesNew: {
		en: 'account/addresses/new',
		es: 'cuenta/direcciones/nueva'
	},
	addressesEdit: {
		en: 'account/addresses/edit',
		es: 'cuenta/direcciones/editar'
	},
	orders: {
		en: 'account/orders',
		es: 'cuenta/pedidos'
	},
	accountReturns: {
		en: 'account/returns',
		es: 'cuenta/devoluciones'
	},
	myInfo: {
		en: 'account/my-info',
		es: 'cuenta/mis-datos'
	},
	contactUs: {
		en: 'contact-us',
		es: 'contactanos'
	},
	changePassword: {
		en: 'account/change-password',
		es: 'cuenta/cambiar-contrasena'
	},
	wishlist: {
		en: 'account/wishlist',
		es: 'cuenta/lista-de-deseos'
	},
	newAddress: {
		en: 'new-address',
		es: 'nueva-direccion'
	},
	addresses: {
		en: 'addresses',
		es: 'direcciones'
	},
	editAddress: {
		en: 'address/edit',
		es: 'direcciones/editar'
	},
	category: {
		en: 'category',
		es: 'categoria'
	},
	product: {
		en: 'product',
		es: 'producto'
	},
	search: {
		en: 'search',
		es: 'buscar'
	},
	shoppingBag: {
		en: 'shopping-bag',
		es: 'carrito'
	},
	shoppingBagInsite: {
		en: 'shopping-bag/insite',
		es: 'carrito/insite'
	},
	processingPayment: {
		en: 'processing-payment',
		es: 'procesando-pago'
	},
	passwordRecovery: {
		en: 'password-recovery',
		es: 'recuperar-contrasena'
	},
	organizations: {
		en: 'organizations',
		es: 'organizacion'
	},
	purchaseConditions: {
		en: 'purchase-conditions',
		es: 'condicion-de-venta'
	},
	privacyPolicy: {
		en: 'privacy-policy',
		es: 'politica-de-privacidad'
	},
	cookiePolicy: {
		en: 'cookie-policy',
		es: 'politica-cookies'
	},
	returns: {
		en: 'returns',
		es: 'devoluciones'
	},
	orderingAndShipping: {
		en: 'ordering-and-shipping',
		es: 'pedidos-y-envios'
	},
	storeLocator: {
		en: 'store-locator',
		es: 'localizador-de-tiendas'
	},
	termsAndConditions: {
		en: 'terms-and-conditions',
		es: 'terminos-y-condiciones'
	},
	feStory: {
		en: 'fina-ejerique-story',
		es: 'fina-ejerique-historia'
	},
	signup: {
		en: 'signup',
		es: 'registrate'
	}
}

export {
	routeMapping
}
