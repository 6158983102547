import {
	Box,
	Grid,
	Typography,
} from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import { useEffect, useState } from 'react'
import { RequestManager } from '../utils'
import countries from 'i18n-iso-countries'
import { useParams } from 'react-router-dom'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
countries.registerLocale(
	require('i18n-iso-countries/langs/en.json')
)
countries.registerLocale(
	require('i18n-iso-countries/langs/es.json')
)

export interface IMyInfoRequestProps
	extends IRoute {}

const MyInfo = (props: IMyInfoRequestProps) => {
	const { language } = useParams()
	const [user, setUser] = useState<any>(null)
	const { t } = useTranslation()

	useEffect(() => {
		RequestManager('GET', 'user/me').then(
			(response) => {
				if (response.status === 200) {
					setUser(response.data)
				}
			}
		)
	}, [])

	function formatDate(dateString: string) {
		const date = new Date(dateString)

		// Obtenemos el día, mes y año
		const day = date.getDate()
		const month = date.getMonth() + 1 // getMonth() devuelve un índice basado en cero, por lo que se suma 1
		const year = date.getFullYear().toString() // Extraemos los últimos dos dígitos del año

		// Aseguramos que el día y el mes tengan dos dígitos
		const parsedDay =
			day < 10 ? '0' + day : `${day}`
		const parsedMonth =
			month < 10 ? '0' + month : `${month}`

		// Devolvemos la fecha en el formato deseado
		return `${parsedDay}/${parsedMonth}/${year}`
	}

	const myInfoText = (text: string) => (
		<Typography
			sx={{
				border: '1px solid black',
				padding: '0.5rem 1rem',
			}}
		>
			{text}
		</Typography>
	)

	const country = countries.getName(
		user?.country,
		language || 'en'
	)

	const birthDate: string | undefined =
		user?.birthDate && formatDate(user?.birthDate)

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				marginBottom: '1.5rem',
				margin: '0 auto',
				justifyContent: 'center',
				padding: '0 2% 5%',
				textAlign: 'left',
				maxWidth: '500px',
				gap: '1.5rem',
			}}
		>
			<Typography
				fontSize={'1.5rem'}
				color='black'
				fontWeight={'bold'}
				textAlign={'center'}
				style={{ backgroundColor: 'white' }}
			>
				{t(Translations.PERSONAL_DATA)}
			</Typography>

			{user?.firstName &&
				myInfoText(user.firstName)}
			{user?.lastName &&
				myInfoText(user.lastName)}
			{user?.email && myInfoText(user.email)}
			{country && myInfoText(country)}

			<Typography
				fontSize={'1.5rem'}
				color='black'
				fontWeight={'bold'}
				textAlign={'left'}
				style={{ backgroundColor: 'white' }}
			>
				{t(Translations.BIRTH_DATE)}
			</Typography>
			{birthDate && myInfoText(birthDate)}
		</Box>
	)
}

export default MyInfo
