import { useState } from 'react'
import DropdownMenu, {
	IDropdownMenuProps,
} from '../../dropdown/dropdown-body'
import { Box } from '@mui/material'
import '../../../assets/scss/dropdown.scss'
import '../../../translations'
import { useTranslation } from 'react-i18next'
import '../../../assets/scss/header.scss'

export interface IDropDown
	extends IDropdownMenuProps {
	label: string
	isLeftSide: boolean
	language: string
	isCategory: boolean
}

function SubDropdown(props: IDropDown) {
	const { t } = useTranslation()

	const [isDropdownVisible, setDropdownVisible] =
		useState(false)

	const handleMouseEnter = () => {
		setDropdownVisible(true)
	}

	const handleMouseLeave = () => {
		setDropdownVisible(false)
	}

	const hasLinks =
		props.children && props.children.length > 0

	return (
		<Box
			sx={{
				display: 'inline-flex',
				borderColor: 'grey.500',
				cursor: 'pointer',
				// padding: props.isSticky
				// 	? '0 0 15px'
				// 	: '0 0 25px',
				// margin: props.isLeftSide
				// 	? '0 15px 0 0 !important'
				// 	: '0 0 0 15px !important',
				textDecoration: 'none',
				color: 'primary.main',
			}}
			className='subdropdown header-subdropwdown'
			onMouseEnter={
				props.children && handleMouseEnter
			}
			onMouseLeave={
				props.children && handleMouseLeave
			}
		>
			{props.label}
			{isDropdownVisible && hasLinks && (
				<Box className='subdropdown-menu'>
					<DropdownMenu
						isCategory={props.isCategory}
						children={props.children}
					/>
				</Box>
			)}
		</Box>
	)
}

export default SubDropdown
