import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import { ExpandMore } from '@mui/icons-material'
import visionImage from '../../../assets/images/store-locator/tiendas.jpg'
import {
	footerPageText,
	footerPageTitle,
} from '.'

export const StoreLocatorPage = (
	props: IRoute
) => {
	const storeData = [
		{
			name: 'Australia',
			cities: [
				{
					name: 'Online',
					stores: ['HOUSE OF EMMERSON'],
				},
				{
					name: 'Victoria',
					stores: [
						'THINGS ADORABLE AUSTRALIA',
						'LITTLE LUXURIES',
					],
				},
			],
		},
		{
			name: 'Belgium',
			cities: [
				{
					name: 'Sint-Niklaas',
					stores: ['RAFFIA KIDS'],
				},
			],
		},
		{
			name: 'Canada',
			cities: [
				{
					name: 'Vancouver',
					stores: ['LITTLE DANGINE'],
				},
			],
		},
		{
			name: 'China',
			cities: [
				{
					name: 'Jiaxing City',
					stores: ['DORA BABY'],
				},
				{
					name: 'Wenzhou City',
					stores: ['DORIS SHOP'],
				},
				{
					name: 'Fujian',
					stores: ['I LOVE BABY'],
				},
				{
					name: 'Wenling City',
					stores: ['LITTLE M'],
				},
				{
					name: 'Online',
					stores: [
						'MAYBEE',
						'SHINYEE KIDS',
						'XIHA BABY',
					],
				},
				{
					name: 'Jinhua',
					stores: ['MYBABY'],
				},
				{
					name: 'Xuzhou',
					stores: ['NINIAN'],
				},
				{
					name: 'Jingjiang City',
					stores: ['VOILA BEBE'],
				},
			],
		},
		{
			name: 'Ecuador',
			cities: [
				{
					name: 'Quito',
					stores: ['MY LITTLE ONE'],
				},
			],
		},
		{
			name: 'United Arab Emirates',
			cities: [
				{
					name: 'Abu Dhabi',
					stores: ['ADELA'],
				},
			],
		},
		{
			name: 'Spain',
			cities: [
				{
					name: 'Cangas de Narcea',
					stores: ['VIRUTAS DE CHOCOLATE'],
				},
				{
					name: 'A Coruña',
					stores: [
						'ALGODÓN DE AZÚCAR',
						'GAROTA E MENINO',
						'VITOR',
					],
				},
				{
					name: 'Cerdanyola del Vallès',
					stores: ['ALGODÓN Y REGALIZ'],
				},
				{
					name: 'Santa Uxia de Ribeira',
					stores: ['AMB MIMO'],
				},
				{
					name: 'Barcelona',
					stores: ['BABYSEL SUECA'],
				},
				{
					name: 'Sueca',
					stores: ['BALBINA'],
				},
				{
					name: 'Pravia',
					stores: ['BEATRIZ TU BEBE'],
				},
				{
					name: 'Utrera',
					stores: ['BOUTIQUE CAROLINA'],
				},
				{
					name: 'Pamplona',
					stores: ['BOUTIQUE PEPPA'],
				},
				{
					name: 'Valladolid',
					stores: ['CALZADOS PÉREZ'],
				},
				{
					name: 'O Grove',
					stores: ['CHIC MISS CHIC'],
				},
				{
					name: 'Online',
					stores: ['CHUCU CHU'],
				},
				{
					name: 'Madrid',
					stores: [
						'CINCO LOBITOS',
						'KOPIÑA',
						'LAS TRES OCAS',
						'MADONNA MATERNAL',
						'MINIS BABY&KIDS',
						'PACHUS',
						'PETIT CANESÚ',
					],
				},
				{
					name: 'Linares',
					stores: ['CLOTINA'],
				},
				{
					name: 'Corvera de Asturias',
					stores: ['COCO BABY'],
				},
				{
					name: 'Coria del Río',
					stores: ['COSA DE PALOMA'],
				},
				{
					name: 'Teruel',
					stores: ['COUSIÑAS'],
				},
				{
					name: 'Lalin',
					stores: ['CUCCOS'],
				},
				{
					name: 'Caceres',
					stores: ['DPEQUES'],
				},
				{
					name: 'Palencia',
					stores: ['EL ANGELITO LEO'],
				},
				{
					name: 'Torrente',
					stores: ['EL PILAR MODA INFANTIL'],
				},
				{
					name: 'Fuengirola',
					stores: ['EN LAS NUBES', 'MACHU'],
				},
				{
					name: 'León',
					stores: ['ENHEBRA BORDADOS'],
				},
				{
					name: 'Albacete',
					stores: ['GIOVANNA SOTTO'],
				},
				{
					name: 'Logroño',
					stores: ['GIOVANNA SOTTO BURGOS'],
				},
				{
					name: 'Burgos',
					stores: ['GRETEL'],
				},
				{
					name: 'Oviedo',
					stores: ['ARABELL', 'NANAS'],
				},
				{
					name: 'Bilbao',
					stores: [
						'IRULEA',
						'MARTINA',
						'POMERANIA KIDS',
					],
				},
				{
					name: 'San Sebastián',
					stores: ['KAOLA', 'LA OCA LOCA'],
				},
				{
					name: 'Eibar',
					stores: ['LA CANASTILLA'],
				},
				{
					name: 'Los Palacios',
					stores: ['LA CASITA DEL ÁRBOL'],
				},
				{
					name: 'Puzol',
					stores: ['LA CONDESA MODA'],
				},
				{
					name: 'Granada',
					stores: [
						'LA ESPERANZA',
						'MELLI',
						'PASITOS BABY MR',
					],
				},
				{
					name: 'Málaga',
					stores: [
						'LE PETIT BEBE',
						'ORGAZ',
						'PIRAPA',
					],
				},
				{
					name: 'Amería',
					stores: ['LITTLE LITTY'],
				},
				{
					name: 'Córdoba',
					stores: ['ORGAZ'],
				},
				{
					name: 'Gijón',
					stores: [
						'EL PILAR MODA INFANTIL',
						'PAOCA',
					],
				},
				{
					name: 'Toledo',
					stores: ['MARGARITA & CO'],
				},
				{
					name: 'Alicante',
					stores: ['MORANGO'],
				},
				{
					name: 'Chipiona',
					stores: ['MI LIMONERO KIDS'],
				},
				{
					name: 'Zaragoza',
					stores: ['MICOS', 'VALENTINA BB'],
				},
				{
					name: 'Benavente',
					stores: ['NACHO MODA INFANTIL'],
				},
				{
					name: 'Segovia',
					stores: ['NINES MODA INFANTIL'],
				},
				{
					name: 'Laredo',
					stores: ['NUVOLE'],
				},
				{
					name: 'Écija',
					stores: ['PEQUES'],
				},
				{
					name: 'Ontinyent',
					stores: ['MODA INFANTIL PETANO'],
				},
				{
					name: 'La Línea',
					stores: ['PETER AND MOLLY'],
				},
				{
					name: 'Peñiscola',
					stores: ['PICOLOS TIENDA'],
				},
				{
					name: 'Barakaldo',
					stores: ['PIERROT'],
				},
				{
					name: 'Alcalá de Henares',
					stores: ['POLOLOS'],
				},
				{
					name: 'Online',
					stores: [
						'RAFER',
						'MARIA ABIAN',
						'VA DE SUEÑOS',
					],
				},
				{
					name: 'Infiesto',
					stores: ['BOUTIQUE CAROLINA'],
				},
				{
					name: 'Santiago de Compostela',
					stores: ['TINI PETITS'],
				},
			],
		},
		{
			name: 'United States',
			cities: [
				{
					name: 'New York',
					stores: ['BONNE NUIT NYC'],
				},
				{
					name: 'Online',
					stores: [
						'BUBALU ONLINE',
						'EL RINCÓN DE MARÍA',
						'ELOISE & SKILES',
						'LAPINETTE BABY',
						'THE BLUE BERET LLC',
					],
				},
				{
					name: 'Michigan',
					stores: ['THE BLUE BERET LLC'],
				},
			],
		},
		{
			name: 'Guatemala',
			cities: [
				{
					name: 'Guatemala',
					stores: ['BABYS GUATEMALA'],
				},
			],
		},
		{
			name: 'Hong Kong',
			cities: [
				{
					name: 'Hong Kong',
					stores: [
						'BABYVERSE HK',
						'BIMBO CONCEPT',
					],
				},
				{
					name: 'Causeway Bay',
					stores: ['HAPPY MILK CO'],
				},
			],
		},
		{
			name: 'Ireland',
			cities: [
				{
					name: 'Dublin',
					stores: ['LES PETITS ROYALS'],
				},
				{
					name: 'Tyrone',
					stores: ['CICI BABYWEAR'],
				},
			],
		},
		{
			name: 'Israel',
			cities: [
				{
					name: 'Nazrat Illit',
					stores: ['ANITA'],
				},
			],
		},
		{
			name: 'Italy',
			cities: [
				{
					name: 'Milano',
					stores: [
						'IEVELI',
						'ADAMO TRE &C. SNC DI ADAMO GESUALDO &C',
					],
				},
				{
					name: 'Enna',
					stores: ['ANNALISA CONCILIO'],
				},
				{
					name: 'Pontecagnano',
					stores: ['AZALEA'],
				},
				{
					name: 'Palermo',
					stores: ['AZZURRA COLLEZIONE'],
				},
				{
					name: 'Sant´Arsenio',
					stores: [
						'BABY D’ELITE DI DI BARI FRANCESCO',
					],
				},
				{
					name: 'Andria',
					stores: [
						'BABY JUNIOR DI MARTONE CARMELA',
					],
				},
				{
					name: 'Sant’Antonio Abate',
					stores: ['BABY LAB SNC'],
				},
				{
					name: 'Prato',
					stores: [
						'BEBÈ FATTI A MANO DI CAPRA FRANCESCO',
					],
				},
				{
					name: 'Rimini',
					stores: [
						'BIMBOA DI RUSSO CIRILLO VALENTINA',
					],
				},
				{
					name: 'Agrigento',
					stores: [
						'BIRBE E MONELLI DI KOCI ELSONA',
					],
				},
				{
					name: 'Ancona',
					stores: [
						'BULLI E BELLI SNC DI SIMONE EMILA & C',
					],
				},
				{
					name: 'San Nicolò a Tordino',
					stores: ['BYBERON DI COSTAGLIOLA ANNA'],
				},
				{
					name: 'Aversa',
					stores: ['MARITE'],
				},
				{
					name: 'Angri',
					stores: [
						'ZUCCHERO FILATO DI GIUSEPPINA PLATANIA',
					],
				},
				{
					name: 'Siracusa',
					stores: ['LES LIBELLULES FERRARA'],
				},
				{
					name: 'Ferrara',
					stores: [
						'LA FIABA DI STEFANIA MARINIELLO',
					],
				},
				{
					name: 'Montoro',
					stores: ["CINZIA AIRO' ABBIGLIAMENTO"],
				},
				{
					name: 'Fregene',
					stores: ["MAMMA M'ADORA"],
				},
				{
					name: 'Massa',
					stores: ['NOI DA PICCOLI'],
				},
				{
					name: 'Frattamaggiore',
					stores: ['OFFICINA DI CRISTINA'],
				},
				{
					name: 'Manfredonia',
					stores: ['STORE DI GIOVANNA PACE'],
				},
				{
					name: 'Gravina di Puglie',
					stores: ['ELEONORA GRIMALDI'],
				},
				{
					name: 'Roma',
					stores: ['TAZIO BIMBO'],
				},
				{
					name: 'Mantova',
					stores: [
						"C'ERA UNA VOLTA DI BACILIERI ISOTTA",
					],
				},
				{
					name: 'Este',
					stores: [
						'L&P SNC DI GINETTI LARA ELIANTONIO PAOLA',
					],
				},
			],
		},
		{
			name: 'United Kingdom',
			cities: [
				{
					name: 'Online',
					stores: [
						'ALI OLI KIDS',
						"DODO' BABIES BOUTIQUE",
					],
				},
			],
		},
	]

	return (
		<Box>
			<Grid container>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						padding: '5%',
					}}
				>
					<img
						src={visionImage}
						alt='store locator'
						style={{
							width: '100%',
							height: 'auto',
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						alignContent: 'center',
					}}
				>
					{footerPageText(
						'Somos una marca internacional',
						props.isMobile,
						{
							textAlign: 'center',
							fontWeight: 'regular',
							fontSize: '1.5rem',
							alignContent: 'center',
						}
					)}
					{footerPageText(
						'+ 28 Países + 200 Tiendas',
						props.isMobile,
						{
							textAlign: 'center',
							fontWeight: 'bold',
							fontSize: '1.5rem',
							alignContent: 'center',
							color: '#968f8b',
						}
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						padding: '0 5% 5%',
					}}
				>
					{storeData.map(
						(country: any, index: number) => (
							<Accordion key={index}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
								>
									<Typography fontWeight='bold'>
										{country.name}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{country.cities.map(
										(city: any, idx: number) => (
											<Box
												key={idx}
												pl={2}
												pb={1}
											>
												<Typography
													variant='subtitle1'
													fontWeight='bold'
												>
													{city.name}
												</Typography>
												{city.stores.map(
													(
														store: string,
														id: number
													) => (
														<Typography
															key={id}
															pl={2}
														>
															{store}
														</Typography>
													)
												)}
											</Box>
										)
									)}
								</AccordionDetails>
							</Accordion>
						)
					)}
				</Grid>
			</Grid>
		</Box>
	)
}
