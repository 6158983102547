import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { IRoute } from '../../interfaces/route.interface'
import { IAddress } from './interface/address.interface'
import AddressPreview from './address-preview'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AddressTranslations } from '../../translations/translations.enum'
import Alert from '@mui/material/Alert';

export interface IAddressListProps
	extends IRoute {
	isAccountSettings?: boolean
	setEditingAddress?: (
		address: string | undefined
	) => void
	selectedAddress?: string
	setSelectedAddress?: (
		addressName: string
	) => void
}

const AddressList = (
	props: IAddressListProps
) => {
	const [addresses, setAddresses] = useState<
		IAddress[]
	>([])
	const { t } = useTranslation()

	const retrieveAddresses = async () => {
		const accessToken = Cookies.get('accessToken')

		if (!accessToken) {
			window.location.href = '/login'
		}
		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/user/address`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
			.then((response) => {
				if (response.ok) {
					return response.json()
				} else {
					throw new Error(
						'Error fetching addresses'
					)
				}
			})
			.then((data) => {
				setAddresses(data)
			})
	}

	useEffect(() => {
		retrieveAddresses()
	}, [])

	useEffect(() => {
		props.setSelectedAddress?.(addresses[0]?.name)
	}, [addresses])

	return (
		<Box>
			{addresses.length === 0 ? (
				<Alert severity="info" sx={{ my: 3, fontSize: '1rem' }}>{ t(AddressTranslations.ADDRESS_NO_ADDRESSES) }</Alert>
			) : (
				<>
					{addresses.map((address, key) => (
						<AddressPreview
							key={key}
							selectedAddress={
								props.selectedAddress
							}
							address={address}
							retrieveAddresses={
								retrieveAddresses
							}
							isAccountSettings={
								props.isAccountSettings
							}
							setSelectedAddress={
								props.setSelectedAddress
							}
							setEditingAddress={
								props.setEditingAddress
							}
						/>
					))}
				</>
			)}
		</Box>
	)
}

export default AddressList
