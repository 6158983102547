import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MultiSelect, Option } from './select'
import { useParams, useSearchParams } from 'react-router-dom'
import { FilterColor, Translations } from '../../translations/translations.enum'
import CheckboxWithLabelOnLeft from './mobile/filter-options.mobile'
import { colorPaletteRelation } from '../../enums/color-palette-relation.enum'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { getEntityEID } from '../utils/base64'

export interface ICategoryFilterProps {
	by: Translations.COLOR | Translations.SIZE
	setter: Dispatch<SetStateAction<Record<string, boolean>>>
	values: Record<string, boolean>
	isMobile: boolean
}

export interface IFilter {
	value: string | FilterColor
	isColor: boolean
}

export default function CategoryFilter(props: ICategoryFilterProps) {
	const { t } = useTranslation()
	const { id, language } = useParams()
	const [searchParams] = useSearchParams()
	const [data, setData] = useState([])
	const categoryId = id ? Number(id) : getEntityEID(searchParams)

	useEffect(() => {
		fetch(
			`${
				process.env.REACT_APP_CORE_API_URL
			}/category/${categoryId}/filters?language=es&by=${
				props.by === Translations.COLOR ? 'color' : 'size'
			}`
		)
			.then((res) => res.json())
			.then((data) => {
				setData(data)
			})
	}, [id, categoryId, language])

	return props.isMobile ? (
		<CheckboxWithLabelOnLeft
			data={data}
			setter={props.setter}
			values={props.values}
		/>
	) : (
		<MultiSelect
			by={props.by}
			setter={props.setter}
			values={props.values}
		>
			{data?.map((item: any, index) => (
				<Option
					key={index}
					value={item.value}
				>
					{item.isColor && colorPaletteRelation[item.value as FilterColor] ? (
						<>
							{/* <span
								style={{
									backgroundColor:
										colorPaletteRelation[
											item.value as FilterColor
										],
									width: '2rem',
									height: '2rem',
									display: 'inline-block',
									marginRight: '.5rem',
									border:
										'1px solid rgba(0,0,0,.2)',
								}}
							></span> */}
							<p>{t(item.value)}</p>
						</>
					) : (
						item.value
					)}
				</Option>
			))}
		</MultiSelect>
	)
}
