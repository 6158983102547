import { Box, Typography } from '@mui/material'

interface IDesktopHeaderBanner {
	headerBannerHeight: string
	isSticky: boolean
}

export const DesktopHeaderBanner = (
	props: IDesktopHeaderBanner
) => {
	return (
		<Box
			height={props.headerBannerHeight}
			bgcolor={'#222'}
			width={'100%'}
			textAlign={'center'}
			sx={{
				display: props.isSticky
					? 'none'
					: 'block',
			}}
		>
			<Typography
				color={'secondary.main'}
				fontWeight={'bold'}
				letterSpacing={'1.15pt'}
				fontSize='9px !important'
				lineHeight={'24px'}
				textTransform={'uppercase'}
			>
				FINA EJERIQUE'S NEW WEBSITE
			</Typography>
		</Box>
	)
}
