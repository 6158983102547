import { Box } from '@mui/material'
import {
	CartSubtotal,
	ICartSubtotal,
} from './cart-subtotal'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../translations/translations.enum'

export interface ICartSummary {
	subTotal: ICartSubtotal
	delivery: ICartSubtotal
	total: ICartSubtotal
	width?: string
}

export function CartSummary(props: ICartSummary) {
	const { t } = useTranslation()
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				width: props.width || '100%',
				margin: '20px 0',
			}}
		>
			<CartSubtotal
				text={t(Translations.SUBTOTAL)}
				value={props.subTotal.value}
				valueWithDiscount={
					props.subTotal.valueWithDiscount
				}
			/>
			<CartSubtotal
				text={t(Translations.SHIPPING)}
				value={props.delivery.value}
				valueWithDiscount={
					props.delivery.valueWithDiscount
				}
			/>
			<CartSubtotal
				text={t(Translations.TOTAL)}
				value={props.total.value}
				valueWithDiscount={
					props.total.valueWithDiscount
				}
			/>
		</Box>
	)
}
