import { Box } from '@mui/material'

export const WIPComponent = () => {
	return (
		<Box
			sx={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontSize: '2rem',
				color: '#FFF',
				bgcolor: '#020202',
			}}
		>
			WIP 🔧👷‍♂️
		</Box>
	)
}
