import { Box, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { IRoute } from '../../interfaces/route.interface'

export interface ISignInFooterProps
	extends IRoute {
	urlPath: string
	firstText: string
	secondText: string
	isOrderLogin?: boolean
	changeLoginForm?: () => void
}

export function SignInFooter(
	props: ISignInFooterProps
) {
	const { language } = useParams()

	const content = () => (
		<Box
			sx={{
				textDecoration: 'none',
				color: 'black',
				marginBottom: '1rem',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'left',
				gap: '0.5rem',
				cursor: 'pointer',
			}}
		>
			<Typography>{props.firstText}</Typography>
			<Typography
				sx={{
					textDecoration: 'underline',
				}}
			>
				{props.secondText}
			</Typography>
		</Box>
	)

	return props.isOrderLogin ? (
		<Box
			onClick={() => props.changeLoginForm?.()}
		>
			{content()}
		</Box>
	) : (
		<Link
			to={`/${language}/${props.urlPath}`}
			replace={true}
			style={{
				textDecoration: 'none',
				color: 'black',
				marginBottom: '1rem',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'left',
				gap: '0.5rem',
			}}
		>
			{content()}
		</Link>
	)
}
