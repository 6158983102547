import {
	Alert,
	Box,
	Button,
	Container,
	TextField,
	Typography
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../interfaces/route.interface'
import { PasswordRecover } from '../../translations/translations.enum'

export interface IPasswordRecoveryProps extends IRoute {}

export const PasswordRecovery = ({ isMobile }: IPasswordRecoveryProps) => {
	const { t } = useTranslation()
	const [email, setEmail] = useState('')
	const [errors, setErrors] = useState<any>({})
	const [successMessage, setSuccessMessage] = useState('')

	const validateEmail = (email: string) => {
		return /\S+@\S+\.\S+/.test(email)
	}

	const handleChange = (e: any) => {
		const { value } = e.target
		setEmail(value)

		// Limpiar errores al cambiar el campo
		if (errors.email) {
			setErrors({ ...errors, email: '' })
		}
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		let tempErrors: any = {}

		// Validación de email
		if (!validateEmail(email)) {
			tempErrors.email = t(
				PasswordRecover.PASSWORD_RECOVERY_ERROR_INVALID_EMAIL
			)
		}

		setErrors(tempErrors)

		if (Object.keys(tempErrors).length === 0) {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_CORE_API_URL}/auth/password-recovery`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ email }),
					}
				)

				if (response.ok) {
					// Manejar la respuesta exitosa, mostrar un mensaje de confirmación, etc.
					setSuccessMessage(t(PasswordRecover.PASSWORD_RECOVERY_SUCCESS))
				} else if (response.status === 401) {
					setErrors({
						...errors,
						email: t(PasswordRecover.PASSWORD_RECOVERY_ERROR_INVALID_EMAIL),
					})
				} else {
					setErrors({
						...errors,
						email: t(PasswordRecover.PASSWORD_RECOVERY_ERROR_RETRY),
					})
				}
			} catch (error) {
				console.error('Error submitting form:', error)
				// Manejo de error de red o del servidor
				setErrors({
					...errors,
					email: t(PasswordRecover.PASSWORD_RECOVERY_ERROR_RETRY),
				})
			}
		}
	}

	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{
				padding: '7.5% 2%',
				width: isMobile ? '100%' : '25%',
			}}
		>
			<div>
				<Typography
					component='h1'
					variant='h5'
					fontFamily='Bembo'
					sx={{
						textAlign: 'left',
						fontSize: '2rem',
					}}
				>
					{t(PasswordRecover.PASSWORD_RECOVERY)}
				</Typography>
				<Typography
					component='h3'
					variant='h6'
					fontFamily='Bembo'
					sx={{
						paddingTop: '1rem',
						textAlign: 'left',
						fontSize: '1.1rem',
					}}
				>
					{t(PasswordRecover.PASSWORD_RECOVERY_DESCRIPTION)}
				</Typography>
				<form
					noValidate
					onSubmit={handleSubmit}
				>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label={t(PasswordRecover.PASSWORD_RECOVERY_EMAIL)}
						name='email'
						autoComplete='email'
						autoFocus
						value={email}
						onChange={handleChange}
						error={!!errors.email}
						helperText={errors.email}
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						sx={{ mt: 3, mb: 2 }}
					>
						{t(PasswordRecover.PASSWORD_RECOVERY_SUBMIT)}
					</Button>
					{/* success message here */}
					{successMessage && (
						<Box
							sx={{
								marginTop: '10px',
							}}
						>
							<Alert severity='success'>{t(successMessage)}</Alert>
						</Box>
					)}
				</form>
			</div>
		</Container>
	)
}
