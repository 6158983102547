import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

export interface IMobileHeaderMenuButton {
	toggleDrawer: () => void
	drawerOpen: boolean
}

export const MobileHeaderMenuButton = (
	props: IMobileHeaderMenuButton
) => {
	return (
		<IconButton
			color='inherit'
			aria-label='open drawer'
			edge='start'
			onClick={props.toggleDrawer}
			sx={{ margin: '0 2.5px 0 0' }}
		>
			{props.drawerOpen ? (
				<Close
					sx={{
						fontSize: '30px',
						color: 'rgba(0, 0, 0, 0.8)',
					}}
				/>
			) : (
				<MenuIcon
					sx={{
						fontSize: '30px',
						color: 'rgba(0, 0, 0, 0.8)',
					}}
				/>
			)}
		</IconButton>
	)
}
