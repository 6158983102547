import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../../translations/translations.enum'

export interface FilterApplyButtonProps {
	setIsOpen: (isOpen: boolean) => void
}

export const FilterApplyButton = (
	props: FilterApplyButtonProps
) => {
	const isMobile = true
	const { t } = useTranslation()
	const border = isMobile
		? '2px solid black'
		: 'none'

	return (
		<Button
			sx={{
				borderRadius: '0px',
				backgroundColor: 'primary.main',
				color: 'secondary.main',
				boxShadow: 'none',
				width: '100%',
				border: border,
				padding: '.85rem 0',
			}}
			variant='contained'
			onClick={() => props.setIsOpen(false)}
		>
			<Typography
				sx={{
					fontSize: '0.785rem',
					fontWeight: 'bold',
					letterSpacing: '.14rem',
				}}
			>
				{t(Translations.APPLY)}
			</Typography>
		</Button>
	)
}
