import {
	Inventory,
	KeyboardReturn,
	LockReset,
	MenuBook,
	StarRate,
	SupervisedUserCircleOutlined,
} from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
	AccountRoutes,
	Translations,
} from '../../translations/translations.enum'
import { routeMapping } from '../utils/route-mapping'
import { AccountOverviewShortcut } from './account-overview-shortcut.component'

export interface IAccountOverviewProps {
	language: string | undefined
}

export function AccountOverview(
	props: IAccountOverviewProps
) {
	const { t } = useTranslation()

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<SupervisedUserCircleOutlined />}
					text={t(AccountRoutes.MY_INFO)}
					subText={t(AccountRoutes.MY_INFO)}
					path={`/${props.language}/${routeMapping.myInfo[props.language || 'es']}`}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<Inventory />}
					text={t(AccountRoutes.ORDER_HISTORY)}
					subText={t(Translations.ORDER_HISTORY_DESCRIPTION)}
					path={`/${props.language}/${routeMapping.orders[props.language || 'es']}`}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<StarRate />}
					text={t(AccountRoutes.WISHLIST)}
					subText={t(Translations.WISHLIST_DESCRIPTION)}
					path={`/${props.language}/${routeMapping.wishlist[props.language || 'es']}`}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<MenuBook />}
					text={t(AccountRoutes.ADDRESS_BOOK)}
					subText={t(Translations.ADDRESS_BOOK_DESCRIPTION)}
					path={`/${props.language}/${routeMapping.accountAddresses[props.language || 'es']}`}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<LockReset />}
					text={t(AccountRoutes.CHANGE_PASSWORD)}
					subText={t(Translations.PASSWORD_CHANGE_DESCRIPTION)}
					path={`/${props.language}/${routeMapping.changePassword[props.language || 'es']}`}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<KeyboardReturn />}
					text={t(AccountRoutes.RETURNS)}
					subText={t(Translations.RETURN_DESCRIPTION)}
					path={`/${props.language}/${routeMapping.accountReturns[props.language || 'es']}`}
				/>
			</Grid>
		</Grid>
	)
}
