import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	FreeMode,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import homeImageLeft from '../../../assets/images/home-image-left.webp'
import homeImageRight from '../../../assets/images/home-image-right.webp'
import productImage from '../../../assets/images/product-image.webp'
import './carousel.css'
import { CategoryProduct } from '../../category/category-product'
import { IRoute } from '../../../interfaces/route.interface'
import { IProduct } from '..'
import { useParams } from 'react-router-dom'

export interface IMobileProductBodyProps
	extends IRoute {
	relatedModels: number[]
}

export const MobileOtherProductsCarousel = (
	props: IMobileProductBodyProps
) => {
	const { language } = useParams()
	const [products, setProducts] = useState<
		IProduct[]
	>([])

	const fetchData = async (init?: boolean) => {
		const ids = props.relatedModels

		const queryIds = ids
			.map((id) => `ids=${id}`)
			.join('&')

		const response = await fetch(
			`${
				process.env.REACT_APP_CORE_API_URL
			}/model?pageNumber=1&pageSize=12&language=${language}${
				queryIds ? `&${queryIds}` : ''
			}`
		)

		if (response.status === 200) {
			const result = await response.json()
			setProducts(result)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const images = Array.from(Array(10).keys())

	const swiperBody = () => {
		const imgs = (products || []).map(
			(product) => (
				<SwiperSlide key={product.id}>
					<CategoryProduct
						productRecommendation={true}
						isMobile={true} // TODO: remove hardcoded value
						product={product}
					/>
				</SwiperSlide>
			)
		)

		return imgs || []
	}

	return props.isMobile ? (
		<Swiper
			centeredSlides={false}
			slidesPerView={1.5}
			modules={[
				Navigation,
				Pagination,
				Scrollbar,
				A11y,
				FreeMode,
			]}
			style={{
				display: 'flex',
				flexDirection: 'row',
			}}
			freeMode={{
				enabled: true,
				momentum: true,
				momentumRatio: 0.75,
			}}
			key='mobile-swiper'
		>
			{swiperBody()}
		</Swiper>
	) : (
		<Swiper
			centeredSlides={false}
			slidesPerView={5.5}
			modules={[Navigation]}
			style={{
				display: 'flex',
				flexDirection: 'row',
			}}
			navigation // Habilita los botones de navegación
			allowTouchMove={false}
			key='desktop-swiper'
		>
			{swiperBody()}
		</Swiper>
	)
}
