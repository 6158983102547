import { useRef, useEffect } from 'react'
import { IHomeMultimedia } from './home'

interface IHomeVideoProps {
	video: IHomeMultimedia
}

export const HomeVideo = ({
	video,
}: IHomeVideoProps) => {
	const videoRef =
		useRef<HTMLVideoElement | null>(null)

	useEffect(() => {
		const video =
			videoRef.current as HTMLVideoElement
		if (video) {
			video.play().catch((error) => {
				console.error(
					'Error attempting to play the video:',
					error
				)
			})
		}
	}, [])

	return (
		<video
			controls
			autoPlay
			muted
			loop
			preload='auto'
			playsInline
			width={'100%'}
			ref={videoRef}
		>
			<source
				src={`${process.env.REACT_APP_IMAGE_URL}/home/${video.file}`}
				type="video/mp4"
			/>
			Your browser does not support the video tag.
		</video>
	)
}
