import { Box, IconButton } from '@mui/material'
import { Fragment, useRef } from 'react'
import {
	TransformWrapper,
	TransformComponent,
	useControls,
	ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const Controls = ({
	zoomIn,
	zoomOut,
	resetTransform,
}: any) => (
	<Box
		sx={{
			display: 'flex',
			flexDirection: 'row',
		}}
	>
		<IconButton
			onClick={() => zoomIn()}
			color='primary'
		>
			<AddIcon />
		</IconButton>
		<IconButton
			onClick={() => zoomOut()}
			color='primary'
		>
			<RemoveIcon />
		</IconButton>
		{/* <button onClick={() => resetTransform()}> */}
	</Box>
)

function ProductZoom({ src }: { src: string }) {
	const transformComponentRef =
		useRef<ReactZoomPanPinchRef | null>(null)
	const zoomToImage = () => {
		if (transformComponentRef.current) {
			const { zoomToElement } =
				transformComponentRef.current
			zoomToElement('imgExample')
		}
	}
	return (
		<TransformWrapper
			initialScale={1}
			ref={transformComponentRef}
		>
			{(utils) => (
				<Fragment>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
						}}
					>
						<TransformComponent>
							<img
								src={src}
								alt='test'
								style={{
									maxWidth: '80vw',
									maxHeight: '80vh',
								}}
							/>
						</TransformComponent>
						<Controls {...utils} />
					</Box>
				</Fragment>
			)}
		</TransformWrapper>
	)
}

export default ProductZoom
