import React, {
	Dispatch,
	SetStateAction,
	useEffect,
} from 'react'
import Checkbox from '@mui/material/Checkbox'
import {
	FormGroup,
	FormControlLabel,
	Box,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { colorPaletteRelation } from '../../../enums/color-palette-relation.enum'
import { IFilter } from '../filter'
import { FilterColor } from '../../../translations/translations.enum'

export interface ICategoryFilterProps {
	data: any
	setter: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
	values: Record<string, boolean>
}

const CheckboxWithLabelOnLeft = ({
	data,
	setter,
	values,
}: ICategoryFilterProps) => {
	const { t } = useTranslation()
	const handleChange =
		(value: any) => (event: any) => {
			setter((prevStates) => ({
				...prevStates,
				[value]: event.target.checked,
			}))
		}

	return (
		<FormGroup
			sx={{
				m: 0,
				p: 0,
			}}
		>
			{data.map(
				(item: IFilter, index: number) => (
					<FormControlLabel
						sx={{
							m: 0,
							p: 0,
						}}
						key={item.value}
						label={''}
						control={
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row-reverse',
									alignItems: 'center',
									width: '100%',
									mx: '5%',
									height: '50px',
								}}
							>
								<Checkbox
									checked={
										values[item.value] || false
									}
									onChange={handleChange(
										item.value
									)}
									icon={<span />} // Usar un span vacío o personalizar según sea necesario
									checkedIcon={
										<Check
											style={{ color: 'black' }}
										/>
									}
									sx={{ marginLeft: 1 }} // Ajusta el espaciado según sea necesario
								/>
								<Box
									component='span'
									sx={{
										flexGrow: 1,
										textAlign: 'left',
									}}
								>
									{item.isColor &&
									colorPaletteRelation[
										item.value as FilterColor
									] ? (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												flexDirection: 'row',
											}}
										>
											<span
												style={{
													backgroundColor:
														colorPaletteRelation[
															item.value as FilterColor
														],
													width: '3rem',
													height: '1.25rem',
													display: 'inline-block',
													marginRight: '.5rem',
													border:
														'1px solid rgba(0,0,0,.2)',
												}}
											></span>
											<p>{t(item.value)}</p>
										</Box>
									) : (
										item.value
									)}
								</Box>
							</Box>
						}
					/>
				)
			)}
		</FormGroup>
	)
}

export default CheckboxWithLabelOnLeft
