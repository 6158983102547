import { FilterColor } from '../translations/translations.enum'

export const colorPaletteRelation: Record<
	FilterColor,
	string
> = {
	[FilterColor.RED_PALETTE]: '#e53935',
	[FilterColor.PINK_PALETTE]: '#ec407a',
	[FilterColor.PURPLE_PALETTE]: '#9c27b0',
	[FilterColor.BLUE_PALETTE]: '#2196f3',
	[FilterColor.GREEN_PALETTE]: '#4caf50',
	[FilterColor.YELLOW_PALETTE]: '#ffeb3b',
	[FilterColor.ORANGE_PALETTE]: '#ff9800',
	[FilterColor.BROWN_PALETTE]: '#795548',
	[FilterColor.GRAY_PALETTE]: '#9e9e9e',
	[FilterColor.WHITE_PALETTE]: '#ffffff',
	[FilterColor.BLACK_PALETTE]: '#000000',
}
