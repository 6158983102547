import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import { MobileHeaderToolbar } from './header/toolbar'
import { MobileHeaderDrawer } from './header/drawer'
import { Theme } from '@mui/material'
import { LanguageISOCode } from '../../../enums/language-iso-code.enum'

export interface IMobileHeader {
	appBarHeight: string
	language: LanguageISOCode
	setLanguage: (language: LanguageISOCode) => void
}

export const MobileHeader = (
	props: IMobileHeader
) => {
	const [drawerOpen, setDrawerOpen] =
		useState(false)

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen)
	}

	const appBarHeight = props.appBarHeight

	const appBarStyles = {
		zIndex: (theme: Theme) =>
			theme.zIndex.drawer + 1,
		backgroundColor: 'background.default',
		color: 'primary.main',
		boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)',
		height: appBarHeight,
		position: 'sticky',
		top: 0,
	}

	return (
		<>
			<AppBar sx={appBarStyles}>
				<MobileHeaderToolbar
					toggleDrawer={toggleDrawer}
					setDrawerOpen={setDrawerOpen}
					drawerOpen={drawerOpen}
					language={props.language}
				/>
			</AppBar>
			<MobileHeaderDrawer
				drawerOpen={drawerOpen}
				toggleDrawer={toggleDrawer}
				appBarHeight={appBarHeight}
				language={props.language}
				setLanguage={props.setLanguage}
			/>
		</>
	)
}
