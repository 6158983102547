import { Box, Typography } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import NotFoundImage from '../../assets/images/not-found.png'
import { useTranslation } from 'react-i18next'
import { ErrorTranslations } from '../../translations/translations.enum'

interface INoProductsFound extends IRoute {}

export function NoProductsFound(props: INoProductsFound) {
	const { t } = useTranslation()

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '50vh',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<img
				src={NotFoundImage}
				height={'50vh'}
			/>
			<br />
			<Typography variant={'h5'}>
				{t(ErrorTranslations.NO_PRODUCTS_FOUND)}
			</Typography>
		</Box>
	)
}
