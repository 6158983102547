import i18n, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from './es'
import en from './en'
import it from './it'
import pt from './pt'

// Recursos de traducción
const resources: Resource = {
	en,
	es,
	it,
	pt,
} as const

i18n.use(initReactI18next).init({
	resources,
	lng: 'es',
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
