import { Drawer } from '@mui/material'
import { MobileHeaderSectionCustomerService } from './sections/customer-service'
import { MobileHeaderDivider } from './divider'
import { MobileHeaderLocationLanguage } from './sections/location-language'
import { MobileHeaderSectionMyAccount } from './sections/my-account'
import { MobileHeaderTab } from './tab'
import { LanguageISOCode } from '../../../../enums/language-iso-code.enum'
import { MobileHeaderAccordion } from './accordion'

interface MobileHeaderDrawerProps {
	drawerOpen: boolean
	toggleDrawer: () => void
	appBarHeight: string
	language: LanguageISOCode
	setLanguage: (language: LanguageISOCode) => void
}

export const MobileHeaderDrawer = (
	props: MobileHeaderDrawerProps
) => {
	return (
		<Drawer
			anchor='left'
			open={props.drawerOpen}
			onClose={props.toggleDrawer}
			variant='temporary'
			sx={{
				width: '100vw',
				[`& .MuiDrawer-paper`]: {
					width: '100vw',
					boxSizing: 'border-box',
					paddingTop: props.appBarHeight,
				},
			}}
		>
			<MobileHeaderTab
				language={props.language}
				toggleDrawer={props.toggleDrawer}
			/>

			<MobileHeaderDivider />
			<MobileHeaderSectionMyAccount
				toggleDrawer={props.toggleDrawer}
			/>
			<MobileHeaderDivider />
			<MobileHeaderSectionCustomerService />
			<MobileHeaderDivider />
			<MobileHeaderLocationLanguage
				setLanguage={props.setLanguage}
				language={props.language}
			/>
		</Drawer>
	)
}
