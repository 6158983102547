import { Box, Typography } from '@mui/material'
import { IAddress } from './interface/address.interface'
import countries from 'i18n-iso-countries'
import { useParams } from 'react-router-dom'
countries.registerLocale(
	require('i18n-iso-countries/langs/en.json')
)
countries.registerLocale(
	require('i18n-iso-countries/langs/es.json')
)

export interface IAddressPreviewInfoProps {
	address: IAddress
}

export function AddressPreviewInfo(
	props: IAddressPreviewInfoProps
) {
	const { language } = useParams()

	return (
		<>
			<Box
				sx={{
					padding: '1rem',
				}}
			>
				<Typography fontWeight={'bold'}>
					{props.address.firstName}{' '}
					{props.address.lastName}
				</Typography>

				<Typography>
					{props.address.streetAndNumber}
				</Typography>
				<Typography>
					{props.address.postalCode}{' '}
					{props.address.city}
				</Typography>
				<Typography>
					{countries.getName(
						props.address.country,
						language || 'en'
					)}
				</Typography>
			</Box>
			<Box
				sx={{
					padding: '1rem',
				}}
			>
				<Typography>
					{props.address.email}
				</Typography>
				<Typography>
					{props.address.phoneNumber}
				</Typography>
			</Box>
		</>
	)
}
