import {
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import { useTranslation } from 'react-i18next'
import { Privacy } from '../../../translations/translations.enum'

export default function PrivacyPolicy(props: IRoute) {
	const { t } = useTranslation()
	
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					gutterBottom
				>
					{t(Privacy.PRIVACY_TITLE)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_1)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Privacy.PRIVACY_SUBTITLE_1)}
				</Typography>

				<Typography
					variant='h6'
					gutterBottom
				>
					{t(Privacy.PRIVACY_SUBTITLE_2)}
				</Typography>

				<List>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_2)}
							secondary={t(Privacy.PRIVACY_TEXT_3)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_4)}
							secondary={t(Privacy.PRIVACY_TEXT_5)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_6)}
							secondary={t(Privacy.PRIVACY_TEXT_7)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_8)}
							secondary={t(Privacy.PRIVACY_TEXT_9)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_10)}
							secondary={t(Privacy.PRIVACY_TEXT_11)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_12)}
							secondary={t(Privacy.PRIVACY_TEXT_13)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Privacy.PRIVACY_SUBTITLE_3)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_14)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
					dangerouslySetInnerHTML={{ __html: t(Privacy.PRIVACY_TEXT_15) }}
				/>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_16)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
					dangerouslySetInnerHTML={{ __html: t(Privacy.PRIVACY_TEXT_17) }}
				/>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_18)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_19)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_20)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_21)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_22)}
							secondary={t(Privacy.PRIVACY_TEXT_23)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_24)}
							secondary={t(Privacy.PRIVACY_TEXT_25)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_26)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_27)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_28)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_29)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_30)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_31)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_32)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_33)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_34)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_35)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_36)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_37)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_38)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_39)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_40)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_41)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary={t(Privacy.PRIVACY_TEXT_42)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Privacy.PRIVACY_TEXT_43)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Privacy.PRIVACY_TEXT_44)} />
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Privacy.PRIVACY_SUBTITLE_4)}
				</Typography>

				<Typography
					variant='h6'
					gutterBottom
				>
					{t(Privacy.PRIVACY_SUBTITLE_5)}
				</Typography>

				<List>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_45)}
							secondary={t(Privacy.PRIVACY_TEXT_46)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_47)}
							secondary={t(Privacy.PRIVACY_TEXT_48)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_49)}
							secondary={t(Privacy.PRIVACY_TEXT_50)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_51)}
							secondary={t(Privacy.PRIVACY_TEXT_52)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_53)}
							secondary={t(Privacy.PRIVACY_TEXT_54)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_55)}
							secondary={t(Privacy.PRIVACY_TEXT_56)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Privacy.PRIVACY_SUBTITLE_6)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_57)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
					dangerouslySetInnerHTML={{ __html: t(Privacy.PRIVACY_TEXT_58) }}
				/>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_59)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
					dangerouslySetInnerHTML={{ __html: t(Privacy.PRIVACY_TEXT_60) }}
				/>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_61)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_62)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_63)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_64)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_65)}
							secondary={t(Privacy.PRIVACY_TEXT_66)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_67)}
							secondary={t(Privacy.PRIVACY_TEXT_68)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Privacy.PRIVACY_TEXT_69)}
							secondary={t(Privacy.PRIVACY_TEXT_70)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_71)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_72)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_73)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_74)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_75)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_76)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_77)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_78)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_79)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_80)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_81)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_82)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_83)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_84)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_85)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Privacy.PRIVACY_TEXT_86)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary={t(Privacy.PRIVACY_TEXT_87)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Privacy.PRIVACY_TEXT_88)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Privacy.PRIVACY_TEXT_89)} />
					</ListItem>
				</List>
			</Paper>
		</Container>
	)
}
