import { Box } from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import {
	footerPageSubTitle,
	footerPageText,
	footerPageTitle,
} from '.'
import { useTranslation } from 'react-i18next'
import { Delivery, FooterPage } from '../../../translations/translations.enum'

export const OrderingAndShippingPage = (
	props: IRoute
) => {
	const { t } = useTranslation()
	return (
		<Box
			mx={'5%'}
			my={'5%'}
			mb={'15%'}
		>
			{footerPageTitle(
				t(FooterPage.FOOTER_PAGE_PAYMENT_TYPE_TITLE),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_DEVOLUTIONS),
				props.isMobile,
				{
					fontWeight: 'bold',
				}
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4),
				props.isMobile
			)}
			{footerPageSubTitle(
				t(FooterPage.FOOTER_PAGE_INVOICE_QUESTION),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_INVOICE_ANSWER),
				props.isMobile
			)}
			{footerPageTitle(
				t(FooterPage.FOOTER_PAGE_SECURITY_TITLE),
				props.isMobile
			)}
			{footerPageText(
				t(FooterPage.FOOTER_PAGE_SECURITY_DESCRIPTION),
				props.isMobile
			)}
			{footerPageTitle(
				t(FooterPage.FOOTER_PAGE_DELIVERY),
				props.isMobile
			)}
			{footerPageText(
				t(Delivery.DELIVERY_TITLE),
				props.isMobile,
				{
					fontWeight: 'bold',
				}
			)}
			{footerPageText(
				t(Delivery.DELIVERY_DESCRIPTION_1),
				props.isMobile
			)}
			{footerPageText(
				t(Delivery.DELIVERY_DESCRIPTION_2),
				props.isMobile
			)}
		</Box>
	)
}
