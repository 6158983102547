import { ShoppingBagOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { LanguageISOCode } from '../../../../../enums/language-iso-code.enum'
import { routeMapping } from '../../../../utils/route-mapping'

export interface IMobileHeaderShippingCartButton {
	language: LanguageISOCode
	setDrawerOpen: (isOpen: boolean) => void
}

export const MobileHeaderShippingCartButton = (
	props: IMobileHeaderShippingCartButton
) => {
	return (
		<Link
			to={`${props.language}/${routeMapping.shoppingBag[props.language]}`}
			style={{
				textDecoration: 'none',
				color: 'black',
			}}
			onClick={() => {
				props.setDrawerOpen(false)
			}}
		>
			<IconButton
				edge='start'
				color='inherit'
				aria-label='menu'
				sx={{
					margin: '0 0 0 2.5px',
				}}
			>
				<ShoppingBagOutlined
					sx={{
						fontSize: '25px',
					}}
				/>
			</IconButton>
		</Link>
	)
}
