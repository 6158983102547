import { Box, Typography } from '@mui/material'
import { IAddress } from './interface/address.interface'
import { useParams } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import { AddressTranslations } from '../../translations/translations.enum'
countries.registerLocale(
	require('i18n-iso-countries/langs/en.json')
)
countries.registerLocale(
	require('i18n-iso-countries/langs/es.json')
)
export interface IAddressPreviewInfoProps {
	address: IAddress
	isReturnOrder?: boolean
}

export function AddressOrderPreviewInfo(
	props: IAddressPreviewInfoProps
) {
	const { t } = useTranslation()
	const { language } = useParams()

	const addressLine = (
		fieldName: string,
		value: string
	) => {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: '0.5rem 0',
				}}
			>
				<Typography
					sx={{
						fontSize: '1rem',
						lineHeight: '1.5rem',
					}}
				>
					{value}
				</Typography>
			</Box>
		)
	}
	return (
		<Box
			sx={{
				marginY: 'rem',
			}}
		>
			<Typography
				sx={{
					fontWeight: 'bold',
					fontSize: '1.5rem',
					lineHeight: '2rem',
					marginTop: props.isReturnOrder
						? '0'
						: '2rem',
				}}
			>
				{t(
					props.isReturnOrder
						? AddressTranslations.RETURN_ADDRESS
						: AddressTranslations.DELIVERY_ADDRESS
				)}
			</Typography>
			<Box>
				{addressLine(
					t(AddressTranslations.ADDRESS_NAME),
					`${props.address.firstName} ${props.address.lastName}`
				)}
				{addressLine(
					t(
						AddressTranslations.ADDRESS_STREET_AND_NUMBER
					),
					`${props.address.streetAndNumber}`
				)}
				{addressLine(
					t(
						AddressTranslations.ADDRESS_ADDITIONAL_DATA
					),
					`${props.address.extraInfo}`
				)}
				{addressLine(
					t(
						AddressTranslations.ADDRESS_POSTAL_CODE
					),
					`${props.address.postalCode}`
				)}
				{addressLine(
					t(AddressTranslations.ADDRESS_CITY),
					`${props.address.city}`
				)}
				{addressLine(
					t(AddressTranslations.ADDRESS_PROVINCE),
					`${props.address.province}`
				)}
				{addressLine(
					t(AddressTranslations.ADDRESS_COUNTRY),
					countries.getName(
						props.address.country,
						language || 'en'
					) || props.address.country
				)}
				{addressLine(
					t(
						AddressTranslations.ADDRESS_PHONE_NUMBER
					),
					`${props.address.phoneNumber}`
				)}
			</Box>
		</Box>
	)
}
