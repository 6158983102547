import Box from '@mui/material/Box'

export interface IVariantPickerProps {
	color?: string
	size?: string
	modelId?: number
	active: boolean
	variantId?: number
	onSelect?: (variantId: number) => void
	stock: number
}

export const VariantPicker = (
	props: IVariantPickerProps
) => {
	const parentSize = '4rem'
	const parentMargin = '1rem'
	const activeHolderSize = '80%'
	const inactiveHolderSize = '65%'

	const hasStock = props.stock > 0

	const isActive = false

	return (
		<Box
			sx={{
				width: parentSize,
				height: parentSize,
				display: 'flex',
				justifyContent: 'center',
				margin: parentMargin,
				alignItems: 'center',
				position: 'relative', // Necesario para posicionar la barra diagonal absolutamente dentro de este componente
			}}
			onClick={() =>
				props.onSelect && hasStock
					? props.onSelect(props.variantId || 0)
					: void 0
			}
		>
			<Box
				sx={{
					borderRadius: '50%',
					padding: '5px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					transition: 'all 0.3s ease',
					position: 'relative',
					':hover':
						props.active || !hasStock
							? {}
							: {
									cursor: 'pointer',
									boxShadow:
										'0px 4px 8px rgba(0, 0, 0, 0.2)',
									transform: 'scale(1.05)',
							  },
					backgroundColor: hasStock
						? props.color || '#fdfdfd'
						: '#fdfdfd',
					border: props.active
						? '1px solid rgba(15, 15, 15, 0.5)'
						: '1px solid rgba(0, 0, 0, 0.1)',
					boxShadow: props.active
						? 'inset 0 0 4px rgba(0, 0, 0, 0.9), 0px 4px 8px rgba(0, 0, 0, 0.2)'
						: '0px 2px 4px rgba(0, 0, 0, 0.1)',
					height: props.active
						? activeHolderSize
						: inactiveHolderSize,
					width: props.active
						? activeHolderSize
						: inactiveHolderSize,
				}}
			>
				{props.size || ''}
				{!hasStock && (
					<Box
						sx={{
							width: '100%',
							height: '3px',
							backgroundColor: '#646464ad', // Rojo translúcido para la barra diagonal
							position: 'absolute',
							top: '50%',
							right: 0,
							transform: 'rotate(45deg)',
						}}
					/>
				)}
			</Box>
		</Box>
	)
}
