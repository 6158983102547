import { IOrderRequest } from '../../interfaces/order-request.interface'
import { CategoryItem, ICategory } from '../category/category'
import { IProduct } from '../product'

const handleMetaTagAddToCartClick = (product: IProduct) => {
	;(window as any).fbq('track', 'AddToCart')
}

const handleMetaTagLoadProduct = (product: IProduct) => {
	;(window as any).fbq('track', 'ViewContent')
}

const handleMetaTagBeginCheckout = (order: IOrderRequest) => {
	;(window as any).fbq('track', 'InitiateCheckout')
}

const handleMetaTagSelectItem = (product: CategoryItem) => {
	;(window as any).fbq.push({
		event: 'select_item',
		ecommerce: {
			items: [product.name],
		},
	})
}

const handleMetaTagViewItem = (product: IProduct) => {
	;(window as any).fbq('track', 'ViewContent')
}

const handleMetaTagViewItemList = (product: ICategory) => {
	;(window as any).fbq('track', 'ViewContent')
}

export {
	handleMetaTagAddToCartClick,
	handleMetaTagLoadProduct,
	handleMetaTagBeginCheckout,
	handleMetaTagSelectItem,
	handleMetaTagViewItem,
	handleMetaTagViewItemList,
}
