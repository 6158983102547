import * as React from 'react'
import {
	Select as BaseSelect,
	SelectProps,
	selectClasses,
	SelectRootSlotProps,
} from '@mui/base/Select'
import {
	Option as BaseOption,
	optionClasses,
} from '@mui/base/Option'
import { Popper as BasePopper } from '@mui/base/Popper'
import { styled } from '@mui/system'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export interface IMultiSelectProps {
	by: Translations.COLOR | Translations.SIZE
	setter: React.Dispatch<
		React.SetStateAction<Record<string, boolean>>
	>
	values: Record<string, boolean>
}

const MultiSelect = React.forwardRef(
	function CustomMultiSelect(
		props: SelectProps<string, true> &
			IMultiSelectProps,
		ref: React.ForwardedRef<any>
	) {
		const { t } = useTranslation()
		const slots: SelectProps<
			string,
			true
		>['slots'] = {
			root: Button,
			listbox: Listbox,
			// popper: Popper,
			...props.slots,
		}

		const handleChange = (
			event: any,
			value: string[]
		) => {
			const values: Record<string, boolean> =
				value.reduce(
					(
						acc: Record<string, boolean>,
						curr: string
					) => {
						acc[curr] = true
						return acc
					},
					{}
				)
			props.setter(values)
		}

		return (
			<BaseSelect
				{...props}
				multiple
				ref={ref}
				slots={slots}
				value={Object.keys(props.values)}
				renderValue={(selected) =>
					`${t(props.by)}`
				}
				onChange={handleChange}
			/>
		)
	}
)

const Button = React.forwardRef(function Button<
	TValue extends {},
	Multiple extends boolean
>(
	props: SelectRootSlotProps<TValue, Multiple>,
	ref: React.ForwardedRef<HTMLButtonElement>
) {
	const { ownerState, ...other } = props

	return (
		<StyledButton
			type='button'
			{...other}
			ref={ref}
		>
			{other.children}
			{false ? (
				<ArrowDropUpIcon />
			) : (
				<ArrowDropDownIcon />
			)}
		</StyledButton>
	)
})

const blue = {
	100: '#DAECFF',
	200: '#99CCF3',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	900: '#003A75',
}

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
}

const StyledButton = styled('button', {
	shouldForwardProp: () => true,
})(
	({ theme }) => `
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-family: 'Avenir', sans-serif;
	font-size: 0.875rem;
	box-sizing: border-box;
	padding: 8px 12px;
	text-align: left;
	line-height: 1.5;
	background: ${
		theme.palette.mode === 'dark'
			? grey[900]
			: '#fff'
	};
	border: 0px solid;
	color: ${
		theme.palette.mode === 'dark'
			? grey[300]
			: grey[900]
	};
	position: relative;

	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 120ms;

	&.${selectClasses.focusVisible} {
		outline: 0;
		border-color: ${blue[400]};
		box-shadow: 0 0 0 3px ${
			theme.palette.mode === 'dark'
				? blue[600]
				: blue[200]
		};
	}

    &.${selectClasses.expanded} {
        &:after {
        content: ''; /* Contenido vacío, necesario para generar un pseudo-elemento */
        position: absolute; /* Posicionamiento absoluto respecto a la caja */
        bottom: 0; /* Coloca la banda en la parte inferior de la caja */
        left: 15%; /* Alinea la banda a la izquierda de la caja */
        width: 60%; /* Ancho de la banda igual al ancho de la caja */
        height: 1.5px; /* Altura de la banda */
        background-color: black; /* Color de la banda */
      }
    }
    
  `
)

const Listbox = styled('ul')(
	({ theme }) => `
    font-family: 'Avenir', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px 15px;
    margin: 0;
    border-radius: 0px;
    overflow: auto;
    outline: 0px;
    max-height: 250px;

    background: #fff;

    /* Estilos personalizados para el scrollbar */
    &::-webkit-scrollbar {
        width: 4px; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* Color de fondo del track del scrollbar */
    }

    &::-webkit-scrollbar-thumb {
        background-color: black; /* Color del thumb (la barra deslizante) */
        border-radius: 1px; /* Redondeo de los bordes del thumb */
    }

    /* Estilos para otros navegadores */
    scrollbar-width: thin;
    scrollbar-color: black transparent; /* Color del thumb y del track */
    `
)

const Option = styled(BaseOption)(
	({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0px;
  cursor: default;
  position: relative;
  display: flex;
  alignItems: center;
  flexDirection: row;
  line-height: 1rem;
  padding: 0.5rem 3rem 0.5rem 0.5rem;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
     &:after {
		display: flex;
  		line-height: 1rem;
  		alignItems: center;
        content: ' ✓'; // Añade el símbolo al hacer hover
        font-weight: bold;
        font-size: 1.1rem;
        position: absolute;
        color: rgb(0, 0, 0);
        right: -1px; // Puedes ajustar la posición como prefieras
		top: 50%; /* Empuja el elemento al centro verticalmente */
    	transform: translateY(-50%); /* Asegura que el centro del elemento esté alineado verticalmente, no su borde superior */
      }
  }

  @supports selector(:has(*)) {
    &.${optionClasses.selected} {
      & + .${optionClasses.selected} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:has(+ .${optionClasses.selected}) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

   &:hover {
      &:after {
        content: '✓'; // Añade el símbolo al hacer hover
        font-size: 1.1rem;
        position: absolute;
        color: #000;
        right: -1px; // Puedes ajustar la posición como prefieras
		top: 50%; /* Empuja el elemento al centro verticalmente */
    	transform: translateY(-50%); /* Asegura que el centro del elemento esté alineado verticalmente, no su borde superior */
      }
    }
  `
)

const Popper = styled(BasePopper)`
	z-index: 1;
`

export { MultiSelect, Option }
