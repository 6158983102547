import { useState } from 'react'
import { IRoute } from '../../interfaces/route.interface'
import { ICartUpdate } from '../../utils/cart.util'
import { Login } from '../login/login'
import SignUp from '../signup/signup'

export interface ICartLoginProps extends IRoute {
	nextStep?: () => void
	isMobile: boolean
	setIsLogged: (isLogged: boolean) => void
	setCart: ICartUpdate
	isNewCart?: Boolean
}

export function CartLogin(
	props: ICartLoginProps
) {
	const [isLoginForm, setIsLoginForm] =
		useState(true)
	const changeLoginForm = () => {
		setIsLoginForm(!isLoginForm)
	}

	return isLoginForm ? (
		<Login
			isMobile={props.isMobile}
			nextStep={props.nextStep}
			setIsLogged={props.setIsLogged}
			setCart={props.setCart}
			isNewCart={true}
			changeLoginForm={changeLoginForm}
			isOrderLogin={true}
		/>
	) : (
		<SignUp
			isMobile={props.isMobile}
			setIsLogged={props.setIsLogged}
			setCart={props.setCart}
			nextStep={props.nextStep}
			changeLoginForm={changeLoginForm}
			isOrderLogin={true}
		/>
	)
}
