import { ResourceLanguage } from 'i18next'
import {
	AccountRoutes,
	AddressErrors,
	AddressTranslations,
	Cart,
	Delivery,
	DeliveryStatusTranslations,
	ErrorTranslations,
	FilterColor,
	FooterPage,
	MenuCategoryTranslations,
	OrderStatusTranslation,
	Password,
	PasswordRecover,
	Payment,
	Product,
	ReturnOrder,
	SignIn,
	SignUp,
	Translations,
	Returns,
	Faqs,
	Partners,
	Privacy,
	Cookie,
	Terms,
} from './translations.enum'

const translations: Record<
	| Translations
	| MenuCategoryTranslations
	| AccountRoutes
	| FilterColor
	| AddressTranslations
	| AddressErrors
	| Password
	| Cart
	| Payment
	| SignUp
	| SignIn
	| Product
	| FooterPage
	| Delivery
	| ReturnOrder
	| DeliveryStatusTranslations
	| PasswordRecover
	| OrderStatusTranslation
	| ErrorTranslations
	| Returns
	| Faqs
	| Partners
	| Privacy
	| Cookie
	| Terms,
	string
> = {
	SEARCH: 'Search',
	COOKIE_POLICY: 'Cookie Policy',
	TERMS_CONDITIONS: 'Terms & Conditions',
	PRIVACY_POLICY: 'Privacy Policy',
	FAQS: 'FAQs',
	SPANISH: 'Spanish',
	ENGLISH: 'English',
	ITALIAN: 'Italian',
	PORTUGUESE: 'Portuguese',
	SHOPPING_BAG: 'Shopping Bag',
	WISHLIST: 'Wishlist',
	MYFINAEJERIQUE: 'My account',
	CUSTOMER_SERVICE: 'Customer Service',
	NEED_HELP_ORDER: 'Do you need help with your order?',
	COLOR: 'Color',
	SIZE: 'Size',
	SIZE_CHART: 'Size chart',
	REGION: 'Region',
	LANGUAGE: 'Language',
	SHIPPING_TO: 'Shipping to',
	SHIPPING_LOCATION: 'United Kingdom',
	BABY: 'Baby',
	NEW_BORN: 'New Born',
	CHILDREN: 'Children',
	SIGN_IN: 'Sign In',
	SIGN_OUT: 'Sign Out',
	SIGN_UP: 'Sign Up',
	LOADING: 'Loading',
	DESCRIPTION: 'Description',
	DETAILS: 'Details',
	DELIVERY: 'Delivery',
	CONTACT_US: 'Contact Us',
	SIMILAR_PRODUCTS: 'Similar Products',
	YOU_MAY_ALSO_LIKE: 'You may also like',
	ADD_TO_CART: 'Add to Cart',
	CLEAR_FILTERS: 'Clear Filters',
	FILTER: 'Filter',
	APPLY: 'Apply',
	ACCOUNT_OVERVIEW: 'Account overview',
	ORDER_HISTORY: 'Order history',
	ADDRESS_BOOK: 'Address book',
	ADDRESS_EDIT: 'Edit address',
	ADDRESS_NEW: 'New address',
	CHANGE_PASSWORD: 'Change password',
	CONTINUE_TO_PAYMENT: 'Continue to payment',
	ORDER_VIEW: 'View order',
	RED_PALETTE: 'Reds',
	PINK_PALETTE: 'Pinks',
	PURPLE_PALETTE: 'Purple',
	BLUE_PALETTE: 'Blues',
	GREEN_PALETTE: 'Greens',
	YELLOW_PALETTE: 'Yellows',
	ORANGE_PALETTE: 'Oranges',
	BROWN_PALETTE: 'Browns',
	GRAY_PALETTE: 'Grays',
	WHITE_PALETTE: 'Whites',
	BLACK_PALETTE: 'Blacks',
	MY_INFO: 'My account info',
	ORDER_RETURN: 'Order return',
	RETURNS: 'Returns',
	RETURN_VIEW: 'Return',
	ORDER_HISTORY_DESCRIPTION: 'Check your order history',
	ADDRESS_BOOK_DESCRIPTION: 'Manage your addresses',
	PASSWORD_CHANGE_DESCRIPTION: 'Change your password',
	RETURN_DESCRIPTION: 'Manage your returns',
	WISHLIST_DESCRIPTION: "Wish list of Fina Ejerique's products",
	ACCOUNT_OVERVIEW_DESCRIPTION: 'Hi',
	ADDRESS: 'Address',
	ADDRESS_NO_ADDRESSES: 'Create your first address',
	ADDRESS_FIRST_NAME: 'First name',
	ADDRESS_LAST_NAME: 'Last name',
	ADDRESS_COUNTRY: 'Country',
	ADDRESS_CITY: 'City',
	ADDRESS_PROVINCE: 'Province',
	ADDRESS_POSTAL_CODE: 'Postal code',
	ADDRESS_STREET_AND_NUMBER: 'Street and number',
	ADDRESS_ADDITIONAL_DATA: 'Floor/Door or other (Optional)',
	ADDRESS_PHONE_NUMBER: 'Phone number',
	ADDRESSES: 'Addresses',
	ADD_ADDRESS: 'Add address',
	EDIT_ADDRESS: 'Edit address',
	DELIVERY_ADDRESS: 'Delivery address',
	ADDRESS_NAME: 'Name of the address (Ex. House, Work)',
	SAVE_ADDRESS: 'Save address',
	EDIT: 'Edit',
	DELETE: 'Delete',
	CONTINUE_SHOPPING: 'Continue shopping',
	CHECK_OUT: 'Check out',
	SUBTOTAL: 'Subtotal',
	TOTAL: 'Total',
	SHIPPING: 'Shipping',
	SELECT_ADDRESS: 'Please, select one address',
	UNITS: 'Units',
	ORDER_SUMMARY: 'Order summary',
	ALL_FIELDS_REQUIRED: 'All fields are required',
	MESSAGE_SENT_SUCCESSFULLY: 'Message sent successfully',
	AN_ERROR_OCCURRED: 'An error occurred',
	SEND_MESSAGE: 'Send message',
	MESSAGE: 'Message',
	CALL_US: 'Call us',
	EMAIL_US: 'Email us',
	CONTACT_US_DESCRIPTION: `If you need help placing an order
					order or have any questions about our
					our shop, our team is available to answer your
					Monday to Friday, 9 am to 3 pm.
					3pm.`,
	NEWS_LETTER: 'Newsletter',
	NEWS_LETTER_LABEL: 'Enter your email',
	NEWS_LETTER_SIGNUP: 'Sign Up For Updates',
	NEWS_LETTER_BUTTON: 'Sign Up',
	ORDERING_AND_SHIPPING: 'Ordering and shipping',
	THE_FE_STORY: 'The Fina Ejerique Story',
	CAREERS: 'Careers',
	CUSTOMER_CARE: 'Customer Care',
	ABOUT_FE: 'About Fina Ejerique',
	FOLLOW_FE: 'Follow Fina Ejerique',
	VIEW_DETAILS: 'View details',
	ITEMS: 'Items',
	ORDER_NUMBER: 'Order number',
	REQUEST_RETURN: 'Request return',
	ERROR_ADDRESS_NAME: 'Please select a name for the address',
	ERROR_ADDRESS_FIRST_NAME: 'Please add you name',
	ERROR_ADDRESS_LAST_NAME: 'Please add you surname',
	ERROR_ADDRESS_COUNTRY: 'Please select your country',
	ERROR_ADDRESS_CITY: 'Please select your city',
	ERROR_ADDRESS_PROVINCE: 'Please select your province',
	ERROR_ADDRESS_POSTAL_CODE: 'Please select your postal code',
	ERROR_ADDRESS_STREET_AND_NUMBER: 'Please select your address',
	ERROR_ADDRESS_PHONE_NUMBER: 'Please select a valid phone number',
	NO_ADDRESSES: 'No addresses',
	BACK: 'Back',
	PERSONAL_DATA: 'Personal data',
	BIRTH_DATE: 'Birth date',
	SELECT_SIZE: 'Please, select a size',
	RETURN_ADDRESS: 'Return address',
	DRAFT: 'Draft',
	PENDING_PAYMENT: 'Pending payment',
	PAID: 'Paid',
	PAYMENT_FAILED: 'Payment failed',
	PENDING_REVIEW: 'Pending review',
	REVIEWED: 'Reviewed',
	SENT_TO_SIMSS: 'Sent to SIMSS',
	ERROR_SENDING_TO_SIMSS: 'Error sending to SIMSS',
	CARRIER_NOTIFIED: 'Carrier notified',
	ERROR_NOTIFYING_CARRIER: 'Error notifying carrier',
	SHIPPED: 'Shipped',
	DELIVERED: 'Delivered',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
	PASSWORD_CHANGE: 'Change password',
	PASSWORD_CURRENT: 'Current Password',
	PASSWORD_NEW: 'New Password',
	PASSWORD_CONFIRM: 'Confirm New Password',
	PASSWORD_DETAILS:
		'Password must contain: <br />- At least 8 characters long <br />- At least one uppercase letter <br />- At least one lowercase letter <br /> - At least one number <br />- At least one special character (@, #, $, %, etc.)',
	PASSWORD_ERROR_OLD: 'Please add your old password',
	PASSWORD_ERROR_NEW: 'Please add your new password',
	PASSWORD_ERROR_CONFIRM: 'Please confirm your new password',
	PASSWORD_ERROR_NOT_MATCH: 'Passwords do not match',
	PASSWORD_ERROR_INCOMPLETE:
		'Password must contain at least 8 characters, one number, one letter and one special character.',
	PASSWORD_ERROR_INCORRECT: 'Current password is not correct. Please check it.',
	PASSWORD_ERROR_ERROR: 'An error occurred. Please try again later.',
	CART_BAG: 'Bag',
	CART_EMAIL: 'Email',
	CART_DELIVERY: 'Delivery',
	CART_PAYMENT: 'Payment',
	CHECK_EMAIL_COMMUNICATIONS:
		'I would like to receive exclusive email communications from Fina Ejerique.',
	CHECK_TERMS_AND_CONDITIONS:
		'By placing your order you agree to Fina Ejerique’s terms of website use and privacy policy.',
	PAYMENT_CONFIRM_AND_PAY: 'Confirm and pay',
	PAYMENT_ERROR_TERMS_CONDITIONS: 'You must accept the terms and conditions',
	PAYMENT_ERROR_PAYMENT_METHOD: 'You must select a payment method',
	PAYMENT_ERROR_CREATING_ORDER_ID: 'Error creating order',
	PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND: 'Payment method not found',
	PAYMENT_ERROR_CREATING_ORDER_RETRY:
		'Error creating order. Please, try again later.',
	PAYMENT_CREDIT_CARD: 'Debit/Credit Card',
	PAYMENT_CREDIT_CARD_DESCRIPTION: 'User your card to pay',
	PAYMENT_BIZUM: 'Bizum',
	PAYMENT_BIZUM_DESCRIPTION: 'User Bizum to pay',
	SIGNUP_CREATE_ACCOUNT: 'Create Account',
	SIGNUP_EMAIL: 'Email Address',
	SIGNUP_FIRST_NAME: 'First Name',
	SIGNUP_LAST_NAME: 'Last Name',
	SIGNUP_BIRTHDAY: 'Date of birth (optional)',
	SIGNUP_COUNTRY: 'Choose country',
	SIGNUP_PASSWORD: 'Password',
	SIGNUP_CONFIRM_PASSWORD: 'Confirm Password',
	SIGNUP_ALREADY_EXISTS: 'Already have an account?',
	SIGNUP_SIGNIN: 'Sign In',
	SIGNUP_ERROR_INVALID_EMAIL: 'Invalid email address',
	SIGNUP_ERROR_INVALID_PASSWORD: 'Please provide a valid password',
	SIGNUP_ERROR_TERMS_CONDITIONS: 'You must accept the terms and conditions',
	SIGNUP_ERROR_PASSWORD_NOT_MATCH: 'Passwords do not match',
	SIGNUP_ERROR_COUNTRY: 'Please select a country',
	SIGNUP_ERROR_FIRST_NAME: 'Please provide your first name',
	SIGNUP_ERROR_LAST_NAME: 'Please provide your last name',
	SIGNUP_ERROR_RETRY: 'An error occurred. Please try again later',
	SIGNUP_ERROR_CHECK_FIELDS: 'There are errors in the form. Please check them',
	SIGNIN_CREATE_ACCOUNT: 'Sign in',
	SIGNIN_EMAIL: 'Email Address',
	SIGNIN_PASSWORD: 'Password',
	SIGNIN_PASSWORD_FORGOT: 'Forgotten your password?',
	SIGNIN_ACCOUNT_NOT_EXISTS: 'Don’t have an account?',
	SIGNIN_SIGNUP: 'Sign up',
	SIGNIN_ERROR_INVALID_EMAIL: 'Please enter a valid email address',
	SIGNUP_ERROR_DUPLICATED_EMAIL: 'Email already exists',
	SIGNIN_ERROR_SIGNIN: 'Login failed. Please check your credentials',
	SIGNIN_ERROR_RETRY: 'An error occurred. Please try again later',
	PRODUCT_CONTACT_US_DESCRIPTION:
		'If you need help placing an order or have any questions about our store, our team is available to answer your questions Monday to Friday, 9am to 3pm.',
	PRODUCT_CONTACT_US_BUTTON: 'Contact Us',
	FOOTER_PAGE_PAYMENT_TYPE_TITLE: 'Payment Methods',
	FOOTER_PAGE_DEVOLUTIONS: 'Returns are FREE.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION:
		'You can use the following payment methods: Visa, Visa Electron, Mastercard, Maestro, JCB and UPAY.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1: '• The card has not expired.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2:
		'• That your card does not exceed its validity date.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3:
		'• The card limit may have been reached. Check with your bank that the card has not exceeded the amount allowed to make purchases.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4:
		'• Check that you have correctly filled out all the necessary fields.',
	FOOTER_PAGE_INVOICE_QUESTION:
		'Can I obtain an invoice in the name of my company?',
	FOOTER_PAGE_INVOICE_ANSWER:
		'Yes. You just have to check the "company" option in personal data and cover the tax data that we ask for.',
	FOOTER_PAGE_SECURITY_TITLE: 'Security',
	FOOTER_PAGE_SECURITY_DESCRIPTION:
		'For Fina Ejerique, security in online purchases is essential. All transactions are carried out through secure payment systems and confidential payment data is transmitted directly and in encrypted form (SSL) to the entity. We have Sectigo RSA Domain Validation Secure Server CA SSL certificates and online transaction security certificates: Verified by VISA, MasterCard SecureCode and American Express Safekey',
	FOOTER_PAGE_DELIVERY: 'Shipping',
	DELIVERY_TITLE: 'FREE HOME DELIVERY OVER €50',
	DELIVERY_DESCRIPTION_1:
		'Delivery times range from 1 to 3 working days on the peninsula (1 or 2 more days in the Balearic Islands). No shipments are made to the Canary Islands.',
	DELIVERY_DESCRIPTION_2:
		'Delivery times are for orders placed from Monday to Friday before 09:00 am. After that time, the period will begin on the next business day. In special periods such as Black Friday or Sales, the shipping time may be longer than usual.',
	RETURNORDER_CHECK_TERMS_AND_CONDITIONS:
		'By returning your order, you accept the terms of use of the website and the privacy policy of Fina Ejerique.',
	RETURNORDER_DEVOLUTION_N: 'Devolution number',
	RETURNORDER_ORDER_N: 'Order number',
	RETURNORDER_BUY_DATE: 'Date of purchase',
	RETURNORDER_PRODUCTS: 'Products',
	RETURNORDER_SUBTOTAL: 'Subtotal',
	RETURNORDER_TOTAL: 'Total',
	RETURNORDER_SHIPPING: 'Shipping',
	NEWS_LETTER_SUCCESS: 'You have been subscribed',
	NEWS_LETTER_ERROR: 'An error occurred. Please try again later',
	NEWS_LETTER_ALREADY_SUSCRIBED: 'You are already subscribed',
	RETURNORDER_CONTACT_SUPPORT: 'Error. Please contact the technical support',
	RETURNORDER_NO_SELECTED_PRODUCTS: 'There are no products selected',
	RETURNORDER_SELECT_TERMS_CONDITIONS:
		'You must accept the Terms and Conditions to continue.',
	RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE:
		'Your return request has been successfully created. You will receive an email with the return instructions.',
	PASSWORD_RECOVERY: 'Password Recovery',
	PASSWORD_RECOVERY_DESCRIPTION:
		'Enter your email address and we will send you an email with a new password',
	PASSWORD_RECOVERY_EMAIL: 'Email',
	PASSWORD_RECOVERY_ERROR_INVALID_EMAIL: 'Please enter a valid email address',
	PASSWORD_RECOVERY_ERROR_RETRY: 'An error occurred. Please try again later',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND: 'Email not found',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT:
		'An error occurred. Email not sent. Please try again later',
	PASSWORD_RECOVERY_SUCCESS:
		'An email has been sent to your account with a new password',
	PASSWORD_RECOVERY_ERROR: 'An error occurred. Please try again later',
	PASSWORD_RECOVERY_ERROR_EMAIL: 'Please enter a valid email address',
	PASSWORD_RECOVERY_SUBMIT: 'Submit',
	Draft: 'Draft',
	'Pending payment': 'Pending payment',
	Paid: 'Paid',
	'Payment failed': 'Payment failed',
	'Paid pending review': 'Paid pending review',
	Reviewed: 'Reviewed',
	'Sent to SIMSS': 'Sent to SIMSS',
	'Error sending to SIMSS': 'Error sending to SIMSS',
	'Carrier notified': 'Carrier notified',
	'Error notifying carrier': 'Error notifying carrier',
	Shipped: 'Shipped',
	Delivered: 'Delivered',
	Completed: 'Completed',
	Cancelled: 'Cancelled',
	PAGE_NOT_FOUND: 'Page not found',
	NO_PRODUCTS_FOUND: 'No products found for this search criteria',

	RETURN_TITLE: 'Returns',
	RETURN_SUBTITLE_1: 'Returns are FREE',
	RETURN_TEXT_1: 'You have 30 days after receiving your order to request a return. To do so, you can request free home collection and we will send a carrier to collect your package:',
	RETURN_TEXT_2: 'Delivery times are for orders placed Monday to Friday before 9:00 a.m. After that time, the delivery time will start on the next business day. During special periods such as Black Friday or Sales, the delivery time may be longer than usual.',
	RETURN_SUBTITLE_2: '1. Request home collection at finaejerique.com',
	RETURN_TEXT_3: 'If you are registered, go to the Order History and Details section in the My Account section. Next, select the order where the product(s) to be returned are located and once inside, select it and click on the Request a return option. Once all these steps have been completed, we will proceed to collect the package and if the product is in good condition, the full amount of the product will be refunded.',
	RETURN_SUBTITLE_3: '2. Prepare your package',
	RETURN_TEXT_4: 'Pack your items securely, in the original packaging if possible, and attach the return label to the package.',
	RETURN_TEXT_5: 'You can obtain a return note in the Merchandise Returns section by selecting your order and printing the "Return Form".',
	RETURN_SUBTITLE_4: '3. The carrier will come to your home to pick up your package',
	RETURN_TEXT_6: 'Once the previous steps have been completed, the carrier will come to your home to proceed with the collection.',
	RETURN_TEXT_7: 'In the case of a return, the refund will be made using the same payment method used for the purchase and within 3 to 12 business days from when we receive your package at our warehouses.',

	FAQS_TITLE_1: 'Shipping and order tracking',
	FAQS_TITLE_2: 'Returns, exchanges and refunds',
	FAQS_TITLE_3: 'Payments, promotions and gift cards',
	FAQS_TITLE_4: 'Size and product information',
	FAQS_TITLE_5: 'Care Instructions',
	FAQS_TITLE_6: 'Size Guide - Baby',
	FAQS_TITLE_7: 'Size Guide - Kids',
	FAQS_TITLE_8: 'Personal data and communications',
	FAQS_Q_1: 'What are the types of shipping and delivery times for an order?',
	FAQS_Q_2: 'Where is my order?',
	FAQS_Q_3: 'Can I change the delivery address of an order?',
	FAQS_Q_4: 'Can I cancel my order?',
	FAQS_Q_5: 'What can I do if I have not received all the items from my purchase?',
	FAQS_Q_6: 'What can I do if I have received an incorrect or defective item?',
	FAQS_Q_7: 'How can I exchange or return an online purchase?',
	FAQS_Q_8: 'How can I find out the status of my return?',
	FAQS_Q_9: 'How and when will I receive my refund?',
	FAQS_Q_10: 'What are the return conditions?',
	FAQS_Q_11: 'How can I pay for my online purchases?',
	FAQS_Q_12: 'Can I get an invoice in my company\'s name?',
	FAQS_Q_13: 'I have a promotional code, how do I use it?',
	FAQS_Q_14: 'How do I care for my clothes?',
	FAQS_Q_15: 'Washing',
	FAQS_Q_16: 'Use of bleach',
	FAQS_Q_17: 'Ironing',	
	FAQS_Q_18: 'Dry cleaning',
	FAQS_Q_19: 'Drying',
	FAQS_Q_20: 'What is my size?',
	FAQS_Q_21: 'I have forgotten my password, how can I recover it?',
	FAQS_A_1: 'FREE HOME DELIVERY FOR ORDERS OVER €50',
	FAQS_A_2: 'Delivery times range from 1 to 3 working days in the peninsula (1 or 2 more days in the Balearic Islands). We do not ship to the Canary Islands.',
	FAQS_A_3: 'Delivery times are for orders placed from Monday to Friday before 9:00 a.m. After that time, the delivery time will start on the next working day. During special periods such as Black Friday or Sales, the delivery time may be longer than usual.',
	FAQS_A_4: 'Check the details of your purchases and know their status at all times from My Account. You will receive notifications by email so you can easily track them. Keep an eye on our communications.',
	FAQS_A_5: 'We can change the address of an order while it is being prepared in our warehouses. Once it is ready for shipping or in the hands of the carrier, we can no longer make changes to the order. Check the status in My Orders and, if you are on time, contact our Customer Service team.',
	FAQS_A_6: 'You can ask our Customer Service team to cancel your order as long as it is being prepared in our warehouse. If your order can no longer be cancelled, remember that you have 30 days from shipping to make changes and returns easily.',
	FAQS_A_7: 'In the event that any of the items in your purchase have sold out at the last minute and we have not sent them to you, you will receive an email with a notice. In addition, we will refund the amount of the out-of-stock items and the shipping costs in the same payment method of the purchase.',
	FAQS_A_8: 'We are sorry that some of the items are not what you expected. Make the return from My Account indicating on the return form that it is an incorrect or defective item. We will refund the amount of the items plus shipping costs as soon as we receive them in our warehouses.',
	FAQS_A_9: 'Returns are FREE. You have 30 days after receiving your order to request a return. To do so, you can request free home collection and we will send a carrier to pick up your package:',
	FAQS_A_10: '1. Request home collection at finaejerique.com',
	FAQS_A_11: 'If you are registered, go to the My Account section. Next, select the order where the product(s) to be returned are located and once inside, select it and click on the Request a return option. Once all these steps have been completed, we will proceed to collect the package and if the product is in good condition, the full amount of the product will be refunded.',
	FAQS_A_12: '2. Prepare your package',
	FAQS_A_13: 'Pack your items securely, in the original packaging if possible, and stick the return label on the package.',
	FAQS_A_14: '3. The carrier will come to your home to pick up your package',
	FAQS_A_15: 'Once the previous steps have been completed, the carrier will come to your home to proceed with the collection.',
	FAQS_A_16: 'If you made a return, your package may take several days to arrive at our warehouses. You will receive an email when we refund the items or, in the case of an exchange, when we send the new size you requested.',
	FAQS_A_17: 'If it is a return, the refund will be made to the same payment method used in the purchase and within 3 to 12 business days from when we receive your package at our warehouses. Please note that we will refund the cost of the garments, not the shipping costs (only if the garment was faulty or incorrect). If your purchase exceeded €50, we will not refund the shipping costs since shipping was free.',
	FAQS_A_18: 'Items must be returned in the same condition as they were received. Items that you have fixed or are personalized cannot be exchanged or returned because they are made exclusively for you and cannot be put back on sale.',
	FAQS_A_19: 'You can use the following payment methods: Visa, Visa Electron, Mastercard, Maestro, JCB, UPAY and Bizum.',
	FAQS_A_20: 'In the event that the bank rejects your card, check that:',
	FAQS_A_21: 'The card is not expired.',
	FAQS_A_22: 'That your card does not exceed the validity date.',
	FAQS_A_23: 'The card limit may have been reached. Check with your bank that the card has not exceeded the amount allowed for making purchases.',
	FAQS_A_24: 'Check that you have correctly filled out all the required fields.',
	FAQS_A_25: 'Yes. You just have to select the "company" option in personal data and fill in the tax information that we ask for.',
	FAQS_A_26: 'Before completing your purchase, find the promotional code space where you can add your code (only one per purchase). If no discount applies, check the conditions of the promotion or its validity date. The code may have expired or the items in your purchase may not be eligible for the promotion. You will see the accumulated amount that you can discount before completing your purchase.',
	FAQS_A_27: 'Specific instructions on garment care are found on the label of each item. Follow these instructions to keep your garments in good condition.',
	FAQS_A_28: 'Machine wash. The number (30º C, 40º C, 60º C, 95º C) indicates the maximum washing temperature.',
	FAQS_A_29: 'Machine wash with normal program and short spin. The number (30º C, 40º C, 60º C, 95º C) indicates the maximum washing temperature.',
	FAQS_A_30: 'Hand wash maximum 30º C. Do not rub or wring.',
	FAQS_A_31: 'Do not wash.',
	FAQS_A_32: 'Bleach can be used.',
	FAQS_A_33: 'Bleach cannot be used.',
	FAQS_A_34: 'High temperature ironing: maximum 200º C. Cotton, linen or viscose.',
	FAQS_A_35: 'Medium temperature ironing: maximum 150º C. Wool and polyester blends.',
	FAQS_A_36: 'Low temperature ironing: maximum 110º C. Wool and polyester blends. Natural silk, rayon, acetate or acrylic.',
	FAQS_A_37: 'Do not iron.',
	FAQS_A_38: 'The letters (A, F, P) contained in the circles indicate the type of product suitable for each garment (useful information for dry cleaning specialists).',
	FAQS_A_39: 'Do not dry clean.',
	FAQS_A_40: 'Tumble dry.',
	FAQS_A_41: 'Do not tumble dry.',
	FAQS_A_42: 'Tumble dry on low heat.',
	FAQS_A_43: 'Tumble dry on normal heat.',
	FAQS_A_44: 'Use the Size Guide: you will see the measurements that correspond to each size so you can measure yourself and choose which one best fits your child.',
	FAQS_A_45: 'When you go to log in to your account, you will see a link to change your password if you have forgotten it. Then, you will have to add the email of your account to receive a message with the steps to follow. It\'s very simple!',
	FAQS_TABLE1_1: 'Size',
	FAQS_TABLE1_2: '1m',
	FAQS_TABLE1_3: '3m',
	FAQS_TABLE1_4: '6m',
	FAQS_TABLE1_5: '9m',
	FAQS_TABLE1_6: '12m',
	FAQS_TABLE1_7: '18m',
	FAQS_TABLE1_8: '2y',
	FAQS_TABLE1_9: '3y',
	FAQS_TABLE1_10: '4y',
	FAQS_TABLE2_1: 'Height',
	FAQS_TABLE3_1: 'Chest',
	FAQS_TABLE4_1: 'Waist',
	FAQS_TABLE5_1: 'Hip',
	FAQS_TABLE6_1: 'Sleeve length',
	FAQS_TABLE7_1: 'Pants length',
	FAQS_TABLE8_1 : 'Size',
	FAQS_TABLE8_2: '2y',
	FAQS_TABLE8_3: '3y',
	FAQS_TABLE8_4: '4y',
	FAQS_TABLE8_5: '5y',
	FAQS_TABLE8_6: '6y',
	FAQS_TABLE8_7: '8y',
	FAQS_TABLE8_8: '10y',
	FAQS_TABLE8_9: '12y',
	FAQS_TABLE8_10: '14y',
	FAQS_TABLE9_1: 'Height',
	FAQS_TABLE10_1: 'Chest',
	FAQS_TABLE11_1: 'Waist',
	FAQS_TABLE12_1: 'Hip',
	FAQS_TABLE13_1: 'Sleeve length',
	FAQS_TABLE14_1: 'Pants length',
	PARTNERS_TITLE: 'Partners',
	PARTNERS_TEXT_1: 'GLOBOTEX SL, has been a beneficiary of European Funds, whose objective is to strengthen the sustainable growth and competitiveness of SMEs, and thanks to which it has launched an Action Plan with the aim of improving its competitiveness through digital transformation, online promotion and electronic commerce in international markets during the year 2024. To do so, it has had the support of the Xpande Digital Program of the Valencia Chamber of Commerce. #EuropaSeSiente',
	PARTNERS_TEXT_2: 'GLOBOTEX SOCIEDAD DE RESPONSABILIDAD LIMITADA, has been a beneficiary of European Funds, whose objective is to strengthen the sustainable growth and competitiveness of SMEs, and thanks to which it has launched an Internationalization Plan with the aim of improving its competitive positioning abroad during the year 2024. To do so, it has had the support of the Xpande Digital Program of the Valencia Chamber of Commerce. #EuropaSeSiente',
	PRIVACY_TITLE: 'PRIVACY POLICY',
	PRIVACY_SUBTITLE_1: 'Processing of customer data',
	PRIVACY_SUBTITLE_2: 'Basic information on Data Protection',
	PRIVACY_SUBTITLE_3: 'Full information on Data Protection',
	PRIVACY_SUBTITLE_4: 'Processing of data of potential customers and contacts',
	PRIVACY_SUBTITLE_5: 'Basic information on Data Protection',
	PRIVACY_SUBTITLE_6: 'Full information on Data Protection',
	PRIVACY_TEXT_1: 'At GLOBOTEX, S.L. we care about privacy and transparency. Below, we detail the personal data processing we carry out, as well as all the information relating to them.',
	PRIVACY_TEXT_2: 'Controller',
	PRIVACY_TEXT_3: 'GLOBOTEX, S.L.',
	PRIVACY_TEXT_4: 'Purpose',
	PRIVACY_TEXT_5: 'To provide the requested services and send commercial communications.',
	PRIVACY_TEXT_6: 'Legitimacy',
	PRIVACY_TEXT_7: 'Execution of a contract. Legitimate interest of the Controller.',
	PRIVACY_TEXT_8: 'Recipients',
	PRIVACY_TEXT_9: 'Data will not be transferred to third parties, except under legal obligation.',
	PRIVACY_TEXT_10: 'Rights',
	PRIVACY_TEXT_11: 'You have the right to access, rectify and delete the data, as well as other rights, indicated in the additional information, which you can exercise by contacting legal@finaejerique.es or C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_12: 'Origin',
	PRIVACY_TEXT_13: 'The interested party himself.',
	PRIVACY_TEXT_14: '1. Who is responsible for the processing of your data?',
	PRIVACY_TEXT_15: 'GLOBOTEX, S.L.<br />B97230692<br />C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA - VALENCIA<br />963135167<br />legal@finaejerique.es',
	PRIVACY_TEXT_16: '2. For what purpose do we process your personal data?',
	PRIVACY_TEXT_17: 'At GLOBOTEX, S.L. we process the information provided by interested parties in order to carry out the administrative, accounting and tax management of the requested services, as well as to send commercial communications about our products and services. In the event that you do not provide your personal data, we will not be able to fulfill the purposes described. <br/>No automated decisions will be made based on the data provided.',
	PRIVACY_TEXT_18: '3. How long will we keep your data?',
	PRIVACY_TEXT_19: 'The data will be kept as long as the interested party does not request its deletion, and where appropriate, for the years necessary to comply with legal obligations.',
	PRIVACY_TEXT_20: '4. What is the legitimacy for the processing of your data?',
	PRIVACY_TEXT_21: 'We indicate the legal basis for the processing of your data:',
	PRIVACY_TEXT_22: 'Execution of a contract',
	PRIVACY_TEXT_23: 'Tax, accounting and administrative management of clients. (RGPD art. 6.1.b).',
	PRIVACY_TEXT_24: 'Legitimate interest of the Controller',
	PRIVACY_TEXT_25: 'Sending commercial communications, including by electronic means. (RGPD Recital 47, LSSICE art. 21.2).',
	PRIVACY_TEXT_26: '5. To which recipients will your data be communicated?',
	PRIVACY_TEXT_27: 'Data will not be transferred to third parties, except under legal obligation.',
	PRIVACY_TEXT_28: '6. Data transfers to third countries',
	PRIVACY_TEXT_29: 'Data transfers to third countries are not planned.',
	PRIVACY_TEXT_30: '7. What are your rights when you provide us with your data?',
	PRIVACY_TEXT_31: 'Anyone has the right to obtain confirmation as to whether or not GLOBOTEX, S.L. is processing personal data concerning them.',
	PRIVACY_TEXT_32: 'Interested persons have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected. They also have the right to data portability.',
	PRIVACY_TEXT_33: 'In certain circumstances, interested parties may request that the processing of their data be limited, in which case we will only retain them for the exercise or defence of claims.',
	PRIVACY_TEXT_34: 'In certain circumstances and for reasons related to their particular situation, interested parties may object to the processing of their data. In this case, GLOBOTEX, S.L. will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.',
	PRIVACY_TEXT_35: 'You may materially exercise your rights in the following manner: by contacting legal@finaejerique.es or C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_36: 'When commercial communications are sent using the legitimate interest of the controller as a legal basis, the interested party may object to the processing of their data for that purpose.',
	PRIVACY_TEXT_37: 'If you have given your consent for a specific purpose, you have the right to withdraw the consent given at any time, without affecting the legality of the processing based on the consent prior to its withdrawal.',
	PRIVACY_TEXT_38: 'If you feel that your rights regarding the protection of your personal data have been violated, especially when you have not obtained satisfaction in the exercise of your rights, you may file a complaint with the competent Data Protection Control Authority through its website: www.aepd.es.',
	PRIVACY_TEXT_39: '8. How did we obtain your data?',
	PRIVACY_TEXT_40: 'The personal data that we process at GLOBOTEX, S.L. come from: The interested party.',
	PRIVACY_TEXT_41: 'The categories of data processed are:',
	PRIVACY_TEXT_42: 'Identification data',
	PRIVACY_TEXT_43: 'Postal and electronic addresses',
	PRIVACY_TEXT_44: 'Commercial information',
	PRIVACY_TEXT_45: 'Controller',
	PRIVACY_TEXT_46: 'GLOBOTEX, S.L.',
	PRIVACY_TEXT_47: 'Purpose',
	PRIVACY_TEXT_48: 'To handle your request and send you commercial communications.',
	PRIVACY_TEXT_49: 'Legitimation',
	PRIVACY_TEXT_50: 'Execution of a contract. Consent of the interested party. Legitimate interest of the Controller.',
	PRIVACY_TEXT_51: 'Recipients',
	PRIVACY_TEXT_52: 'Data will not be transferred to third parties, except under legal obligation.',
	PRIVACY_TEXT_53: 'Rights',
	PRIVACY_TEXT_54: 'You have the right to access, rectify and delete the data, as well as other rights, indicated in the additional information, which you can exercise by contacting legal@finaejerique.es or C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_55: 'Origin',
	PRIVACY_TEXT_56: 'The interested party himself.',
	PRIVACY_TEXT_57: '1. Who is responsible for the processing of your data?',
	PRIVACY_TEXT_58: 'GLOBOTEX, S.L.<br />B97230692<br />C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA - VALENCIA<br />963135167<br />legal@finaejerique.es',
	PRIVACY_TEXT_59: '2. For what purpose do we process your personal data?',
	PRIVACY_TEXT_60: 'At GLOBOTEX, S.L. we process the information provided by interested parties in order to manage potential clients who have shown interest in our products and/or services, as well as other commercial contacts. Sending commercial communications, including by electronic means. If you do not provide your personal data, we will not be able to fulfill the purposes described. <br/>No automated decisions will be made based on the data provided.',
	PRIVACY_TEXT_61: '3. How long will we keep your data?',
	PRIVACY_TEXT_62: 'The data will be kept as long as the interested party does not request its deletion.',
	PRIVACY_TEXT_63: '4. What is the legitimacy for the processing of your data?',
	PRIVACY_TEXT_64: 'We indicate the legal basis for the processing of your data:',
	PRIVACY_TEXT_65: 'Execution of a contract',
	PRIVACY_TEXT_66: 'Management of potential clients who have shown interest in our products and/or services. (RGPD, art. 6.1.b, LSSICE art.21).',
	PRIVACY_TEXT_67: 'Consent of the interested party',
	PRIVACY_TEXT_68: 'Send commercial communications, including by electronic means. (RGPD, art. 6.1.a).',
	PRIVACY_TEXT_69: 'Legitimate interest of the Controller',
	PRIVACY_TEXT_70: 'Management of professional contact data (LOPDGDD art.19, RGPD art. 6.1.f).',
	PRIVACY_TEXT_71: '5. To which recipients will your data be communicated?',
	PRIVACY_TEXT_72: 'Data will not be transferred to third parties, except under legal obligation.',
	PRIVACY_TEXT_73: '6. Data transfers to third countries',
	PRIVACY_TEXT_74: 'Data transfers to third countries are not planned.',
	PRIVACY_TEXT_75: '7. What are your rights when you provide us with your data?',
	PRIVACY_TEXT_76: 'Any person has the right to obtain confirmation as to whether GLOBOTEX, S.L. we are processing, or not, personal data that concerns them.',
	PRIVACY_TEXT_77: 'Interested persons have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected. Likewise, they have the right to the portability of their data.',
	PRIVACY_TEXT_78: 'In certain circumstances, interested parties may request the limitation of the processing of their data, in which case we will only retain them for the exercise or defense of claims.',
	PRIVACY_TEXT_79: 'In certain circumstances and for reasons related to their particular situation, interested parties may object to the processing of their data. In this case, GLOBOTEX, S.L. will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.',
	PRIVACY_TEXT_80: 'You may materially exercise your rights in the following way: by contacting legal@finaejerique.es or C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.',
	PRIVACY_TEXT_81: 'When commercial communications are sent using the legitimate interest of the controller as a legal basis, the interested party may object to the processing of their data for that purpose.',
	PRIVACY_TEXT_82: 'If you have given your consent for a specific purpose, you have the right to withdraw the consent given at any time, without affecting the legality of the processing based on the consent prior to its withdrawal.',
	PRIVACY_TEXT_83: 'If you feel that your rights regarding the protection of your personal data have been violated, especially when you have not obtained satisfaction in the exercise of your rights, you can file a claim with the competent Data Protection Control Authority through its website: www.aepd.es.',
	PRIVACY_TEXT_84: '8. How did we obtain your data?',
	PRIVACY_TEXT_85: 'The personal data that we process at GLOBOTEX, S.L. come from: The interested party himself.',
	PRIVACY_TEXT_86: 'The categories of data that are processed are:',
	PRIVACY_TEXT_87: 'Identification data',
	PRIVACY_TEXT_88: 'Postal and electronic addresses',
	PRIVACY_TEXT_89: 'Commercial information',

	COOKIE_TITLE: 'Cookie Policy',
	COOKIE_SUBTITLE_1: 'WHAT ARE COOKIES?',
	COOKIE_SUBTITLE_2: 'TYPES OF COOKIES',
	COOKIE_SUBTITLE_3: 'DEACTIVATION AND DELETION OF COOKIES',
	COOKIE_SUBTITLE_4: 'Acceptance of the Cookie Policy',
	COOKIE_TEXT_1:'On the GLOBOTEX, S.L. website, we inform you about the use of cookies on its website:',
	COOKIE_TEXT_2:'Cookies are files that can be downloaded to your computer through web pages. They are tools that play an essential role in the provision of numerous information society services. Among other things, they allow a website to store and retrieve information about the browsing habits of a user or their computer and, depending on the information obtained, they can be used to recognize the user and improve the service offered.',
	COOKIE_TEXT_3:'Depending on the entity that manages the domain from which the cookies are sent and processes the data obtained, two types can be distinguished:',
	COOKIE_TEXT_4:'Own cookies',
	COOKIE_TEXT_5:'Those that are sent to the user\'s terminal equipment from a computer or domain managed by the editor itself and from which the service requested by the user is provided.',
	COOKIE_TEXT_6:'Third-party cookies',
	COOKIE_TEXT_7:'Those that are sent to the user\'s terminal equipment from a computer or domain that is not managed by the editor, but by another entity that processes the data obtained through cookies.',
	COOKIE_TEXT_8: 'There is also a second classification according to the period of time that they remain stored in the client\'s browser, which may be:',
	COOKIE_TEXT_9: 'Session cookies',
	COOKIE_TEXT_10: 'Designed to collect and store data while the user accesses a web page.',
	COOKIE_TEXT_11: 'Persistent cookies',
	COOKIE_TEXT_12: 'The data remains stored in the terminal and can be accessed and processed for a period defined by the person responsible for the cookie, which can range from a few minutes to several years.',
	COOKIE_TEXT_13: 'Finally, there is another classification with five types of cookies according to the purpose for which the data obtained is processed:',
	COOKIE_TEXT_14: 'Technical cookies',
	COOKIE_TEXT_15: 'They allow the user to navigate through a web page, platform or application and the use of the different options or services that exist therein.',
	COOKIE_TEXT_16: 'Personalization cookies',
	COOKIE_TEXT_17: 'They allow the user to access the service with some general characteristics predefined based on a series of criteria.',
	COOKIE_TEXT_18: 'Analysis cookies',
	COOKIE_TEXT_19: 'They allow the monitoring and analysis of the behavior of users of the websites.',
	COOKIE_TEXT_20: 'Advertising cookies',
	COOKIE_TEXT_21: 'They allow the management of advertising spaces.',
	COOKIE_TEXT_22: 'Behavioral advertising cookies',
	COOKIE_TEXT_23: 'They store information on the behavior of users obtained through continuous observation of their browsing habits.',
	COOKIE_TEXT_24: 'External social network cookies',
	COOKIE_TEXT_25: 'They are used so that visitors can interact with the content of different social platforms.',
	COOKIE_TEXT_26: 'You have the option to allow, block or delete the cookies installed on your computer by configuring the options of the browser installed on your computer. By disabling cookies, some of the available services may no longer be operational. The way to disable cookies is different for each browser, but can usually be done from the Tools or Options menu. You can also consult the browser\'s Help menu, where you can find instructions. The user may at any time choose which cookies he/she wants to operate on this website.',
	COOKIE_TEXT_27: 'You can allow, block or delete the cookies installed on your computer by configuring the options of the browser installed on your computer:',
	COOKIE_TEXT_28: 'In addition, you can also manage the cookie store in your browser through tools such as the following:',
	COOKIE_TEXT_29: 'displays information about its Cookie Policy at the bottom or top of any page of the portal with each login so that you are aware.',
	COOKIE_TEXT_30: 'With this information it is possible to carry out the following actions:',
	COOKIE_TEXT_31: 'Accept cookies',
	COOKIE_TEXT_32: 'This notice will not be displayed again when accessing any page of the portal during the current session.',
	COOKIE_TEXT_33: 'Modify your configuration',
	COOKIE_TEXT_34: 'You can obtain more information about what cookies are, learn about the Cookie Policy of https://finaejerique.com/ and modify your browser settings.',

	TERMS_TITLE: 'LEGAL NOTICE',
	TERMS_SUBTITLE_1: '1. PURPOSE',
	TERMS_SUBTITLE_2: '2. IDENTIFICATION',
	TERMS_SUBTITLE_3: '3. COMMUNICATIONS',
	TERMS_SUBTITLE_4: '4. CONDITIONS OF ACCESS AND USE',
	TERMS_SUBTITLE_5: '4.1. EXCLUSION OF WARRANTIES AND LIABILITY IN ACCESS AND USE',
	TERMS_SUBTITLE_6: '4.2. PROCEDURE IN CASE OF ILLEGAL ACTIVITIES',
	TERMS_SUBTITLE_7: '4.3. PUBLICATIONS',
	TERMS_SUBTITLE_8: '5. APPLICABLE LEGISLATION',
	TERMS_TEXT_1: 'This legal notice regulates the use and utilization of the website <a href="http://www.finaejerique.com">www.finaejerique.com</a>, owned by GLOBOTEX, S.L. (hereinafter, THE OWNER OF THE WEBSITE).',
	TERMS_TEXT_2: 'Browsing the website of THE OWNER OF THE WEBSITE attributes to you the condition of USER of the same and implies your full and unreserved acceptance of each and every one of the conditions published in this legal notice, warning that said conditions may be modified without prior notification by THE OWNER OF THE WEBSITE, in which case they will be published and notified as far in advance as possible.',
	TERMS_TEXT_3: 'It is therefore advisable to read its content carefully if you wish to access and use the information and services offered from this website.',
	TERMS_TEXT_4: 'The user, furthermore, undertakes to make correct use of the website in accordance with the laws, good faith, public order, traffic uses and this Legal Notice, and will be liable to THE OWNER OF THE WEBSITE or to third parties for any damages and losses. that may be caused as a consequence of the breach of said obligation.',
	TERMS_TEXT_5: 'Any use other than that authorized is expressly prohibited, and THE OWNER OF THE WEBSITE may deny or withdraw access and its use at any time.',
	TERMS_TEXT_6: 'THE OWNER OF THE WEBSITE, in compliance with Law 34/2002, of July 11, on Information Society Services and Electronic Commerce, informs you that:',
	TERMS_TEXT_7: 'To communicate with us, we put at your disposal different means of contact that we detail below:',
	TERMS_TEXT_8: 'Phone',
	TERMS_TEXT_9: 'All notifications and communications between users and the WEBSITE OWNER will be considered effective, for all purposes, when carried out through any of the means detailed above.',
	TERMS_TEXT_10: 'The website and its services are free and open access. However, the OWNER OF THE WEBSITE may make the use of some of the services offered on its website conditional on the prior completion of the corresponding form.',
	TERMS_TEXT_11: 'The user guarantees the authenticity and timeliness of all data communicated to the OWNER OF THE WEBSITE and will be solely responsible for any false or inaccurate statements made.',
	TERMS_TEXT_12: 'The user expressly agrees to make appropriate use of the contents and services of the OWNER OF THE WEBSITE and not to use them for, among others:',
	TERMS_TEXT_13: 'a) Disseminate criminal, violent, pornographic, racist, xenophobic, offensive content, in support of terrorism or, in general, contrary to the law or public order.',
	TERMS_TEXT_14: 'b) Introduce computer viruses into the network or carry out actions that may alter, damage, interrupt or generate errors. or damage to electronic documents, data or physical and logical systems of the WEBSITE OWNER or third parties.',
	TERMS_TEXT_15: 'c) Attempt to access the email accounts of other users or restricted areas of the computer systems of the WEBSITE OWNER or third parties and, where appropriate, extract information.',
	TERMS_TEXT_16: 'd) Violate intellectual or industrial property rights, as well as violate the confidentiality of information of the WEBSITE OWNER or third parties.',
	TERMS_TEXT_17: 'e) Impersonate any other user.',
	TERMS_TEXT_18: 'f) Reproduce, copy, distribute, make available to, or any other form of public communication, transform or modify the contents, unless you have the authorization of the owner of the corresponding rights or it is legally permitted.',
	TERMS_TEXT_19: 'g) Collect data for advertising purposes and send advertising of any kind and communications for sales purposes or other commercial nature without prior request or consent.',
	TERMS_TEXT_20: 'All content on the website, such as text, photographs, graphics, images, icons, technology, software, as well as its graphic design and source codes, constitute a work whose property belongs to OWNER OF THE WEBSITE, without any of the exploitation rights over them being understood to be transferred to the user beyond what is strictly necessary for the correct use of the website.',
	TERMS_TEXT_21: 'Likewise, all trademarks, trade names or distinctive signs of any kind that appear on the website are the property of OWNER OF THE WEBSITE, without it being understood that the use or access to it attributes to the user any right over them.',
	TERMS_TEXT_22: 'The distribution, modification, transfer or public communication of the contents and any other act that has not been expressly authorized by the owner of the exploitation rights are prohibited.',
	TERMS_TEXT_23: 'The content of this website is of a general nature and has a purely informative purpose, without fully guaranteeing access to all the contents, nor their exhaustiveness, correctness, validity or timeliness, nor their suitability or usefulness for a specific objective.',
	TERMS_TEXT_24: 'THE OWNER OF THE WEBSITE excludes, to the extent permitted by the legal system, any liability for damages of any kind arising from:',
	TERMS_TEXT_25: 'a) The impossibility of accessing the website or the lack of veracity, accuracy, exhaustiveness and/or timeliness of the contents, as well as the existence of vices and defects of all kinds of the contents transmitted, disseminated, stored, made available, to which the information has been transferred, and the contents of the website are not subject to any liability. accessed through the website or the services offered.',
	TERMS_TEXT_26: 'b) The presence of viruses or other elements in the content that may cause alterations in computer systems, electronic documents or user data.',
	TERMS_TEXT_27: 'c) Failure to comply with the law, good faith, public order, traffic usage and this legal notice as a result of incorrect use of the website.',
	TERMS_TEXT_28: 'Likewise, OWNER OF THE WEBSITE declines any responsibility for information found outside this website and not managed directly by our webmaster.',
	TERMS_TEXT_29: 'The function of the links that appear on this website is exclusively to inform the user about the existence of other sources that may expand the content offered by this website. OWNER OF THE WEBSITE does not guarantee or assume responsibility for the operation or accessibility of the linked sites; nor does it suggest, invite or recommend visiting them, and therefore will not be liable for the result obtained.',
	TERMS_TEXT_30: 'In the event that any user or third party considers that there are facts or circumstances that reveal the illicit nature of the use of any content and/or the performance of any activity on the web pages included or accessible through the website, they must send a notification to the OWNER OF THE WEBSITE, duly identifying themselves and specifying the alleged infringements.',
	TERMS_TEXT_31: 'The administrative information provided through the website does not replace the legal publicity of laws, regulations, plans, general provisions and acts that must be formally published in the official journals of public administrations, which constitute the only instrument that attests to their authenticity and content. The information available on this website must be understood as a guide without any legal validity.',
	TERMS_TEXT_32: 'The present conditions will be governed by current Spanish legislation. The language used will be Spanish.',
	
	RETURNORDER_TITLE: 'Return of products',
	RETURNORDER_CHOOSE_PRODUCTS: 'Choose the products to return',
	RETURNORDER_ADDRESS: 'Pickup address',
	RETURNORDER_ALL_PRODUCTS: 'All the products have been returned',
}

export const en: ResourceLanguage = {
	translation: translations,
}

export default en
