const searchMeiliSearch = async (
	query: string,
	language: string = 'en'
) => {
	const url = `${process.env.REACT_APP_SEARCH_SERVICE_URL}/indexes/model-${language}/search`
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			q: query,
			limit: 100,
			sort: [
				'id:desc'
			]
		}),
	})
	if (!response.ok) {
		throw new Error('Network response was not ok')
	}
	return response.json()
}

export default searchMeiliSearch
