import { useEffect, useState } from 'react'
import {
	useLocation,
	useParams,
} from 'react-router-dom'

const ScrollToTop = () => {
	const location = useLocation()
	const { lang } = useParams()
	const [prevLang, setPrevLang] = useState(lang)

	useEffect(() => {
		// Descomponer la ruta en segmentos
		const pathSegments = location.pathname
			.split('/')
			.filter(Boolean) // ['es', 'category', '15']
		const prevPathSegments = pathSegments // podrías necesitar almacenar esto previamente

		// Suponiendo que el idioma está siempre en el primer segmento
		const currentLang = pathSegments[0]
		const didLanguageChange =
			prevLang !== currentLang

		// Aquí decides si quieres ejecutar el código de efecto basado en si el idioma cambió o no
		if (!didLanguageChange) {
			window.scrollTo(0, 0)
		}

		// Actualiza el idioma previo para la próxima comparación
		setPrevLang(currentLang)
	}, [location]) // Dependencias del efecto, reacciona a cambios en la ruta

	return null
}

export default ScrollToTop
