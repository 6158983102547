import {
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { SignUpButton } from './signup-button'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/en-gb'
import countries from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { IRoute } from '../../interfaces/route.interface'
import {
	Password,
	SignUp as SignUpTraslations,
	Translations,
} from '../../translations/translations.enum'
import { ICartUpdate } from '../../utils/cart.util'
import {
	getValidSymbolForPassword,
	isValidEmail,
} from '../../utils/validation.util'
import { saveAccessToken } from '../utils'
import { SignInFooter } from '../utils/sign-in-footer'
import { Visibility, VisibilityOff } from '@mui/icons-material'
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/es.json'))

export interface ISignUpProps extends IRoute {
	nextStep?: () => void
	setIsLogged: (isLogged: boolean) => void
	setCart: ICartUpdate
	changeLoginForm?: () => void
	isOrderLogin?: boolean
}

const SignUp = (props: ISignUpProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { language } = useParams()
	const [countriesSorted, setCountriesSorted] = useState<string[][]>([])

	const [formData, setFormData] = useState<any>({
		email: '',
		firstName: '',
		lastName: '',
		country: 'ES',
		password: '',
		confirmPassword: '',
		subscribeEmails: false,
		birthDate: null,
		termsAccepted: false,
		emailAdvertising: false,
	})
	const [errors, setErrors] = useState<any>({})

	const validateForm = () => {
		let tempErrors: any = {}
		if (!isValidEmail(formData.email)) {
			t(SignUpTraslations.SIGNUP_ERROR_INVALID_EMAIL)
		}
		if (!validatePassword(formData.password)) {
			tempErrors.password = SignUpTraslations.SIGNUP_ERROR_INVALID_PASSWORD
		}

		if (!formData.termsAccepted) {
			tempErrors.termsAccepted = SignUpTraslations.SIGNUP_ERROR_TERMS_CONDITIONS
		}

		if (formData.password !== formData.confirmPassword) {
			tempErrors.confirmPassword =
				SignUpTraslations.SIGNUP_ERROR_PASSWORD_NOT_MATCH
		}

		if (!formData.country.length) {
			tempErrors.country = SignUpTraslations.SIGNUP_ERROR_COUNTRY
		}

		if (!formData.firstName.length) {
			tempErrors.firstName = SignUpTraslations.SIGNUP_ERROR_FIRST_NAME
		}

		if (!formData.lastName.length) {
			tempErrors.lastName = SignUpTraslations.SIGNUP_ERROR_LAST_NAME
		}

		if (Object.keys(tempErrors).length > 0) {
			tempErrors.errorExtraInfo = SignUpTraslations.SIGNUP_ERROR_CHECK_FIELDS
		}

		setErrors(tempErrors)
		return Object.keys(tempErrors).length === 0
	}

	const validatePassword = (password: string) => {
		const hasNumber = /\d/.test(password)
		const hasLetter = /[a-zA-Z]/.test(password)
		const hasSpecialChar = getValidSymbolForPassword().test(password) // Añade dentro de los corchetes cualquier otro símbolo que desees incluir

		return password.length >= 8 && hasNumber && hasLetter && hasSpecialChar
	}

	const handleSubmit = async (event: any) => {
		event.preventDefault()
		if (validateForm()) {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_CORE_API_URL}/auth/signup`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(formData),
					}
				)

				if (response.ok) {
					const data = await response.json()
					saveAccessToken(
						data,
						navigate,
						props.setIsLogged,
						props.nextStep,
						props.setCart,
						true
					)
				} else if (response.status === 409) {
					setErrors({
						email: SignUpTraslations.SIGNUP_ERROR_DUPLICATED_EMAIL,
						errorExtraInfo: SignUpTraslations.SIGNUP_ERROR_CHECK_FIELDS,
					})
				} else {
					setErrors({
						errorExtraInfo: SignUpTraslations.SIGNUP_ERROR_RETRY,
					})
				}
			} catch (error) {
				setErrors({
					errorExtraInfo: SignUpTraslations.SIGNUP_ERROR_RETRY,
				})
			}
		}
	}

	const handleChange = (event: any) => {
		const { name, value, type, checked } = event.target

		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		})
	}

	const handleDateChange = (newValue: any) => {
		setFormData({
			...formData,
			birthDate: newValue,
		})
	}

	useEffect(() => {
		const contriesUnsorted = Object.entries(
			countries.getNames(language || 'en')
		)
		setCountriesSorted(
			contriesUnsorted.sort(([keyA, valueA], [keyB, valueB]) =>
				valueA.localeCompare(valueB, 'es', {
					sensitivity: 'base',
				})
			)
		)
	}, [language])

	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = () => setShowPassword((show) => !show)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword((show) => !show)

	return (
		<Container
			component='main'
			maxWidth='sm'
			sx={{
				padding: '5% 2%',
				width: props.isMobile ? '100%' : '25%',
				fontFamily: 'Bembo',
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				fontFamily={'Bembo'}
				fontSize={'2rem'}
				sx={{
					mb: '2rem',
				}}
			>
				{t(SignUpTraslations.SIGNUP_CREATE_ACCOUNT)}
			</Typography>
			<form
				onSubmit={handleSubmit}
				noValidate
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							id='email'
							label={t(SignUpTraslations.SIGNUP_EMAIL)}
							name='email'
							value={formData.email}
							onChange={handleChange}
							error={!!errors.email}
							helperText={t(errors.email)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							required
							fullWidth
							id='firstName'
							label={t(SignUpTraslations.SIGNUP_FIRST_NAME)}
							name='firstName'
							autoComplete='fname'
							value={formData.firstName}
							onChange={handleChange}
							error={!!errors.firstName}
							helperText={t(errors.firstName)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							required
							fullWidth
							id='lastName'
							label={t(SignUpTraslations.SIGNUP_LAST_NAME)}
							name='lastName'
							autoComplete='lname'
							value={formData.lastName}
							onChange={handleChange}
							error={!!errors.lastName}
							helperText={t(errors.lastName)}
						/>
					</Grid>
					{/* Add other fields like country, gender, date of birth, etc., similar to above */}
					<Grid
						item
						xs={12}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale='en-gb'
						>
							<DatePicker
								label={t(SignUpTraslations.SIGNUP_BIRTHDAY)}
								value={formData.birthDate}
								onChange={handleDateChange}
								sx={{
									width: '100%',
								}}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControl fullWidth>
							<InputLabel id='country-label'>
								{t(SignUpTraslations.SIGNUP_COUNTRY)} *
							</InputLabel>
							<Select
								labelId='country-label'
								id='country'
								name='country'
								value={formData.country}
								label={t(SignUpTraslations.SIGNUP_COUNTRY)}
								onChange={handleChange}
							>
								{countriesSorted.map(([isoCode, countryName]) => (
									<MenuItem
										key={isoCode}
										value={isoCode}
									>
										{countryName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{errors.country && (
							<FormHelperText error>{t(errors.country)}</FormHelperText>
						)}
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							name='password'
							label={t(SignUpTraslations.SIGNUP_PASSWORD)}
							type={showPassword ? 'text' : 'password'}
							id='password'
							value={formData.password}
							onChange={handleChange}
							error={!!errors.password}
							helperText={t(errors.password)}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											edge='end'
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Typography
							sx={{
								mt: '1rem',
							}}
							dangerouslySetInnerHTML={{ __html: t(Password.PASSWORD_DETAILS) }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							name='confirmPassword'
							label={t(SignUpTraslations.SIGNUP_CONFIRM_PASSWORD)}
							type={showConfirmPassword ? 'text' : 'password'}
							id='confirmPassword'
							value={formData.confirmPassword}
							onChange={handleChange}
							error={!!errors.confirmPassword}
							helperText={t(errors.confirmPassword)}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowConfirmPassword}
											edge='end'
										>
											{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControlLabel
							control={
								<Checkbox
									value='termsAccepted'
									color='primary'
									name='termsAccepted'
									checked={formData.termsAccepted}
									onChange={handleChange}
								/>
							}
							label={t(Translations.CHECK_TERMS_AND_CONDITIONS)}
						/>
						{errors.termsAccepted && (
							<FormHelperText error>{t(errors.termsAccepted)}</FormHelperText>
						)}
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControlLabel
							control={
								<Checkbox
									value='emailAdvertising'
									color='primary'
									name='emailAdvertising'
									checked={formData.emailAdvertising}
									onChange={handleChange}
								/>
							}
							label={t(Translations.CHECK_EMAIL_COMMUNICATIONS)}
						/>
					</Grid>
				</Grid>
				<SignUpButton />
				{errors.errorExtraInfo && (
					<FormHelperText error>{t(errors.errorExtraInfo)}</FormHelperText>
				)}

				<SignInFooter
					urlPath={`login`}
					firstText={t(SignUpTraslations.SIGNUP_ALREADY_EXISTS)}
					secondText={t(SignUpTraslations.SIGNUP_SIGNIN)}
					isOrderLogin={props.isOrderLogin}
					isMobile={props.isMobile}
					changeLoginForm={props.changeLoginForm}
				/>
			</form>
		</Container>
	)
}

export default SignUp
