import { Grid } from '@mui/material'
import HomeImage from './home-image'
import homeWideImage from '../../assets/images/home-wide-image.webp'
import homeImageLeft from '../../assets/images/home-image-left.webp'
import homeImageRight from '../../assets/images/home-image-right.webp'
import { IRoute } from '../../interfaces/route.interface'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HomeVideo } from './home-video'
enum HomeMultimediaSize {
	FULL = 'full',
	HALF = 'half',
}
export interface IHomeMultimedia {
	file: string
	isMobile: boolean
	isVideo: boolean
	size: HomeMultimediaSize
	order: number
	category: {
		id: number
		name: string
	}
}

export const Home = (props: IRoute) => {
	const { language } = useParams()
	const [multimedia, setMultimedia] = useState<IHomeMultimedia[]>([])

	const navigate = useNavigate()
	const defaultLanguage = 'es' // El idioma que quieres por defecto.

	const fullElement = props.isMobile ? 12 : 12
	const halfElement = props.isMobile ? 12 : 6

	const gridItemXs = props.isMobile ? 1 : 2
	const heightCorrection = 0.991

	useEffect(() => {
		if (!language) {
			navigate(`/${defaultLanguage}`, {
				replace: true,
			})
		}
		retrieveMultimedia()
	}, [language])

	const retrieveMultimedia = async () => {
		const response = await fetch(
			`${
				process.env.REACT_APP_CORE_API_URL
			}/home-multimedia?isMobile=${props.isMobile.toString()}&language=${language}`
		)
		const data = await response.json()

		setMultimedia(data)
	}

	const wideElement = (multimedia: IHomeMultimedia) => {
		return (
			<Grid
				item
				xs={fullElement}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{multimedia.isVideo ? (
					<HomeVideo video={multimedia} />
				) : (
					<HomeImage
						src={multimedia.file}
						wide={!props.isMobile}
						categoryId={multimedia.category.id}
						text={multimedia.category.name}
						// aspectRatioX={16}
						// aspectRatioY={9}
						// heightCorrection={heightCorrection}
						// gridItemXs={1}
					/>
				)}
			</Grid>
		)
	}

	const halfWideElement = (multimedia: IHomeMultimedia) => {
		return (
			<Grid
				item
				xs={halfElement}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{multimedia.isVideo ? (
					<HomeVideo video={multimedia} />
				) : (
					<HomeImage
						src={multimedia.file}
						wide={false}
						categoryId={multimedia.category.id}
						text={multimedia.category.name}
						// aspectRatioX={16}
						// aspectRatioY={9}
						// heightCorrection={heightCorrection}
						// gridItemXs={1}
					/>
				)}
			</Grid>
		)
	}

	return (
		<Grid
			container
			sx={{
				gap: 0,
			}}
		>
			{multimedia.map((multimedia, index) => {
				if (multimedia.size === HomeMultimediaSize.FULL) {
					return wideElement(multimedia)
				} else {
					return halfWideElement(multimedia)
				}
			})}
		</Grid>
	)
}
