export const fixCase = (text: string) => {
	const words = text.split(' ')
	const fixedWords = words.map((word) => {
		return (
			word.charAt(0).toUpperCase() +
			word.slice(1).toLowerCase()
		)
	})
	return fixedWords.join(' ')
}
