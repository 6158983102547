import { Box, Typography } from '@mui/material'
import { IProduct } from '..'
import { fixCase } from '../../../utils/fix-case'

export function AccordionDetails(props: {
	product?: IProduct
}) {
	return (
		<Box m={'4% 0 7.5%'}>
			{Object.entries(
				props.product?.features || {}
			).map(([key, feature]) => {
				return [
					'size',
					'family',
					'gender',
				].includes(key) ? (
					<></>
				) : (
					<Typography
						key={feature.title}
						mt={'2.5%'}
					>
						<strong
							style={{ fontSize: '1.2rem' }}
						>
							{feature.title}
						</strong>
						<br />
						{fixCase(feature.description || '')}
					</Typography>
				)
			})}
			<Typography
				sx={{
					mt: '5%',
					fontSize: '1.2rem',
				}}
			>
				Ref. {props.product?.externalReference}
			</Typography>
		</Box>
	)
}
