import {
	Box,
	FormControl,
	NativeSelect,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	useLocation,
	useNavigate,
} from 'react-router-dom'
import { LanguageISOCode } from '../../../enums/language-iso-code.enum'
import i18n from '../../../translations'
import { Translations } from '../../../translations/translations.enum'
import { routeMapping } from '../../utils/route-mapping'

export interface ILanguageSelector {
	label: LanguageISOCode
	url: string
	translation: Translations
}

export interface ILanguageSelectorProps {
	isSticky?: boolean
	language: LanguageISOCode
	setLanguage: (language: LanguageISOCode) => void
	isDesktop: boolean
}

export const LanguageSelector = (
	props: ILanguageSelectorProps
) => {
	const [selectLanguage, setSelectLanguage] =
		useState(props.language)
	const { t } = useTranslation()
	const location = useLocation()

	const handleChange = (event: any) => {
		const selectedLanguage = event.target.value
		changeLanguage(selectedLanguage.toLowerCase())
		setSelectLanguage(selectedLanguage)
	}

	const languages: ILanguageSelector[] = [
		{
			label: LanguageISOCode.es,
			url: '/es',
			translation: Translations.SPANISH,
		},
		// Uncomment the following lines to add again pt and/or it languages
		// {
		// 	label: LanguageISOCode.pt,
		// 	url: '/pt',
		// 	translation: Translations.PORTUGUESE,
		// },
		// {
		// 	label: LanguageISOCode.it,
		// 	url: '/it',
		// 	translation: Translations.ITALIAN,
		// },
		{
			label: LanguageISOCode.en,
			url: '/en',
			translation: Translations.ENGLISH,
		},
	]

	const navigate = useNavigate()

	const reverseRouteMapping = (word: string, targetLang: string) => {
		for (const key in routeMapping) {
			const entry = routeMapping[key];
			for (const [lang, translation] of Object.entries(entry)) {
				if (translation === word) {
					return entry[targetLang];
				}
			}
		}
		return null;
	}

	const changeLanguage = (
		newLanguage: LanguageISOCode
	) => {
		props.setLanguage
			? props.setLanguage(newLanguage)
			: void 0
		i18n.changeLanguage(newLanguage)
		const oldPath = location.pathname.substring(4)
		const newPageUrl = reverseRouteMapping(oldPath, newLanguage)
		let newPath
		if (newPageUrl) {
			newPath = `${newLanguage}/${newPageUrl}`
		} else {
			const path = location.pathname
			const currentUrlSplitted = path.split('/')
			const currentLanguage = currentUrlSplitted[1]
			newPath = location.pathname.replace(
				`${currentLanguage}`,
				`${newLanguage}`
			)
		}

		navigate(newPath)
	}

	const isCurrentLanguage = (
		language: LanguageISOCode
	) => {
		return language === props.language
	}

	const getLanguageSelector = (
		language: ILanguageSelector,
		isLast: boolean
	) => {
		return (
			<>
				<Typography
					className='header-language-selector'
					fontWeight='bold'
					key={language.label}
					onClick={() =>
						changeLanguage(language.label)
					}
					color={
						isCurrentLanguage(language.label)
							? 'primary.main'
							: 'grey.800'
					}
					sx={{
						textDecoration: isCurrentLanguage(
							language.label
						)
							? 'underline'
							: 'none',
						cursor: 'pointer',
						'&:hover': {
							textDecoration: 'underline',
						},
					}}
				>
					{language.label.toUpperCase()}
				</Typography>

				{!isLast && <span>&nbsp;|&nbsp;</span>}
			</>
		)
	}

	const getMobileLanguageSelector = (
		language: ILanguageSelector
	) => {
		return (
			<option
				value={language.label}
				key={language.label}
			>
				{t(language.translation)}
			</option>
		)
	}

	const mobileLanguageSelector = () => {
		return (
			<FormControl
				variant='standard'
				sx={{
					width: '30%',
				}}
			>
				<NativeSelect
					id='demo-simple-select'
					defaultValue={props.language}
					onChange={handleChange}
					value={selectLanguage}
				>
					{languages.map((language, index) => {
						return getMobileLanguageSelector(
							language
						)
					})}
				</NativeSelect>
			</FormControl>
		)
	}

	const desktopLanguageSelector = () => {
		return languages.map((language, index) => {
			return getLanguageSelector(
				language,
				index === languages.length - 1
			)
		})
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'flex-end',
				margin: props.isDesktop
					? props.isSticky
						? '2px 0 0 0'
						: '10px 0 0 0'
					: '0 0 0 0',
				fontSize: '12px',
			}}
		>
			{props.isDesktop
				? desktopLanguageSelector()
				: mobileLanguageSelector()}
		</Box>
	)
}
