import { Typography } from '@mui/material'

export const CategoryTitle = (props: {
	title: string
}) => {
	const style = {
		fontFamily: 'Antic',
		'@media (max-width:767px)': {
			fontSize: '1.71429rem',
			lineHeight: '1.71429rem', // Ejemplo de tamaño de fuente
			margin: '0 0 6px',
		},
		'@media (min-width:769px)': {
			fontSize: '2.28571rem',
			paddingBottom: '10px',
		},
	}

	return (
		<Typography sx={style}>
			{props.title}
		</Typography>
	)
}
