import { Box, Typography } from '@mui/material'
import { getPriceComponent } from '../../utils/price-component'

export interface ICartSubtotal {
	text: string
	value: number
	valueWithDiscount?: number
}

export function CartSubtotal(
	props: ICartSubtotal
) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				margin: '10px 0',
			}}
		>
			<Typography
				variant='h5'
				fontFamily={'Bembo'}
				fontSize={'1.25rem'}
			>
				{props.text}
			</Typography>
			{getPriceComponent(
				'1.25rem',
				props.value,
				props.valueWithDiscount,
				'flex-end'
			)}
		</Box>
	)
}
