import { Grid } from '@mui/material'
import { CategoryProduct } from './category-product'
import { CategoryItem } from './category'

export const CategoryGridItem = (props: {
	product: CategoryItem
	isMobile: boolean
	hidePrice?: boolean
}) => {
	return (
		<Grid
			item
			xs={6}
			sm={6}
			md={4}
			lg={3}
		>
			<CategoryProduct
				product={props.product}
				isMobile={props.isMobile}
				hidePrice={props.hidePrice}
			/>
		</Grid>
	)
}
