import { SxProps, Theme, Typography } from '@mui/material'

export const footerPageTitle = (text: string, isMobile: boolean) => (
	<Typography
		fontFamily={'Avenir'}
		fontSize={'1.75rem'}
		fontWeight={'regular'}
		my={isMobile ? '5%' : '2%'}
	>
		{text}
	</Typography>
)

export const footerPageSubTitle = (text: string, isMobile: boolean) => (
	<Typography
		fontFamily={'Avenir'}
		fontWeight={'bold'}
		fontSize={'1rem'}
		my={isMobile ? '3%' : '0.5%'}
	>
		{text}
	</Typography>
)

export const footerPageText = (
	text: any,
	isMobile: boolean,
	props: SxProps<Theme> = {}
) => (
	<Typography
		fontFamily={'Avenir'}
		my={isMobile ? '3%' : '0.5%'}
		sx={{
			fontSize: '0.95rem',
			...props,
		}}
		dangerouslySetInnerHTML={{ __html: text }}
	></Typography>
)
