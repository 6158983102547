import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export interface IAccountOverviewShortcutProps {
	icon: JSX.Element
	text: string
	subText: string
	path: string
}

export function AccountOverviewShortcut(
	props: IAccountOverviewShortcutProps
) {
	return (
		<Box
			sx={{
				border: '1px solid #e0e0e0',
				padding: '3rem 3rem',
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			{props.icon}
			<Link
				style={{
					display: 'flex',
					flexDirection: 'column',
					textDecoration: 'none',
					color: 'black',
				}}
				to={props.path}
			>
				<Typography
					variant='h6'
					sx={{
						marginLeft: '1rem',
						fontSize: '1.5rem',
						lineHeight: '2rem',
					}}
				>
					{props.text}
				</Typography>
				<Typography
					sx={{
						marginLeft: '1rem',
					}}
				>
					{props.subText}
				</Typography>
			</Link>
		</Box>
	)
}
