import { IOrderRequestLine } from '../../interfaces/order-line-request.interface'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import {
	CategoryItem,
	ICategory,
} from '../category/category'
import { IProduct } from '../product'

const handleGATagAddToCartClick = (
	product: IProduct
) => {
	;(window as any).dataLayer.push({
		event: 'add_to_cart',
		ecommerce: {
			items: [product.name],
			currency: 'EUR',
			value: product.price,
		},
	})
}

const handleGATagLoadProduct = (
	product: IProduct
) => {
	;(window as any).dataLayer.push({
		event: 'view_item',
		ecommerce: {
			items: [product.externalReference],
		},
	})
}

const handleGATagBeginCheckout = (
	order: IOrderRequest
) => {
	const products = order.orderLines.map(
		(product: IOrderRequestLine) => product.name
	)
	;(window as any).dataLayer.push({
		event: 'begin_checkout',
		ecommerce: {
			items: [products],
		},
	})
}

const handleGATagSelectItem = (
	product: CategoryItem
) => {
	;(window as any).dataLayer.push({
		event: 'select_item',
		ecommerce: {
			items: [product.name],
		},
	})
}

const handleGATagViewItem = (
	product: IProduct
) => {
	;(window as any).dataLayer.push({
		event: 'view_item',
		ecommerce: {
			items: [product.name],
		},
	})
}

const handleGATagViewItemList = (
	product: ICategory
) => {
	;(window as any).dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			items: [product.name],
		},
	})
}

export {
	handleGATagAddToCartClick,
	handleGATagLoadProduct,
	handleGATagBeginCheckout,
	handleGATagSelectItem,
	handleGATagViewItem,
	handleGATagViewItemList,
}
