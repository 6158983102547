import { useEffect, useState } from 'react'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { IRoute } from '../../interfaces/route.interface'
import Cookies from 'js-cookie'
import { ReturnOrderPreview } from './return-order-preview'
import { Grid } from '@mui/material'

export interface IReturnOrderListProps
	extends IRoute {}

export function ReturnOrderList(
	props: IReturnOrderListProps
) {
	const [orders, setOrders] = useState<
		IOrderRequest[]
	>([])

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/return-order`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${Cookies.get(
						'accessToken'
					)}`,
				},
			}
		).then(async (response) => {
			if (response.ok) {
				const data = await response.json()
				setOrders(data)
			}
		})
	}, [])

	return (
		<Grid
			container
			width={'100%'}
			gap={1}
			justifyContent={'center'}
		>
			{orders?.map((order) => (
				<Grid
					item
					xs={12}
					md={5.5}
				>
					<ReturnOrderPreview
						order={order}
						isMobile={props.isMobile}
					/>
				</Grid>
			))}
		</Grid>
	)
}
