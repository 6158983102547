import React, { useState } from 'react'
import { Modal, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ProductZoom from './zoom.product'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
function ImageModal({
	images,
	setOpen,
	open,
	modalImageIndex,
	setModalImageIndex,
}: {
	images: string[]
	setOpen: (open: boolean) => void
	open: boolean
	modalImageIndex: number
	setModalImageIndex: (index: number) => void
}) {
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const nextPhoto = () => {
		if (modalImageIndex < images.length - 1) {
			setModalImageIndex(modalImageIndex + 1)
		} else {
			setModalImageIndex(0)
		}
	}

	const previousPhoto = () => {
		if (modalImageIndex > 0) {
			setModalImageIndex(modalImageIndex - 1)
		} else {
			setModalImageIndex(images.length - 1)
		}
	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						bgcolor: 'background.paper',
						boxShadow: 24,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						width: 'fit-content',
					}}
				>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<CloseIcon
							onClick={() => {
								handleClose()
							}}
							sx={{
								cursor: 'pointer',
								p: 1,
							}}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<ArrowBackIosNewIcon
							onClick={() => previousPhoto()}
							sx={{
								cursor: 'pointer',
								padding: '10px',
							}}
						/>

						<ProductZoom
							src={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_PRODUCT_IMAGE_PATH}/${images[modalImageIndex]}`}
						/>

						<ArrowForwardIosIcon
							onClick={() => nextPhoto()}
							sx={{
								cursor: 'pointer',
								padding: '10px',
							}}
						/>
					</Box>
				</Box>
			</Modal>
		</div>
	)
}

export default ImageModal
