import {
	Dispatch,
	SetStateAction,
	useState,
} from 'react'
import {
	Drawer,
	Button,
	Box,
} from '@mui/material'
import FilterAccordion from './filter-accordion.mobile'
import { FilterApplyButton } from './filter-apply-button.mobile'
import { FilterClearButton } from './filter-clear-button.mobile'
import { Translations } from '../../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import { ExpandMore } from '@mui/icons-material'

export interface ICategoryMobileFilterProps {
	setColor: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
	setSize: Dispatch<
		SetStateAction<Record<string, boolean>>
	>
	colors: Record<string, boolean>
	sizes: Record<string, boolean>
}

function CategoryMobileFilter(
	props: ICategoryMobileFilterProps
) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const toggleDrawer =
		(open: any) => (event: any) => {
			if (
				event.type === 'keydown' &&
				(event.key === 'Tab' ||
					event.key === 'Shift')
			) {
				return
			}

			setIsOpen(open)
		}

	return (
		<div>
			<Button
				onClick={toggleDrawer(true)}
				sx={{
					ml: '5px',
				}}
			>
				{t(Translations.FILTER)} <ExpandMore />
			</Button>
			<Drawer
				anchor={'left'}
				open={isOpen}
				onClose={toggleDrawer(false)}
				sx={{
					width: '100vw',
					[`& .MuiDrawer-paper`]: {
						width: '100vw',
						boxSizing: 'border-box',
						paddingTop: `${process.env.REACT_APP_HEADER_HEIGHT_MOBILE_PX}px`,
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
					role='presentation'
				>
					<FilterAccordion
						setColor={props.setColor}
						setSize={props.setSize}
						colors={props.colors}
						sizes={props.sizes}
						isMobile={true}
					/>
					<Box
						sx={{
							borderTop: '1px solid #eee', // Opcional, para dar una separación visual
							position: 'fixed',
							bottom: '0%',
							zIndex: 999999,
							width: '98%',
							flexDirection: 'row',
							display: 'flex',
							gap: '1%',
							background: 'white',
							boxShadow:
								'0px -5px 13px -6px #333',
							padding: '1%',
						}}
					>
						<FilterClearButton
							setColor={props.setColor}
							setSize={props.setSize}
						/>
						<FilterApplyButton
							setIsOpen={setIsOpen}
						/>
					</Box>
				</Box>
			</Drawer>
		</div>
	)
}

export default CategoryMobileFilter
