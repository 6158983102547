import Cookies from 'js-cookie'
import {
	AccountRoutes,
	Translations
} from '../../translations/translations.enum'
import { ICartUpdate, cleanCart } from '../../utils/cart.util'
import { routeMapping } from '../utils/route-mapping'

export interface IMenuRightChildren {
	name: string
	id: string
	onlyLoggedIn?: boolean
	onClick?: () => void
}

export interface IMenuRight {
	name: string
	id: string
	onlyLoggedIn?: boolean
	children?: IMenuRightChildren[]
}

export const listItemsRight = (
	isLogged: boolean,
	setIsLogged: (isLogged: boolean) => void,
	setCart: ICartUpdate,
	language: string = 'es'
): IMenuRight[] => {
	const menus: IMenuRight[] = [
		// {
		// 	name: Translations.WISHLIST,
		// 	id: 'wishlist',
		// 	onlyLoggedIn: true,
		// },
		{
			name: Translations.MYFINAEJERIQUE,
			id: isLogged ? 'account' : 'login',
			children: [],
		},
		{
			id: routeMapping.shoppingBag[language],
			name: Translations.SHOPPING_BAG,
		},
	]

	if (isLogged) {
		menus[0].children?.push(
			{
				id: routeMapping.myInfo[language],
				name: AccountRoutes.MY_INFO,
			},
			{
				id: routeMapping.orders[language],
				name: AccountRoutes.ORDER_HISTORY,
			},
			{
				id: routeMapping.accountReturns[language],
				name: AccountRoutes.RETURNS,
			},
			{
				id: routeMapping.accountAddresses[language],
				name: AccountRoutes.ADDRESS_BOOK,
			},
			{
				id: routeMapping.wishlist[language],
				name: AccountRoutes.WISHLIST,
			},
			{
				id: routeMapping.changePassword[language],
				name: AccountRoutes.CHANGE_PASSWORD,
			},
			{
				id: '',
				name: Translations.SIGN_OUT,
				onlyLoggedIn: true,
				onClick: () => {
					cleanCart(setCart)
					Cookies.remove('accessToken')
					setIsLogged(false)
				},
			}
		)
	} else {
		menus[0].children?.push({
			name: Translations.SIGN_IN,
			id: 'login',
			onlyLoggedIn: false,
		})

		menus[0].children?.push({
			name: Translations.SIGN_UP,
			id: routeMapping.signup[language],
			onlyLoggedIn: false,
		})
	}

	return menus
}
