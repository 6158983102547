import type {
	PreauthBody,
	PreauthOutput,
	AuthBody,
	AuthOutput,
	FinishedMessageEvent,
} from './server-info'
import { waitForIframeToPostMessage } from './utils/in-site'
import type { RedsysMessageEvent } from './types/redsys'
import type { ThreeDSBrowserClientInfo } from 'redsys-easy'
import {
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	useNavigate,
	useParams,
} from 'react-router-dom'
import {
	Box,
	Container,
	Typography,
	styled,
} from '@mui/material'
import { IOrderRequest } from '../../interfaces/order-request.interface'

const obtain3DSBrowserInfo =
	(): ThreeDSBrowserClientInfo => {
		return {
			browserLanguage: navigator.language,
			// browserJavascriptEnabled: true,
			browserScreenHeight:
				window.screen.height.toString(),
			browserColorDepth:
				window.screen.colorDepth.toString(),
			browserScreenWidth:
				window.screen.width.toString(),
			browserTZ: new Date()
				.getTimezoneOffset()
				.toString(),
			// browserUserAgent: navigator.userAgent,
			browserJavaEnabled: navigator.javaEnabled(),
		}
	}

interface SectionProps {
	hidden?: boolean | undefined
}

type SectionName =
	| 'payment-data'
	| 'challenge-v1'
	| 'challenge-v2'

const Section = styled('div')<SectionProps>`
	width: 100%;
	${({ hidden = false }) =>
		hidden ? 'display: none;' : ''}
`

const NonVisibleSection = styled(
	'div'
)<SectionProps>`
	position: absolute;
	width: 0;
	height: 0;
	border: 0;
`

export interface ICardPaymentPageProps {
	order?: IOrderRequest
}

export const CardPaymentPage = (
	props: ICardPaymentPageProps
) => {
	const navigate = useNavigate()

	let { redsysId, language } = useParams()

	if (props.order && !redsysId) {
		redsysId = props.order.redsysId
	}
	/**
	 * 1. Generate redsys INSITE form
	 * 2. Change from payment selection to payment page
	 */

	const [activeSection, setActiveSection] =
		useState<SectionName>('payment-data')

	let threeDSMethodIframeEl =
		useRef<HTMLIFrameElement>(null)
	let threeDSMethodFormEl =
		useRef<HTMLFormElement>(null)
	let threeDSMethodDataEl =
		useRef<HTMLInputElement>(null)

	let challengeV1IframeEl =
		useRef<HTMLIFrameElement>(null)
	let challengeV1FormEl =
		useRef<HTMLFormElement>(null)
	let challengeV1MDEl =
		useRef<HTMLInputElement>(null)
	let challengeV1PaReqEl =
		useRef<HTMLInputElement>(null)
	let challengeV1TermUrlEl =
		useRef<HTMLInputElement>(null)

	let challengeV2IframeEl =
		useRef<HTMLIFrameElement>(null)
	let challengeV2FormEl =
		useRef<HTMLFormElement>(null)
	let challengeV2CreqEl =
		useRef<HTMLInputElement>(null)

	/**
	 * Input validator
	 *
	 * @returns {boolean}
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const inputValidator = () => {
		// Should validate input before sending form
		return true
	}

	const preauthRequest = async (
		redsysId: string,
		idOper: string
	): Promise<PreauthOutput> => {
		const preauthBody: PreauthBody = {
			orderId: redsysId,
			transactionData: {
				type: 'id-oper',
				idOper,
			},
		}

		const response = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/redsys/preauth`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(preauthBody),
			}
		)

		if (!response.ok) {
			alert('Preauth failed') // See how to handle it. Maybe Toast
		}

		return response.json()
	}

	const authRequest = async (
		preauthResponse: PreauthOutput,
		clientInfo: ThreeDSBrowserClientInfo,
		idOper: string,
		redsysId: string
	): Promise<AuthOutput> => {
		const authBody: AuthBody = {
			transactionData:
				preauthResponse.threeDSProtocolVersion ===
					'2.2.0' ||
				preauthResponse.threeDSProtocolVersion ===
					'2.1.0'
					? {
							type: '3ds-v2',
							threeDSServerTransID:
								preauthResponse.threeDSServerTransID,
					  }
					: {
							type: 'regular',
							orderId: redsysId,
							paymentMethod: {
								type: 'id-oper',
								idOper,
							},
							threeDSProtocolVersion:
								preauthResponse.threeDSProtocolVersion,
					  },
			clientInfo,
		}

		const authResponse = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/redsys/auth`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(authBody),
			}
		)

		if (!authResponse.ok) {
			throw new Error('Auth failed')
		}

		return authResponse.json()
	}

	const sendThreeDSMethod = async (
		preauthResponse: PreauthOutput
	): Promise<void> => {
		if (
			'threeDSMethodForm' in preauthResponse &&
			preauthResponse.threeDSMethodForm
		) {
			if (
				!threeDSMethodFormEl.current ||
				!threeDSMethodIframeEl.current ||
				!threeDSMethodDataEl.current
			)
				return

			// Serialize the parameters of the body into JSON and encode them with base64url
			// Then place each encoded parameter in their respective input element
			threeDSMethodDataEl.current.value =
				preauthResponse.threeDSMethodForm.body.threeDSMethodData

			// Fill out the form information and submit.
			threeDSMethodFormEl.current.action =
				preauthResponse.threeDSMethodForm.url
			threeDSMethodFormEl.current.submit()

			await waitForIframeToPostMessage({
				iframe: threeDSMethodIframeEl.current,
				condition: (event: MessageEvent) => {
					return (
						typeof event.data !== 'object' &&
						event.data != null &&
						'finished' in event.data &&
						(event as FinishedMessageEvent).data
							.finished
					)
				},
				// Wait until 3DS method completes or a 10 seconds timeout
				timeout: 10 * 1000,
			})
		}
	}

	useEffect(() => {
		const receiveMessage = async (
			event: RedsysMessageEvent
		) => {
			storeIdOper(
				event,
				'token',
				'errorCode',
				'inputValidator'
			)

			if (
				typeof event.data === 'object' &&
				'idOper' in event.data &&
				redsysId
			) {
				const idOper = event.data.idOper

				if (!idOper) {
					throw new Error('Missing idOper')
				}

				const preauthResponse: PreauthOutput =
					await preauthRequest(redsysId, idOper)

				await sendThreeDSMethod(preauthResponse)

				const clientInfo = obtain3DSBrowserInfo()

				const authResponse = await authRequest(
					preauthResponse,
					clientInfo,
					idOper,
					redsysId
				)

				const redirectUrl = `/${language}/account/orders/${redsysId}?isNew=true`
				if (authResponse.status === 'done') {
					navigate(redirectUrl)
				} else if (
					authResponse.status === 'challenge'
				) {
					let iframe: HTMLIFrameElement

					if (authResponse.formVersion === '1') {
						if (
							!challengeV1IframeEl.current ||
							!challengeV1FormEl.current ||
							!challengeV1MDEl.current ||
							!challengeV1PaReqEl.current ||
							!challengeV1TermUrlEl.current
						) {
							throw new Error(
								'Form for 3DS v1 challenge does not exist'
							)
						}

						iframe = challengeV1IframeEl.current

						challengeV1MDEl.current.value =
							authResponse.form.body.MD
						challengeV1PaReqEl.current.value =
							authResponse.form.body.PaReq
						challengeV1TermUrlEl.current.value =
							authResponse.form.body.TermUrl

						challengeV1FormEl.current.action =
							authResponse.form.url
						challengeV1FormEl.current.submit()

						setActiveSection('challenge-v1')
					} else if (
						authResponse.formVersion === '2'
					) {
						if (
							!challengeV2IframeEl.current ||
							!challengeV2FormEl.current ||
							!challengeV2CreqEl.current
						) {
							throw new Error(
								'Form for 3DS v2 challenge does not exist'
							)
						}

						iframe = challengeV2IframeEl.current

						challengeV2CreqEl.current.value =
							authResponse.form.body.creq
						challengeV2FormEl.current.action =
							authResponse.form.url
						challengeV2FormEl.current.submit()

						setActiveSection('challenge-v2')
					} else {
						throw new Error('Unknown formVersion')
					}

					await waitForIframeToPostMessage({
						iframe,
						condition: (event: MessageEvent) => {
							return (
								typeof event.data !== 'object' &&
								event.data != null &&
								'finished' in event.data &&
								(event as FinishedMessageEvent)
									.data.finished
							)
						},
					})

					// // After the payment finishes, we let them see the postchallenge page for
					// // a few seconds before redirecting to our frontend page
					await new Promise((resolve) =>
						setTimeout(resolve, 500 * 1000)
					)

					navigate(redirectUrl)
				}
			}
		}

		// IFRAME FORM LOAD BELOW

		window.addEventListener(
			'message',
			receiveMessage
		)

		if (
			!document.getElementById(
				'redsys-hosted-pay-button'
			)
		) {
			;(window as any).getInSiteFormJSON({
				id: 'card-form',
				fuc: process.env.REACT_APP_RDS_ID,
				terminal: '1',
				order: redsysId,
				estiloInsite: 'twoRows',
			})
		}

		return () => {
			window.removeEventListener(
				'message',
				receiveMessage
			)
		}
	}, [])
	/*
	 * Listen to reception of operation ID
	 */
	// eslint-disable-next-line @typescript-eslint/no-misused-promises

	return (
		<Container
			component='main'
			maxWidth='sm'
			sx={{
				padding: '5% 2%',
				width: '90%',
				minWidth: '75%',
				fontFamily: 'Bembo',
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				fontFamily={'Bembo'}
				sx={{
					mb: '2rem',
					fontSize: '2rem',
				}}
			>
				Card Payment
			</Typography>
			<div id='card-payment-page'>
				<Section
					hidden={
						activeSection !== 'payment-data'
					}
				>
					{/* Form to capture payment data */}
					<Box
						id='card-form'
						sx={{
							height: '400px',
							display: 'flex',
							flexDirection: 'column',
						}}
					/>
					<input
						type='hidden'
						id='token'
					/>
					<input
						type='hidden'
						id='errorCode'
					/>
				</Section>
				<NonVisibleSection>
					{/* 3DS Method iframe, invisible */}
					<iframe
						ref={threeDSMethodIframeEl!}
						name='threeDSMethodIframe'
						style={{
							position: 'absolute',
							width: 0,
							height: 0,
							border: 0,
						}}
					/>
					<form
						ref={threeDSMethodFormEl!}
						method='post'
						target='threeDSMethodIframe'
					>
						{/* Name of iframe */}
						<input
							ref={threeDSMethodDataEl!}
							name='threeDSMethodData'
							type='hidden'
						/>
					</form>
				</NonVisibleSection>
				<Section
					hidden={
						activeSection !== 'challenge-v1'
					}
				>
					{/* Challenge form */}
					<iframe
						ref={challengeV1IframeEl!}
						name='challengeV1Iframe'
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							height: '100vh',
						}}
					/>
					<form
						ref={challengeV1FormEl!}
						method='post'
						target='challengeV1Iframe'
					>
						{/* Name of iframe */}
						<input
							ref={challengeV1MDEl!}
							type='hidden'
							name='MD'
						/>
						<input
							ref={challengeV1PaReqEl!}
							type='hidden'
							name='PaReq'
						/>
						<input
							ref={challengeV1TermUrlEl!}
							type='hidden'
							name='TermUrl'
						/>
					</form>
				</Section>
				<Section
					hidden={
						activeSection !== 'challenge-v2'
					}
				>
					{/* Challenge form */}
					<iframe
						ref={challengeV2IframeEl!}
						name='challengeV2Iframe'
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							height: '100vh',
						}}
					/>
					<form
						ref={challengeV2FormEl!}
						method='post'
						target='challengeV2Iframe'
					>
						{/* Name of iframe */}
						<input
							ref={challengeV2CreqEl!}
							type='hidden'
							name='creq'
						/>
					</form>
				</Section>
			</div>
		</Container>
	)
}
