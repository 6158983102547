import { Box, Grid, Typography } from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import { footerPageTitle, footerPageText } from '.'
import casaRealImage from '../../../assets/images/fe-story/casaRealHorizontal.webp'
import calidadImage from '../../../assets/images/fe-story/calidad.jpg'
import cambiaLaModaImage from '../../../assets/images/fe-story/cambia_la_moda.jpg'
import detallesImage from '../../../assets/images/fe-story/detalles.jpg'
import bgNewletterImage from '../../../assets/images/fe-story/bg_newletter.jpg'
import visionImage from '../../../assets/images/fe-story/vision.jpg'

export const FeStoryPage = (props: IRoute) => {
	return (
		<Grid
			container
			direction={{ xs: 'column', md: 'row' }} // Añade esta línea
		>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					alignContent: 'center',
					textAlign: 'center',
				}}
			>
				<Box
					sx={{
						backgroundImage: `url(${casaRealImage})`,
						aspectRatio: '16/8',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end', // Alinea el contenido al fondo
						alignItems: 'center', // Centra horizontalmente
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						padding: '0 5% 2.5%', // Espaciado alrededor del texto
					}}
				>
					<Typography
						variant='h4'
						fontFamily={'Avenir'}
						fontSize={'3rem'}
						color={'wheat'}
						fontWeight={'bold'}
						sx={{
							color: 'white',
							borderRadius: '5px', // Bordes redondeados
							textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Sombra del texto
						}}
					>
						Las casas reales eligen Fina Ejerique
					</Typography>
					<Typography
						fontFamily={'Avenir'}
						color={'wheat'}
						fontWeight={'bold'}
						fontSize={'1.25rem'}
						textAlign={'center'}
						sx={{
							color: 'white',
							mb: '1rem', // Margen inferior para separar los textos
							borderRadius: '5px', // Bordes redondeados
							textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Sombra del texto
							marginTop: '1rem', // Margen superior para separar los textos
						}}
					>
						Varias casas reales, entre ellas La Casa Real Británica eligen
						nuestra marca para vestir a sus hijos en diferentes ocasiones.
						Llaman su atención las prendas únicas y exclusivas de nuestras
						colecciones.
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					alignContent: 'center',
					textAlign: 'center',
					padding: '5%',
				}}
			>
				{footerPageTitle('La calidad siempre permanece', props.isMobile)}
				{footerPageText(
					`El precio se olvida, la calidad es recordada siempre. Nuestros valores prevalecen en nuestras prendas, a través de materiales premium que destacan por su gran calidad.`,
					props.isMobile
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					py: '1rem',
				}}
			>
				<img
					src={calidadImage}
					alt='calidad'
					style={{
						height: 'auto',
						width: props.isMobile ? '100%' : '50%',
					}}
				/>
				<img
					src={detallesImage}
					alt='calidad'
					style={{
						height: 'auto',
						width: props.isMobile ? '100%' : '50%',
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					alignContent: 'center',
					textAlign: 'center',
					padding: '5%',
				}}
			>
				{footerPageTitle('Los detalles marcan la diferencia', props.isMobile)}
				{footerPageText(
					`Los detalles marcan la diferencia y, en moda, más que nunca. Cuidamos nuestros diseños al detalle y por eso somos una marca que fabrica 100% en España.`,
					props.isMobile
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					py: '1rem',
				}}
			>
				<img
					src={cambiaLaModaImage}
					alt='calidad'
					style={{
						height: 'auto',
						width: props.isMobile ? '100%' : '50%',
					}}
				/>
				<img
					src={cambiaLaModaImage}
					alt='calidad'
					style={{
						height: 'auto',
						width: props.isMobile ? '100%' : '50%',
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					alignContent: 'center',
					textAlign: 'center',
					padding: '5%',
				}}
			>
				{footerPageTitle(
					'Cambia la moda para proteger el medio ambiente',
					props.isMobile
				)}
				{footerPageText(
					`Nuestro proceso de producción muestra nuestro compromiso con el planeta y la necesidad urgente de cuidarlo a través de la innovación, desarrollo e incorporación de medidas sostenibles en la empresa.`,
					props.isMobile
				)}
			</Grid>

			<Grid
				item
				xs={12}
				md={12}
				sx={{
					backgroundImage: `url(${bgNewletterImage})`,
					padding: '5%',
					color: 'white',
					textAlign: 'center',
				}}
			>
				{footerPageTitle('Todo empieza con una gran historia', props.isMobile)}
				<Box
					sx={{
						textAlign: 'left',
					}}
				>
					{footerPageText(
						`En 1993 Fina Ejerique funda su marca de ropa infantil con una vocación clara: lanzar una colección de ropa de vestir para niños cómoda, trabajada con mimo y con los parámetros de calidad más altos del mercado.`,
						props.isMobile
					)}
					{footerPageText(
						`Desde entonces, hemos conseguido crecer gracias a la ilusión y el tesón. Al pequeño grupo de emprendedores inicial se han sumado jóvenes diseñadores, gestores, técnicos y especialistas textiles con los que hemos avanzado y gracias a los cuáles podemos decir que seguimos trabajando con la filosofía de nuestros inicios, que sigue intacta y cada vez más fuerte.`,
						props.isMobile
					)}
					{footerPageText(
						`Tras más de 30 años en el mundo de la moda, podemos afirmar que somos una marca de ropa infantil donde prima el cliente y el producto sobre el beneficio. Nuestra ilusión y proactividad por crear, año tras año, colecciones que lleguen al alma de nuestros clientes es nuestro sello, siempre apoyándonos en el sector textil de España, de donde proceden todas nuestras prendas. Nuestro entusiasmo sigue siendo nuestra principal fuente de motivación y es gracias a la confianza que, colección tras colección, depositan sobre nosotros.`,
						props.isMobile
					)}
				</Box>
			</Grid>

			{/* <Grid
				item
				xs={12}
				md={5}
				sx={{
					py: '1rem',
				}}
			>
				<img
					src={visionImage}
					alt='calidad'
					style={{
						height: 'auto',
						width: '100%',
					}}
				/>
			</Grid> */}

			<Grid
				item
				xs={12}
				md={12}
				sx={{
					alignContent: 'center',
					padding: '5%',
					textAlign: 'center',
				}}
			>
				{footerPageTitle('La visión de Fina Ejerique', props.isMobile)}
				<Box
					sx={{
						textAlign: 'left',
					}}
				>
					{footerPageText(
						`Nuestra marca de moda infantil, Fina Ejerique, nace con unas convicciones y valores fuertes y robustos. Nuestra estrategia siempre ha sido respaldada por nuestra honradez, trabajo y respeto.`,
						props.isMobile
					)}
					{footerPageText(
						`Buscamos ofrecer productos a precio de compra y venta justos, rechazando la explotación infantil y cualquier otro tipo de discriminación y con conciencia medioambiental.`,
						props.isMobile
					)}
					{footerPageText(
						`Nos comprometemos con el cumplimiento de plazos con nuestros clientes y proveedores. Gracias a una estructura empresarial sencilla y a una fabricación propia, hemos alcanzado tiempos de espera muy reducidos. El resultado: productos artesanales made in Spain de alta calidad.`,
						props.isMobile
					)}
					{footerPageText(
						`Respetamos nuestra fabricación y nuestro sector, con prendas fabricadas en España. A nuestros trabajadores, respetando derechos y permitiendo la conciliación con la vida familiar. Y por último, el medio ambiente, con tejidos naturales y adaptados a las normativas vigentes, pero sobre todo, al mundo infantil.`,
						props.isMobile
					)}
				</Box>
			</Grid>
		</Grid>
	)
}
