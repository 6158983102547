import { Box, Typography } from '@mui/material'
import PartnersLogos from '../../assets/images/partners/partners.png'
import { IRoute } from '../../interfaces/route.interface'
import { useTranslation } from 'react-i18next'
import { Partners } from '../../translations/translations.enum'

export interface PartnersPageProps extends IRoute {}

const PartnersPage = (props: PartnersPageProps) => {
	const { t } = useTranslation()
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '2% 15% 5%',
				textAlign: 'justify',
				gap: '1.5rem',
			}}
		>
			<Typography
				variant='h4'
				sx={{
					marginBottom: '1rem',
				}}
			>
				{t(Partners.PARTNERS_TITLE)}
			</Typography>
			<Typography>
				{t(Partners.PARTNERS_TEXT_1)}
			</Typography>
			<Typography>
				{t(Partners.PARTNERS_TEXT_2)}
			</Typography>
			<img
				src={PartnersLogos}
				alt='partners logos'
				style={{
					width: '100%',
					maxWidth: '800px',
					height: 'auto',
				}}
			/>
		</Box>
	)
}

export default PartnersPage
