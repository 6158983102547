import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import { Translations } from '../../translations/translations.enum'
import { BlackButton } from '../product/black-button'
import { CartDivider } from './cart-divider'
import { CartProduct } from './cart-product'
import { CartSummary } from './cart-summary'
import { CartHelp } from './cart-help'
import {
	ICart,
	ProductQuantity,
	variantId,
} from '../../interfaces/cart.interface'
import {
	useNavigate,
	useParams,
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ICartReview {
	isMobile: boolean
	nextStep: () => void
	cart: ICart
	deleteCartProduct: (
		modelId: number,
		variantId: number | 'default' | undefined
	) => void
	updateCart: (
		modelId: number,
		variantId: number,
		quantity: number
	) => void
}

export function CartReview(props: ICartReview) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { language } = useParams()
	const [
		isSomeProductOutOfStock,
		setIsSomeProductOutOfStock,
	] = useState<boolean>(false)

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: props.isMobile
					? 'column'
					: 'row',
				padding: '20px 0',
				margin: props.isMobile ? '0' : '0 7.5rem',
				justifyContent: 'center',
				gap: '5%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					padding: '20px 0',
					flexDirection: 'column',
					width: props.isMobile ? '100%' : '50%',
					margin: '0',
					overflow: 'hidden',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginLeft: '20px',
						width: '100%',
					}}
				>
					<Typography
						variant='h4'
						fontFamily={'Bembo'}
					>
						{t(Translations.SHOPPING_BAG)}
					</Typography>
				</Box>
				{Object.entries(props.cart.products).map(
					([modelId, variants]) => {
						const productId: number =
							Number(modelId)
						const productVariants: Partial<
							Record<variantId, ProductQuantity>
						> = variants

						return (
							<>
								{Object.entries(
									productVariants
								).map(
									([
										variantIdentifier,
										quantity,
									]) => {
										const variantId:
											| number
											| undefined =
											Number(variantIdentifier) ||
											undefined
										if (quantity) {
											return (
												<>
													<CartProduct
														key={modelId}
														productId={productId}
														variantId={variantId}
														price={0} // TODO: get price from API
														quantity={quantity}
														deleteCartProduct={
															props.deleteCartProduct
														}
														setIsSomeProductOutOfStock={
															setIsSomeProductOutOfStock
														}
														isMobile={
															props.isMobile
														}
														updateCart={
															props.updateCart
														}
													/>
													<CartDivider />
												</>
											)
										}
									}
								)}
							</>
						)
					}
				)}

				<CartSummary
					subTotal={{
						value: props.cart.subTotal || 0,
						valueWithDiscount:
							props.cart.subTotalWithDiscount,
						text: 'Subtotal',
					}}
					total={{
						value: props.cart.total || 0,
						valueWithDiscount:
							props.cart.totalWithDiscount,
						text: 'Total',
					}}
					delivery={{
						value: props.cart.delivery || 0,
						text: 'Envío',
					}}
				/>
				<CartDivider />
				<Box
					sx={{
						display: 'flex',
						flexDirection: props.isMobile
							? 'column-reverse'
							: 'row',
						justifyContent: 'space-between',
						width: '100%',
						margin: '20px 0',
						gap: props.isMobile ? '15px' : '20%',
					}}
				>
					<BlackButton
						text={t(
							Translations.CONTINUE_SHOPPING
						)}
						inverted={true}
						onClick={() =>
							navigate(`/${language}`)
						}
					/>
					{Object.keys(props.cart.products)
						.length > 0 ? (
						<BlackButton
							text={t(Translations.CHECK_OUT)}
							disabled={false}
							onClick={() => {
								props.nextStep()
							}}
						/>
					) : (
						<></>
					)}
				</Box>
			</Box>

			<CartHelp />
		</Box>
	)
}
