import { createTheme } from '@mui/material/styles'

const fontFamily = [
	'Avenir', // reemplaza 'Roboto' con el nombre de tu fuente
	'Montserrat',
	'sans-serif',
].join(',')

const customTheme = createTheme({
	palette: {
		primary: {
			main: '#000',
		},
		secondary: {
			main: '#fff',
		},
		text: {
			primary: '#4d4e52',
		},
		background: {
			default: '#fff',
			paper: '#f8f8f8',
		},
	},
	typography: {
		fontFamily: fontFamily,
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					// display: 'block',
				},
			},
		},
	},
})

export default customTheme
