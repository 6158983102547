import {
	useState,
	useEffect,
	useRef,
} from 'react'
import { Box } from '@mui/material'
import './lazy-image.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import FLogoSVG from '../assets/f-logo-svg'

export interface ILazyImage {
	imageSrc: string
	width?: string
	height?: string
	loadPlaceholderSize: string
}

export const LazyImage = (props: ILazyImage) => {
	const [loaded, setLoaded] =
		useState<boolean>(false)

	return (
		<Box
			sx={{
				width: props.width || '100%',
				paddingTop: props.height || '100%',
				border: loaded ? '' : '1px solid #CCCCCC',
				position: 'relative',
				boxSizing: 'border-box',
			}}
		>
			<LazyLoadImage
				key={props.imageSrc}
				src={props.imageSrc}
				style={{
					position: 'absolute',
					top: '0',
					left: '0',
					width: loaded ? '100%' : '0px',
					// height: loaded ? '100%' : '0px',
				}}
				onLoad={() => {
					setLoaded(true)
				}}
				visibleByDefault={true}
			/>
			<Box
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: props.loadPlaceholderSize,
					border: loaded
						? ''
						: '1px solid #CCCCCC',
					borderRadius: '50%',
					padding: '1%',
				}}
			>
				<FLogoSVG loaded={loaded} />
			</Box>
		</Box>
	)
}
