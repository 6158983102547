import { Box, Typography } from '@mui/material'

export function RedsysProcessingPayment() {
	return (
		<Box
			sx={{
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				border: 'solid 1px #afafaf',
				borderRadius: '1rem',
				padding: '3rem',
				margin: '5rem auto',
				width: 'fit-content',
			}}
		>
			<Typography
				variant='h4'
				fontWeight={'bold'}
				padding={'1rem'}
			>
				¡Ya casi está!
			</Typography>
			<Typography>
				Por favor, asegurate de seguir las
				indicaciones en la página de redsys para
				completar el pago.
			</Typography>
			<Typography>
				Si el pago ha sido completado puedes
				cerrar esta ventana. Si ha surgido algún
				error trata de intentarlo mas tarde.
			</Typography>
		</Box>
	)
}
