import { Box } from '@mui/material'
import '../../../assets/scss/product-blocks.scss'

export const MobileOtherProductsTitle = (
	props: any
) => {
	return (
		<Box className='product-blocks__title'>
			<span>{props.children}</span>
		</Box>
	)
}
