import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const LoginButton = () => {
	const isMobile = true
	const { t } = useTranslation()
	const border = isMobile ? '2px solid' : 'none'

	return (
		<Button
			type='submit'
			fullWidth
			sx={{
				borderRadius: '0px',
				backgroundColor: 'secondary.main',
				color: 'primary.main',
				boxShadow: 'none',
				width: '100%',
				border: border,
				padding: '.85rem 0',
				transition:
					'background-color 300ms ease-out, border-color 300ms ease-out',
				'&:hover': {
					backgroundColor: 'primary.main',
					color: 'secondary.main',
					boxShadow: 'none',
					border: '2px solid',
				},
				my: '1rem',
			}}
			variant='contained'
		>
			<Typography
				sx={{
					fontSize: '0.785rem',
					fontWeight: 'bold',
					letterSpacing: '.14rem',
				}}
			>
				{t('SIGN_IN')}
			</Typography>
		</Button>
	)
}
