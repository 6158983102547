import { Box } from '@mui/material'

export interface IMobileHeaderToolbarBox {
	children?: React.ReactNode
	justifyContent: 'flex-end' | 'flex-start'
}

export const MobileHeaderToolbarBox = (
	props: IMobileHeaderToolbarBox
) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				width: '100%',
				flexDirection: 'row',
				justifyContent: props.justifyContent,
			}}
		>
			{props.children}
		</Box>
	)
}
