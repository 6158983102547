import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import homeImageLeft from '../../../assets/images/home-image-left.webp'
import homeImageRight from '../../../assets/images/home-image-right.webp'
import productImage from '../../../assets/images/product-image.webp'
import './carousel.css'
import { LazyImage } from '../../lazy-image/lazy-image'
import { LazyImageUnknownHeight } from '../../lazy-image/lazy-image-unknown-height'

export interface IMobileCarouselProps {
	images: string[]
}

export const MobileCarousel = (
	props: IMobileCarouselProps
) => {
	return (
		<>
			<Swiper
				spaceBetween={7.5}
				centeredSlides={true}
				slidesPerView={'auto'}
				slidesPerGroup={1}
				pagination={{
					el: '.carousel-pagination',
					clickable: true,
					renderBullet: (
						index: number,
						className: string
					) => {
						return (
							'<span class="' +
							className +
							'" style="width: 6px; height: 6px;"> ' +
							'</span>'
						)
					},
				}}
				modules={[
					Navigation,
					Pagination,
					Scrollbar,
					A11y,
				]}
				style={{
					padding: '0 7.5px',
					overflow: 'hidden',
				}}
			>
				{props.images.map((image, index) => (
					<SwiperSlide key={index}>
						<LazyImageUnknownHeight
							imageSrc={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_PRODUCT_IMAGE_PATH}/${image}`}
							loadPlaceholderSize='20%'
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<div className='carousel-pagination' />
		</>
	)
}
