import {
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import { Cookie } from '../../../translations/translations.enum'

export default function HubspotTest(props: IRoute) {
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					sx={{
						fontSize: '1.75rem',
					}}
					gutterBottom
				>
					Prueba
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Esta es una prueba
				</Typography>
				<iframe 
					src="https://toprun.es/201016101_152.html" 
					title="Example Website" 
					width="600" 
					height="400" 
					style={{ border: "none" }}
				/>
			</Paper>
		</Container>
	)
}
