import { Box } from '@mui/material'

export function CartDivider() {
	return (
		<Box
			sx={{
				width: '100%',
				backgroundColor: '#afafaf',
				height: '0.5px',
			}}
		/>
	)
}
