import { Box, Typography } from '@mui/material'
import { AccountRoutes } from '../../translations/translations.enum'
import AddressForm from '../address/address-form'
import AddressList from '../address/address-list'
import { BlackButton } from '../product/black-button'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../interfaces/route.interface'
import { ArrowBack } from '@mui/icons-material'

export interface IOrderReturnAddressProps
	extends IRoute {
	setAddressCode: (code: string | null) => void
}

export function OrderReturnAddress(
	props: IOrderReturnAddressProps
) {
	const { t } = useTranslation()
	const [errors, setErrors] = useState<any>({})
	const [
		isCreatingNewAddress,
		setIsCreatingNewAddress,
	] = useState<boolean>(false)
	const [editingAddress, setEditingAddress] =
		useState<string | undefined>()

	const [selectedAddress, setSelectedAddress] =
		useState<string | undefined>(undefined)

	const abortAddressForm = () => {
		setIsCreatingNewAddress(false)
		setEditingAddress(undefined)
		window.scrollTo(0, 0)
	}

	useEffect(() => {
		props.setAddressCode(selectedAddress || null)
	}, [selectedAddress])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			{isCreatingNewAddress || editingAddress ? (
				<>
					<Typography
						sx={{
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
							fontSize: '1.25rem',
							lineHeight: '1.5rem',
							textAlign: 'left',
						}}
						onClick={abortAddressForm}
					>
						<ArrowBack
							fontSize='small'
							sx={{
								marginRight: '0.5rem',
							}}
						/>
						Volver
					</Typography>
					<AddressForm
						isMobile={props.isMobile}
						setIsCreatingNewAddress={
							setIsCreatingNewAddress
						}
						editingAddress={editingAddress}
						setEditingAddress={setEditingAddress}
					/>
				</>
			) : (
				<>
					<AddressList
						isMobile={props.isMobile}
						setEditingAddress={setEditingAddress}
						setSelectedAddress={
							setSelectedAddress
						}
						selectedAddress={selectedAddress}
					/>
					{errors.address && (
						<Box
							sx={{
								color: 'red',
								fontSize: '0.8rem',
							}}
						>
							{errors.address}
						</Box>
					)}
					<BlackButton
						onClick={() => {
							setIsCreatingNewAddress(true)
						}}
						text={t(AccountRoutes.ADDRESS_NEW)}
						inverted={true}
					/>
				</>
			)}
		</Box>
	)
}
