import {
	Container,
	Typography,
	Grid,
	TextField,
	InputAdornment,
	IconButton,
} from '@mui/material'
import Cookies from 'js-cookie'
import { useState } from 'react'
import {
	useNavigate,
	useParams,
} from 'react-router-dom'
import { IRoute } from '../../interfaces/route.interface'
import { BlackButton } from '../product/black-button'
import { RequestManager } from '../utils'
import { useTranslation } from 'react-i18next'
import { Password } from '../../translations/translations.enum'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { getValidSymbolForPassword } from '../../utils/validation.util'

export interface IChangePasswordProps
	extends IRoute {
	setIsLogged: (logged: boolean) => void
}

const ChangePassword = (
	props: IChangePasswordProps
) => {
	const { t } = useTranslation()
	const { language } = useParams()
	const navigate = useNavigate()

	const [formData, setFormData] = useState<any>({
		oldPassword: '',
		password: '',
		confirmPassword: '',
	})
	const [errors, setErrors] = useState<any>({})

	const validateForm = () => {
		let tempErrors: any = {}

		if (!formData.oldPassword.length) {
			tempErrors.oldPassword =
				t(Password.PASSWORD_ERROR_OLD)
		}

		if (!formData.password.length) {
			tempErrors.password =
				t(Password.PASSWORD_ERROR_NEW)
		}

		if (!formData.confirmPassword.length) {
			tempErrors.confirmPassword =
				t(Password.PASSWORD_ERROR_CONFIRM)
		}

		if (
			formData.password !==
			formData.confirmPassword
		) {
			tempErrors.confirmPassword =
				t(Password.PASSWORD_ERROR_NOT_MATCH)
		}

		if (!validatePassword(formData.password)) {
			tempErrors.password =
				t(Password.PASSWORD_ERROR_INCOMPLETE)
		}

		setErrors(tempErrors)
		return Object.keys(tempErrors).length === 0
	}

	const handleSubmit = async (event: any) => {
		event.preventDefault()
		setErrors({})
		if (validateForm()) {
			try {
				const response = await RequestManager(
					'POST',
					'user/me/password',
					true,
					formData
				)

				if (
					[200, 201].includes(response.status)
				) {
					Cookies.remove('accessToken')
					props.setIsLogged(false)
					navigate(`/${language}/login`)
				} else {
					setErrors({
						...errors,
						oldPassword: t(Password.PASSWORD_ERROR_INCORRECT)
					})
				}
			} catch (error) {
				setErrors({
					...errors,
					oldPassword: t(Password.PASSWORD_ERROR_ERROR)
				})
			}
		}
	}

	const handleChange = (event: any) => {
		const { name, value, type, checked } =
			event.target

		setFormData({
			...formData,
			[name]:
				type === 'checkbox' ? checked : value,
		})
	}

	const validatePassword = (password: string) => {
		const hasNumber = /\d/.test(password)
		const hasLetter = /[a-zA-Z]/.test(password)
		getValidSymbolForPassword()
		const hasSpecialChar = getValidSymbolForPassword().test(password)

		return (
			password.length >= 8 &&
			hasNumber &&
			hasLetter &&
			hasSpecialChar
		)
	}

	const [showOldPassword, setShowOldPassword] = useState(false)
	const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = () => setShowPassword((show) => !show)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)

	return (
		<Container
			component='main'
			maxWidth='sm'
			sx={{
				width: props.isMobile ? '100%' : '50%',
				fontFamily: 'Bembo',
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				fontFamily={'Bembo'}
				fontSize={'2rem'}
				sx={{
					mb: '2rem',
				}}
			>
				{t(Password.PASSWORD_CHANGE)}
			</Typography>
			<form
				onSubmit={handleSubmit}
				noValidate
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							name='oldPassword'
							label={t(Password.PASSWORD_CURRENT)}
							type={showOldPassword ? 'text' : 'password'}
							id='oldPassword'
							value={formData.oldPassword}
							onChange={handleChange}
							error={!!errors.oldPassword}
							helperText={errors.oldPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowOldPassword}
											edge="end"
										>
											{showOldPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						gap={2}
					>
						<TextField
							required
							fullWidth
							name='password'
							label={t(Password.PASSWORD_NEW)}
							type={showPassword ? 'text' : 'password'}
							id='password'
							value={formData.password}
							onChange={handleChange}
							error={!!errors.password}
							helperText={errors.password}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						<Typography
							sx={{
								mt: '1rem',
							}}
							dangerouslySetInnerHTML = {{__html: t(Password.PASSWORD_DETAILS)}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							name='confirmPassword'
							label={t(Password.PASSWORD_CONFIRM)}
							type={showConfirmPassword ? 'text' : 'password'}
							id='confirmPassword'
							value={formData.confirmPassword}
							onChange={handleChange}
							error={!!errors.confirmPassword}
							helperText={errors.confirmPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowConfirmPassword}
											edge="end"
										>
											{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<BlackButton text={t(Password.PASSWORD_CHANGE)} />
					</Grid>
				</Grid>
			</form>
		</Container>
	)
}

export default ChangePassword
