import { Box, Typography } from '@mui/material'
import { LazyImage } from '../lazy-image/lazy-image'
import { IOrderRequestLine } from '../../interfaces/order-line-request.interface'
import { getPriceComponent } from '../../utils/price-component'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export interface IOrderSummaryProductProps {
	line: IOrderRequestLine
	margin?: string
}

export function OrderSummaryProduct(
	props: IOrderSummaryProductProps
) {
	const { t } = useTranslation()
	return (
		<Box
			my={props.margin || '5%'}
			sx={{
				display: 'flex',
				flexDirection: 'row',
			}}
			gap={'5%'}
		>
			<Box>
				<LazyImage
					imageSrc={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_CART_IMAGE_PATH}/${props.line.image}`}
					loadPlaceholderSize='25px'
					width='100px'
					height='150px'
				/>
			</Box>
			<Box width={'100%'}>
				<Typography
					fontFamily={'Antic'}
					fontSize={'1.25rem'}
					lineHeight={'2rem'}
				>
					{props.line.name}
				</Typography>

				<Typography
					fontFamily={'Antic'}
					fontSize={'1.25rem'}
					lineHeight={'3rem'}
				>
					{props.line.variantName}
				</Typography>

				<Typography
					fontFamily={'Antic'}
					fontSize={'1.25rem'}
					lineHeight={'3rem'}
				>
					{props.line.quantity}{' '}
					{t(Translations.UNITS)}
				</Typography>

				<Typography
					fontFamily={'Antic'}
					fontSize={'1.1rem'}
					lineHeight={'3rem'}
				>
					Ref. {props.line.reference}
				</Typography>

				{getPriceComponent(
					'1.25rem',
					props.line.priceTaxIncluded,
					props.line.priceWithDiscount,
					'flex-end'
				)}
			</Box>
		</Box>
	)
}
