import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import Cookies from 'js-cookie'
import {
	AccountRoutes,
	AddressTranslations,
	Translations,
} from '../../translations/translations.enum'
import AddressForm from '../address/address-form'
import AddressList from '../address/address-list'
import { BlackButton } from '../product/black-button'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../interfaces/route.interface'
import { ArrowBack } from '@mui/icons-material'
import { handleGATagBeginCheckout } from '../utils/google-analytics'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { handleMetaTagBeginCheckout } from '../utils/meta-business'
export interface IOrderAddressProps extends IRoute {
	order?: IOrderRequest
	nextStep: () => void
}

export function OrderAddress(props: IOrderAddressProps) {
	const { t } = useTranslation()
	const [errors, setErrors] = useState<any>({})
	const [isCreatingNewAddress, setIsCreatingNewAddress] =
		useState<boolean>(false)
	const [editingAddress, setEditingAddress] = useState<string | undefined>()

	const [selectedAddress, setSelectedAddress] = useState<string | undefined>(
		undefined
	)

	const nextStepHandle = () => {
		let tempErrors: any = {}
		if (selectedAddress?.length) {
			props.nextStep()
		} else {
			tempErrors.address = t(AddressTranslations.SELECT_ADDRESS)
		}
		setErrors(tempErrors)
	}

	const abortAddressForm = () => {
		setIsCreatingNewAddress(false)
		setEditingAddress(undefined)
		window.scrollTo(0, 0)
	}

	useEffect(() => {
		if (props.order) {
			handleGATagBeginCheckout(props.order)
			handleMetaTagBeginCheckout(props.order)
		}
	}, [])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			{isCreatingNewAddress || editingAddress ? (
				<>
					<Typography
						sx={{
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
							fontSize: '1.25rem',
							lineHeight: '1.5rem',
							textAlign: 'left',
						}}
						onClick={abortAddressForm}
					>
						<ArrowBack
							fontSize='small'
							sx={{
								marginRight: '0.5rem',
							}}
						/>
						Volver
					</Typography>
					<AddressForm
						isMobile={props.isMobile}
						setIsCreatingNewAddress={setIsCreatingNewAddress}
						editingAddress={editingAddress}
						setEditingAddress={setEditingAddress}
					/>
				</>
			) : (
				<>
					<AddressList
						isMobile={props.isMobile}
						setEditingAddress={setEditingAddress}
						setSelectedAddress={setSelectedAddress}
						selectedAddress={selectedAddress}
					/>
					{errors.address && (
						<Box
							sx={{
								color: 'red',
								fontSize: '0.8rem',
							}}
						>
							{errors.address}
						</Box>
					)}
					<BlackButton
						onClick={() => {
							setIsCreatingNewAddress(true)
							window.scrollTo(0, 0)
						}}
						text={t(AccountRoutes.ADDRESS_NEW)}
						inverted={true}
					/>
					<BlackButton
						onClick={() => {
							fetch(`${process.env.REACT_APP_CORE_API_URL}/order/address`, {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
									Authorization: `Bearer ${Cookies.get('accessToken')}`,
								},
								body: JSON.stringify({
									addressName: selectedAddress,
								}),
							}).then(() => nextStepHandle())
						}}
						text={t(Translations.CONTINUE_TO_PAYMENT)}
					/>
				</>
			)}
		</Box>
	)
}
