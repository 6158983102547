import { Box, Typography } from '@mui/material'
import Decimal from 'decimal.js'
import { priceFixedDecimals } from './price.util'

const getPriceComponent = (
	fontSize: string,
	price: number,
	priceWithDiscount?: number,
	justifyContent: 'flex-start' | 'center' | 'flex-end' = 'flex-start'
) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				gap: '1rem',
				justifyContent: justifyContent,
			}}
		>
			<Typography
				sx={{
					fontSize: fontSize,
					textAlign: 'left',
					fontFamily: 'Bembo',
					textDecoration: priceWithDiscount ? 'line-through' : 'none',
					color: priceWithDiscount ? 'grey' : 'black',
				}}
			>
				€{priceFixedDecimals(price)}
			</Typography>

			{priceWithDiscount && (
				<Typography
					sx={{
						fontSize: fontSize,
						fontWeight: 'bold',
						textAlign: 'left',
						fontFamily: 'Bembo',
					}}
				>
					€{priceFixedDecimals(priceWithDiscount)}
				</Typography>
			)}
		</Box>
	)
}

export { getPriceComponent }
