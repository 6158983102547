// SearchBar.js
import React, {
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	Box,
	IconButton,
	InputBase,
} from '@mui/material'
import {
	Close,
	Search,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { LanguageISOCode } from '../../../enums/language-iso-code.enum'

export interface ISearchBar {
	language: LanguageISOCode
	setSearchOpen: (isSearching: boolean) => void
}

const SearchBar = (props: ISearchBar) => {
	const [isEditing, setIsEditing] =
		useState(false)
	const navigate = useNavigate() // Hook para navegar
	const inputRef = useRef<HTMLInputElement>(null) // Crea la referencia al input

	useEffect(() => {
		if (isEditing) {
			inputRef.current?.focus() // Enfoca el input cuando isEditing es true
		}
	}, [isEditing]) // Dependencia de efecto isEditing

	const handleBlur = () => {
		setIsEditing(false)
	}

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const query = event.target.value || ''
		navigate(`${props.language}/search/${query}`)
	}

	return (
		<Box
			display='flex'
			alignItems='center'
			width='100%'
			height={`${process.env.REACT_APP_HEADER_HEIGHT_MOBILE_PX}px`}
			position='fixed'
			top={0}
			left={0}
			zIndex={1300} // Asegúrate de que el zIndex sea suficientemente alto para superponerse a la barra de navegación
			sx={{
				backgroundColor: 'background.default', // Ajusta el color de fondo según tu tema
				borderBottom: '1px solid', // Opcional: agrega un borde inferior para distinguir el buscador de otros contenidos
				borderColor: 'divider',
				flex: 1,
				color: 'inherit',
			}}
		>
			<InputBase
				placeholder='Buscar...'
				autoFocus
				fullWidth
				startAdornment={
					<Search
						sx={{
							mx: 1,
						}}
					/>
				}
				endAdornment={
					<IconButton
						onClick={() =>
							props.setSearchOpen(false)
						}
						sx={{
							marginRight: 1,
						}}
					>
						<Close />
					</IconButton>
				}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		</Box>
	)
}

export default SearchBar
