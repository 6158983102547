import { useEffect, useState } from 'react'
import { IRoute } from '../../interfaces/route.interface'
import { useSearchParams } from 'react-router-dom'
import { NotFound } from '../not-found/not-found'
import { Category } from '../category/category'
import { Product } from '../product'
import { ProductQuantity } from '../../interfaces/cart.interface'
import { decodeBase64 } from '../utils/base64'

export interface IRedirectHandlerProps extends IRoute {
	updateCart: (
		modelId: number,
		product: ProductQuantity,
		variantId: number | 'default'
	) => void
}

export interface IEntityIdentifier {
	type: 'product' | 'category'
	id: number
}

export function RedirectHandler(props: IRedirectHandlerProps) {
	const [searchParams] = useSearchParams()
	const eid: string | null = searchParams.get('eid')
	const [type, setType] = useState<string | null>(null)

	useEffect(() => {
		if (eid) {
			const decoded = decodeBase64(eid)
			const entity: IEntityIdentifier = JSON.parse(decoded)

			if (entity.type === 'product' || entity.type === 'category') {
				setType(entity.type)
			}
		}
	}, [eid])

	return type === 'product' ? (
		<Product
			isMobile={props.isMobile}
			updateCart={props.updateCart}
		/>
	) : type === 'category' ? (
		<Category isMobile={props.isMobile} />
	) : (
		<NotFound isMobile={props.isMobile} />
	)
}
