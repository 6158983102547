import { Box, Typography, useMediaQuery } from '@mui/material'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { IReturnOrderRequest } from '../../interfaces/return-order-request.interface'
import { IRoute } from '../../interfaces/route.interface'
import { ICartUpdate } from '../../utils/cart.util'
import { AddressOrderPreviewInfo } from '../address/address-order-preview-info'
import { IAddress } from '../address/interface/address.interface'
import { CartDivider } from '../cart/cart-divider'
import { CartSummary } from '../cart/cart-summary'
import { OrderSummaryProduct } from '../cart/order-summary-product'
import { ReturnOrder as ReturnOrderTranslations } from '../../translations/translations.enum'

export interface IReturnOrderRequestProps extends IRoute {
	setCart: ICartUpdate
}

const ReturnOrder = (props: IReturnOrderRequestProps) => {
	const { t } = useTranslation()
	const { orderId } = useParams()
	const [order, setOrder] = useState<IReturnOrderRequest | null>()
	const [searchParams] = useSearchParams()

	const isNew = searchParams.get('isNew') === 'true'

	useEffect(() => {
		fetch(`${process.env.REACT_APP_CORE_API_URL}/return-order/${orderId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${Cookies.get('accessToken')}`,
			},
		}).then((response) => {
			response.json().then((data) => {
				setOrder(data)
			})
		})
	}, [])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '0 2% 5%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					border: '1px solid #000',
					padding: '1rem',
					marginBottom: '3rem',
				}}
				gap={2}
			>
				<Typography fontSize={'1.25rem'}>
					{t(ReturnOrderTranslations.RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE)}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '1.5rem',
				}}
				gap={2}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flexDirection: props.isMobile ? 'column' : 'row',
						gap: 2,
						width: '100%',
					}}
				>
					<Typography
						sx={{
							width: 'fit-content',
							fontWeight: 'bold',
							fontSize: '1.75rem',
							lineHeight: '2rem',
						}}
					>
						{t(ReturnOrderTranslations.RETURNORDER_DEVOLUTION_N)}: {order?.id}
					</Typography>
				</Box>
				<Typography
					sx={{
						fontSize: '1.5rem',
						lineHeight: '2rem',
					}}
				>
					{order?.status}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: props.isMobile ? 'column' : 'row',
					margin: '3rem 0',
				}}
			>
				<Box
					sx={{
						width: props.isMobile ? '100%' : '50%',
					}}
				>
					{order?.payedAt && (
						<>
							<Typography
								sx={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									lineHeight: '2rem',
								}}
							>
								{t(ReturnOrderTranslations.RETURNORDER_BUY_DATE)}
							</Typography>
							<Typography
								sx={{
									fontSize: '1rem',
									lineHeight: '2rem',
								}}
							>
								{new Date(order.payedAt).toLocaleDateString('es-es')}
							</Typography>
						</>
					)}
					{order?.addresses.map((address: IAddress) => (
						<AddressOrderPreviewInfo
							address={address}
							isReturnOrder={true}
						/>
					))}
				</Box>
				<Box
					sx={{
						width: props.isMobile ? '100%' : '50%',
					}}
				>
					<Typography
						sx={{
							fontWeight: 'bold',
							fontSize: '1.5rem',
							lineHeight: '2rem',
						}}
					>
						{t(ReturnOrderTranslations.RETURNORDER_PRODUCTS)}
					</Typography>
					{order?.orderLines.map((line) => (
						<OrderSummaryProduct line={line} />
					))}
				</Box>
			</Box>
			<CartDivider />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'end',
					width: '100%',
				}}
			>
				<CartSummary
					subTotal={{
						value: order?.subtotal || 0,
						valueWithDiscount: order?.subTotalWithDiscount,
						text: t(ReturnOrderTranslations.RETURNORDER_SUBTOTAL),
					}}
					total={{
						value: order?.total || 0,
						valueWithDiscount: order?.totalWithDiscount,
						text: t(ReturnOrderTranslations.RETURNORDER_TOTAL),
					}}
					delivery={{
						value: order?.shipping || 0,
						text: t(ReturnOrderTranslations.RETURNORDER_SHIPPING),
					}}
				/>
			</Box>
		</Box>
	)
}

export default ReturnOrder
