import { ReactElement } from 'react'
import { Typography, Box } from '@mui/material'
import 'react-phone-number-input/style.css'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface IPaymentTypeProps {
	title: string
	description: string
	icon: ReactElement<any, any>
	onClick: () => void
	isSelected?: boolean
}

const PaymentType = (
	props: IPaymentTypeProps
) => {
	const { language } = useParams()
	const { t } = useTranslation()

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				my: '5%',
				maxWidth: '100%',
			}}
			gap={3}
			onClick={props.onClick}
		>
			<Box
				sx={{
					ml: '5%',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{props.icon}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography
					fontSize={'1.5rem'}
					fontFamily={'Bembo'}
					fontWeight={'bold'}
				>
					{props.title}
				</Typography>
				<Typography
					fontSize={'1.2rem'}
					fontFamily={'Bembo'}
				>
					{props.description}
				</Typography>
			</Box>
		</Box>
	)
}

export default PaymentType
