import {
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../../interfaces/route.interface'
import { Terms } from '../../../translations/translations.enum'

export const TermsAndConditionsPage = (props: IRoute) => {
	const { t } = useTranslation()
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					gutterBottom
				>
					{t(Terms.TERMS_TITLE)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_1)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
					dangerouslySetInnerHTML={{__html: t(Terms.TERMS_TEXT_1)}}
				/>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_2)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_3)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_4)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_5)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_2)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_6)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='Denominación social GLOBOTEX, S.L.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='CIF B97230692' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Domicilio social C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA - VALENCIA' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Registro Mercantil VALENCIA T 7228, L 4530, F 208, S 8, H V 84497, I/A 5' />
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_3)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_7)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary={`${t(Terms.TERMS_TEXT_8)} 963135167`} />
					</ListItem>
					<ListItem>
						<ListItemText primary='Email legal@finaejerique.es' />
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_9)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_4)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_10)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_11)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_12)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_13)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_14)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_15)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_16)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_17)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_18)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_19)} />
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_20)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_21)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_22)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_5)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_23)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_24)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_25)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_26)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Terms.TERMS_TEXT_27)} />
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_28)}
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_29)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_6)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_30)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_7)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_31)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Terms.TERMS_SUBTITLE_8)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Terms.TERMS_TEXT_32)}
				</Typography>
			</Paper>
		</Container>
	)
}
