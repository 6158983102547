import { Box, Typography } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import { CartDivider } from '../cart/cart-divider'
import AddressList from '../address/address-list'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddressForm from '../address/address-form'
import { BlackButton } from '../product/black-button'
import { useTranslation } from 'react-i18next'
import {
	AccountRoutes,
	AddressTranslations,
	Translations,
} from '../../translations/translations.enum'
import { OrderList } from '../order/order-list'
import Order from '../order/order'
import { AccountOverview } from './account-overview.component'
import { ArrowBack } from '@mui/icons-material'
import MyInfo from '../my-info/my-info.component'
import ChangePassword from '../change-password/change-password.component'
import { ICartUpdate } from '../../utils/cart.util'
import OrderReturn from '../order/order-return'
import { ReturnOrderList } from '../return-order/return-order-list'
import ReturnOrder from '../return-order/return-order'
import { RequestManager } from '../utils'
import { useState } from 'react'
import { Wishlist } from '../wishlist/wishlist'
import { routeMapping } from '../utils/route-mapping'

export interface IAccountProps extends IRoute {
	route: AccountRoutes
	setIsLogged: (logged: boolean) => void
	setCart: ICartUpdate
}

export function Account(props: IAccountProps) {
	const { language } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const accountRouteLinks = () => {
		return [
			{
				route: `/${language}/${routeMapping.account[language || 'es']}`,
				name: AccountRoutes.ACCOUNT_OVERVIEW,
			},
			{
				route: `/${language}/${routeMapping.myInfo[language || 'es']}`,
				name: AccountRoutes.MY_INFO,
			},
			{
				route: `/${language}/${routeMapping.orders[language || 'es']}`,
				name: AccountRoutes.ORDER_HISTORY,
			},
			{
				route: `/${language}/${routeMapping.accountReturns[language || 'es']}`,
				name: AccountRoutes.RETURNS,
			},
			{
				route: `/${language}/${routeMapping.wishlist[language || 'es']}`,
				name: AccountRoutes.WISHLIST,
			},
			{
				route: `/${language}/${routeMapping.accountAddresses[language || 'es']}`,
				name: AccountRoutes.ADDRESS_BOOK,
			},
			{
				route: `/${language}/${routeMapping.changePassword[language || 'es']}`,
				name: AccountRoutes.CHANGE_PASSWORD,
			},
		]
	}

	const getAccountRouteList = () => {
		return accountRouteLinks().map((item, index) => (
			<Link
				style={{
					width: '100%',
					textDecoration: 'none',
					color: 'black',
				}}
				to={item.route}
			>
				<Typography
					sx={{
						lineHeight: '2.25rem',
						fontSize: '1.25rem',
					}}
				>
					{t(item.name)}
				</Typography>
			</Link>
		))
	}

	const getAccountRouteComponent = (route: AccountRoutes) => {
		switch (route) {
			case AccountRoutes.ORDER_HISTORY:
				return <OrderList isMobile={props.isMobile} />
			case AccountRoutes.ORDER_VIEW:
				return (
					<Order
						language={language}
						isMobile={props.isMobile}
						setCart={props.setCart}
					/>
				)
			case AccountRoutes.RETURN_VIEW:
				return (
					<ReturnOrder
						isMobile={props.isMobile}
						setCart={props.setCart}
					/>
				)
			case AccountRoutes.ORDER_RETURN:
				return <OrderReturn isMobile={props.isMobile} />
			case AccountRoutes.ADDRESS_BOOK:
				return (
					<Box>
						<AddressList
							isMobile={false}
							isAccountSettings={true}
						/>
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<BlackButton
								width={props.isMobile ? '100%' : '25%'}
								onClick={() => {
									navigate(`/${language}/${routeMapping.addressesNew[language || 'es']}`)
								}}
								text={t(AddressTranslations.ADD_ADDRESS)}
							/>
						</Box>
					</Box>
				)
			case AccountRoutes.ADDRESS_EDIT:
				return (
					<AddressForm
						isMobile={props.isMobile}
						isAccountSettings={true}
					/>
				)
			case AccountRoutes.ADDRESS_NEW:
				return (
					<AddressForm
						isMobile={props.isMobile}
						isAccountSettings={true}
					/>
				)
			case AccountRoutes.CHANGE_PASSWORD:
				return (
					<ChangePassword
						setIsLogged={props.setIsLogged}
						isMobile={props.isMobile}
					/>
				)
			case AccountRoutes.SIGN_OUT:
				return <Typography>{t(Translations.SIGN_OUT)}</Typography>
			case AccountRoutes.MY_INFO:
				return <MyInfo isMobile={props.isMobile} />
			case AccountRoutes.RETURNS:
				return <ReturnOrderList isMobile={props.isMobile} />
			case AccountRoutes.WISHLIST:
				return <Wishlist isMobile={props.isMobile} />
			default:
				return <AccountOverview language={language}/>
		}
	}

	const [firstname, setFirstname] = useState('')
	const getFirstname = async (): Promise<void> => {
		const response = await RequestManager('GET', 'user/me', true)
		let firstname = ''
		if (response.status === 200) {
			firstname = response.data.firstName
		}
		setFirstname(firstname)
	}
	getFirstname()

	return (
		<Box
			sx={{
				padding: props.isMobile ? '5rem 2.5rem' : '5rem',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography
					fontSize={'2rem'}
					paddingBottom={'0.75rem'}
				>
					{t(AccountRoutes.ACCOUNT_OVERVIEW)}
				</Typography>
				<CartDivider />
				<Typography
					fontSize={'1.5rem'}
					paddingY={'1.5rem'}
				>
					{firstname
						? `${t(Translations.ACCOUNT_OVERVIEW_DESCRIPTION)} ${firstname}!`
						: ''}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: props.isMobile ? 'column' : 'row',
					paddingTop: '2rem',
				}}
			>
				{!props.isMobile && <Box>{getAccountRouteList()}</Box>}
				{props.isMobile && props.route !== AccountRoutes.ACCOUNT_OVERVIEW && (
					<Link
						to={`/${language}/${routeMapping.account[language || 'es']}`}
						style={{
							margin: '0 0 2.5rem',
							color: 'black',
							textDecoration: 'none',
						}}
					>
						<Typography
							sx={{
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
								fontSize: '1.25rem',
								lineHeight: '1.5rem',
								textAlign: 'left',
							}}
						>
							<ArrowBack
								fontSize='small'
								sx={{
									marginRight: '0.5rem',
								}}
							/>
							{t(Translations.BACK)}
						</Typography>
					</Link>
				)}
				<Box
					sx={{
						width: props.isMobile ? '100%' : '70%',
						margin: props.isMobile ? '0' : '0 auto',
					}}
				>
					{getAccountRouteComponent(props.route)}
				</Box>
			</Box>
		</Box>
	)
}
