import { PersonOutlineOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { Translations } from '../../../../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

export interface IMobileHeaderSectionMyAccountProps {
	toggleDrawer: () => void
}

export const MobileHeaderSectionMyAccount = (
	props: IMobileHeaderSectionMyAccountProps
) => {
	const { language } = useParams()
	const { t } = useTranslation()
	return (
		<Box p={'0px 8px'}>
			<Link
				to={`/${language}/account`}
				style={{
					alignContent: 'center',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: '8px 16px',
					textDecoration: 'none',
					color: 'black',
				}}
				onClick={() => props.toggleDrawer()}
			>
				<PersonOutlineOutlined
					sx={{
						fontSize: '19px',
					}}
				/>
				<Typography
					pl={'18px'}
					fontWeight={'bold'}
					lineHeight={'1.75'}
				>
					{t(Translations.MYFINAEJERIQUE)}
				</Typography>
			</Link>
		</Box>
	)
}
