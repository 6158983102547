import {
	Opacity as BleachIcon,
	DryCleaning as DryCleanIcon,
	Iron as IronIcon,
	ArrowDropDownCircle as LowTempDryIcon,
	Block as NoBleachIcon,
	NoPhotography as NoDryCleanIcon,
	RadioButtonChecked as NormalTempDryIcon,
	Cancel as NoTumbleDryIcon,
	DoNotDisturb as NoWashIcon,
	CheckCircle as TumbleDryIcon,
	LocalLaundryService as WashIcon,
} from '@mui/icons-material'
import {
	Container,
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import { SizeChartTable } from './components/size-chart-table'
import { useTranslation } from 'react-i18next'
import { Faqs } from '../../../translations/translations.enum'

export default function FAQSPage(props: IRoute) {
	const { t } = useTranslation()
	
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_TITLE_1)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_1)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_1)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_2)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_3)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_2)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_4)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_3)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_5)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_4)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_6)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_5)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_7)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_6)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_8)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_TITLE_2)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_7)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_9)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary={t(Faqs.FAQS_A_10)}
							secondary={t(Faqs.FAQS_A_11)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Faqs.FAQS_A_12)}
							secondary={t(Faqs.FAQS_A_13)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={t(Faqs.FAQS_A_14)}
							secondary={t(Faqs.FAQS_A_15)}
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_8)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_16)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_9)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_17)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_10)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_18)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_TITLE_3)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_11)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_19)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_20)}
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary={t(Faqs.FAQS_A_21)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Faqs.FAQS_A_22)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Faqs.FAQS_A_23)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={t(Faqs.FAQS_A_24)} />
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_12)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_25)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_13)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_26)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_TITLE_4)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_14)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_27)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Faqs.FAQS_TITLE_5)}
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Faqs.FAQS_Q_15)}
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<WashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_28)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<WashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_29)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<WashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_30)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoWashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_31)}</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Faqs.FAQS_Q_16)}
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<BleachIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_32)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoBleachIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_33)}</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Faqs.FAQS_Q_17)}
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<IronIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_34)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<IronIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_35)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<IronIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_36)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoWashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_39)}</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Faqs.FAQS_Q_18)}
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<DryCleanIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_38)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoDryCleanIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_39)}</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					{t(Faqs.FAQS_Q_19)}
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<TumbleDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_40)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoTumbleDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_41)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<LowTempDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_42)}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NormalTempDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>{t(Faqs.FAQS_A_43)}</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_20)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_44)}
				</Typography>

				<SizeChartTable />

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_TITLE_8)}
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					{t(Faqs.FAQS_Q_21)}
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					{t(Faqs.FAQS_A_45)}
				</Typography>
			</Paper>
		</Container>
	)
}
