import { useState } from 'react'
import { IconButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import SearchBar from './search-bar.mobile'
import { LanguageISOCode } from '../../../enums/language-iso-code.enum'

export interface IMobileHeaderSearchButton {
	setDrawerOpen: (isOpen: boolean) => void
	language: LanguageISOCode
}

export const MobileHeaderSearchButton = (
	props: IMobileHeaderSearchButton
) => {
	const [searchOpen, setSearchOpen] =
		useState(false)

	return (
		<>
			{!searchOpen && (
				<IconButton
					edge='start'
					color='inherit'
					aria-label='menu'
					sx={{ margin: '0 2.5px' }}
					onClick={() => {
						props.setDrawerOpen(false)
						setSearchOpen(true)
					}}
				>
					<Search sx={{ fontSize: '25px' }} />
				</IconButton>
			)}
			{searchOpen && (
				<SearchBar
					setSearchOpen={setSearchOpen}
					language={props.language}
				/>
			)}
		</>
	)
}
