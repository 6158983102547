import { Box, Typography } from '@mui/material'
import { LanguageSelector } from '../../../language/language-selector'
import { Translations } from '../../../../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import { LanguageISOCode } from '../../../../../enums/language-iso-code.enum'

export interface IMobileHeaderLocationLanguage {
	language: LanguageISOCode
	setLanguage: (language: LanguageISOCode) => void
}

export const MobileHeaderLocationLanguage = (
	props: IMobileHeaderLocationLanguage
) => {
	const { t } = useTranslation()
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '0px 8px 20pxd',
				color: 'black',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '8px 16px',
					flexGrow: 1, // Esta propiedad hace que el Box se expanda
					width: '100%', // Asegúrate de que ocupe todo el ancho del padre
					boxSizing: 'border-box', // Asegúrate de que el padding no se sume al ancho
					marginBottom: '8px',
				}}
			>
				<Typography fontWeight={'bold'}>
					{t(Translations.LANGUAGE)}
				</Typography>
				<LanguageSelector
					isDesktop={false}
					language={props.language}
					setLanguage={props.setLanguage}
				/>
			</Box>
		</Box>
	)
}
