import {
	Box,
	Divider,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import text1Image from '../../../assets/images/organizations/1.png'
import text3Image from '../../../assets/images/organizations/3.png'
import text4Image1 from '../../../assets/images/organizations/4_1.png'
import text4Image2 from '../../../assets/images/organizations/4_2.png'

export const OrganizationsPage = (
	props: IRoute
) => {
	const text = (text: string) => (
		<Typography
			fontSize={
				props.isMobile ? '0.5rem' : '1rem'
			}
			textAlign={'center'}
		>
			{text}
		</Typography>
	)

	const divider = () => (
		<Divider
			sx={{
				my: '5%',
			}}
		/>
	)

	const image = (src: string, width: string) => (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				mt: '2.5%',
			}}
		>
			<img
				src={src}
				alt='organization'
				style={{
					width,
					height: 'auto',
				}}
			/>
		</Box>
	)

	return (
		<Box
			mx={'5%'}
			my={'5%'}
			mb={'15%'}
		>
			{text(`
                Globotex, S.L. ha llevado a cabo el
				proyecto con título “Desarrollo de una
				colección de moda infantil sostenible para
				promover la economía circular” financiado
				por el Instituto Valenciano de
				Competitividad Empresarial (IVACE)
				incluido dentro del Programa de ayuda
				PROYECTOS DE INNOVACIÓN DE PYME
				(INNOVA-CV), en la actuación de Innovación
				de producto, cofinanciado por los Fondos
				FEDER dentro del Programa operativo FEDER
				de la Comunitat Valenciana 2021-2027, con
				una subvención de 22.002,52 €.`)}
			{text(`
                El principal objetivo de este proyecto es
				la creación de nuevas prendas y productos
				vanguardistas en el sector de la moda
				infantil desarrollados a partir de
				materiales sostenibles como el plástico
				reciclado o tejidos de algodón orgánico,
				para poder mantenerse en el mercado como
				referentes en diseños innovadores y
				productos de calidad en el sector.`)}
			{image(text1Image, '50%')}
			{divider()}
			{text(
				`Globotex, S.L. ha sido beneficiaria del Fondo Europeo de Desarrollo Regional cuyo objetivo es mejorar la competitividad de las Pymes y gracias al cual ha puesto en marcha un Plan de Marketing Digital Internacional con el objetivo de mejorar su posicionamiento online en mercados exteriores durante el año 2022. Para ello ha contado con el apoyo del Programa XPANDE DIGITAL de la Cámara de Comercio de Valencia.`
			)}

			{divider()}
			{text(
				`Globotex, S.L. ha realizado acciones enfocadas a la internacionalización de la empresa. Estas han sido cofinanciadas por Conselleria de Economía Sostenible, Sectores Productivos, Comercio y Trabajo incluido dentro del programa ayudas de Apoyo a la promoción exterior de la Comunitat Valenciana para el ejercicio 2022. El proyecto ha sido apoyado con una subvención de 16.279,08€.`
			)}

			{image(text3Image, '25%')}
			{divider()}
			{text(
				`GLOBOTEX SL ha sido beneficiaria del Fondo Europeo de Desarrollo Regional cuyo objetivo es mejorar la competitividad de las Pymes y gracias al cual ha puesto en marcha un Plan de Internacionalización con el objetivo de mejorar su posicionamiento competitivo en el exterior durante el año 2023. Para ello ha contado con el apoyo del Programa XPANDE de la Cámara de Comercio de Valencia`
			)}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-around',
					flexWrap: 'wrap',
					mt: '5%',
				}}
			>
				{image(text4Image1, '25%')}
				{image(text4Image2, '50%')}
			</Box>
		</Box>
	)
}
