import { Box, Typography } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import { ErrorTranslations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export const NotFound = (props: IRoute) => {
	const { t } = useTranslation()
	return (
		<Box
			width={'100%'}
			display={'flex'}
			alignItems={'center'}
			justifyContent={'center'}
			flexDirection={'column'}
			height={'50vh'}
			bgcolor={'primary.main'}
			color={'white'}
		>
			<Typography variant={'h1'}>404</Typography>
			<Typography variant={'h2'}>
				{t(ErrorTranslations.PAGE_NOT_FOUND)}
			</Typography>
		</Box>
	)
}
